import { MarketStatus } from '@leon-hub/api-sdk';
export default function mapSlipEntryToEventWithChangedStatus(param) {
    let { event, market, runner, marketStatus } = param;
    return {
        event,
        market,
        runner,
        marketStatus: marketStatus === MarketStatus.CLOSED || marketStatus === MarketStatus.SUSPENDED || marketStatus === MarketStatus.MISSING ? `${marketStatus}` : null
    };
}
