import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipMessageBlock',
    props: {
        caption: {
            default: ''
        },
        description: {
            default: ''
        }
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['slip-message-block'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['slip-message-block__main'])
                }, [
                    _createElementVNode("p", {
                        class: _normalizeClass(_ctx.$style['slip-message-block__caption'])
                    }, _toDisplayString(_ctx.caption), 3),
                    _createElementVNode("p", {
                        class: _normalizeClass(_ctx.$style['slip-message-block__description'])
                    }, _toDisplayString(_ctx.description), 3)
                ], 2),
                _renderSlot(_ctx.$slots, "default"),
                _ctx.$slots?.footer?.() ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['slip-message-block__footer'])
                }, [
                    _renderSlot(_ctx.$slots, "footer")
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetSlipMessageBlock'
                ]
            ]);
        };
    }
});
