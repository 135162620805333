import { computed, toRef } from 'vue';
import { useAccumulatorBoostStore } from 'web/src/modules/slip/submodules/accumulator-boost/store';
import { getWinBoostPercentageString } from 'web/src/modules/slip/submodules/accumulator-boost/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
export function useWinInfoRoute() {
    const { $translate } = useI18n();
    const accumulatorBoostStore = useAccumulatorBoostStore();
    const maxAccumulatorBoostMultiplier = toRef(accumulatorBoostStore, 'maxAccumulatorBoostMultiplier');
    const accumulatorBoostEnabled = toRef(accumulatorBoostStore, 'accumulatorBoostEnabled');
    const isWinBoost = toRef(accumulatorBoostStore, 'isWinBoost');
    const isWinInfoRouteEnabled = computed(()=>accumulatorBoostEnabled.value && isWinBoost.value);
    const headerTranslateParams = computed(()=>({
            max: getWinBoostPercentageString(maxAccumulatorBoostMultiplier.value)
        }));
    const winInfoRouteCaption = computed(()=>$translate('WEB2_WIN_BOOST_INFO_MODAL_CAPTION', headerTranslateParams).value);
    return {
        isWinInfoRouteEnabled,
        winInfoRouteCaption
    };
}
