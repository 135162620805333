import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useIntersectionObserver } from '@leon-hub/vue-utils';
import { getHistoryDetailsRoute } from '../../../utils';
export default function useBetSlipMyBets(props, emit) {
    const router = useRouter();
    const isLoaderVisible = computed(()=>!props.isLoaded && !props.list?.length);
    const isIntersectionObserverAvailable = ref(true);
    const isEmpty = computed(()=>!!(props.isLoaded && !props.placedBetsCount));
    const isFooterHidden = computed(()=>!!(!isIntersectionObserverAvailable.value && props.isLoadingMore));
    const isFooterSticky = computed(()=>isIntersectionObserverAvailable.value && !isEmpty.value);
    const loadMoreObserverTarget = ref();
    const emitCashout = (item)=>{
        emit('cash-out', {
            betId: item.id,
            betType: item.betType,
            item
        });
    };
    const emitLoadMore = ()=>{
        emit('load-more');
    };
    const emitHistoryClick = ()=>{
        emit('history-link-click');
    };
    const emitItemClick = (id)=>{
        emit('item-click', id);
    };
    const detectObserverAvailability = ()=>{
        // for fallback UI
        isIntersectionObserverAvailable.value = 'function' == typeof window.IntersectionObserver;
    };
    const onAppear = ()=>{
        detectObserverAvailability();
    };
    useIntersectionObserver(loadMoreObserverTarget, (isIntersecting)=>{
        if (isIntersecting && !props.isLoadingMore && !isEmpty.value) emitLoadMore();
    }, {
        threshold: 1
    });
    const formattedList = computed(()=>{
        if (!props.list) return [];
        return props.list.map((item)=>({
                ...item,
                betDetailsUrl: router.resolve(getHistoryDetailsRoute(`${item.id}`)).href
            }));
    });
    return {
        isLoaderVisible,
        isEmpty,
        isFooterHidden,
        isFooterSticky,
        isIntersectionObserverAvailable,
        loadMoreObserverTarget,
        formattedList,
        emitCashout,
        emitLoadMore,
        onAppear,
        emitHistoryClick,
        emitItemClick
    };
}
