import { computed } from 'vue';
import { getFormattedTime } from '../utils';
export default function useBetSlipTrace(props) {
    const formattedLines = computed(()=>props.lines.map((param)=>{
            let { timestamp, error, contents } = param;
            return {
                formattedTime: getFormattedTime(timestamp),
                error,
                contents
            };
        }));
    return {
        formattedLines
    };
}
