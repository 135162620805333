import { isString } from '@leon-hub/guards';
import isCompetitorsTuple from 'web/src/modules/slip/guards/isCompetitorsTuple';
export function getBetsInfoFromSharedSlipEntry(input) {
    const haveValidCompetitors = isCompetitorsTuple(input.competitors);
    const getCompetitors = ()=>{
        if (haveValidCompetitors) {
            isCompetitorsTuple(input.competitors);
            return input.competitors;
        }
    };
    const getEventName = ()=>{
        if (haveValidCompetitors) return;
        isString(input.eventName);
        return input.eventName;
    };
    return {
        originalOdds: input.odds,
        originalOddsStr: input.oddsStr ?? void 0,
        competitors: getCompetitors(),
        eventName: getEventName(),
        // will be null at runner/market names for closed market status
        originalRunnerName: input.runnerName ?? '-',
        marketName: input.marketName ?? '-',
        isPrimaryMarket: input.primaryMarket ?? void 0
    };
}
