import { ref, toRef, computed } from 'vue';
import { useSlipInfoStore } from '../submodules/slip-info/store';
export function useSlipTrace() {
    const activeTraceIndex = ref(0);
    const isTraceModalVisible = ref(false);
    const slipTraceLines = toRef(useSlipInfoStore(), 'slipTraceLines');
    const currentTraceLines = computed(()=>slipTraceLines.value?.[activeTraceIndex.value] ?? null);
    const showTraceModal = function() {
        let index = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
        activeTraceIndex.value = index;
        isTraceModalVisible.value = true;
    };
    const hideTraceModal = ()=>{
        isTraceModalVisible.value = false;
    };
    const showTraceButton = computed(()=>!!slipTraceLines.value?.length);
    return {
        isTraceModalVisible,
        currentTraceLines,
        showTraceButton,
        showTraceModal,
        hideTraceModal
    };
}
