import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function getItemsForUpdatedPrice(entries, betsInfo) {
    const updatedBetsInfo = {
        ...betsInfo
    };
    const updatedEntries = entries.map((item)=>{
        const id = getSlipEntryId(item);
        updatedBetsInfo[id] = {
            ...updatedBetsInfo[id],
            originalOdds: item.odds
        };
        return {
            ...item,
            marketStatus: null
        };
    });
    return {
        updatedEntries,
        updatedBetsInfo
    };
}
