export function useBookingCodeForm(props, emit) {
    const onInput = (data)=>{
        const nextValue = data.target.value.trim().toUpperCase();
        emit('input-code', nextValue);
    };
    const onClear = ()=>{
        emit('input-code', '');
    };
    const onSubmit = ()=>{
        if (!props.error) emit('submit-code');
    };
    return {
        onInput,
        onClear,
        onSubmit
    };
}
