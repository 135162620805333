import { toRef, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useSlipShareStore } from '../store';
export function useSlipShareRoutePage() {
    const router = useRouter();
    const slipShareStore = useSlipShareStore();
    const { canselShare, regenerateSharePicture } = slipShareStore;
    const bookingCode = toRef(slipShareStore, 'bookingCode');
    const imageUrl = toRef(slipShareStore, 'imageUrl');
    const shareLink = toRef(slipShareStore, 'shareLink');
    const shareModalShown = toRef(slipShareStore, 'shareModalShown');
    const isSlipShareEnabled = toRef(slipShareStore, 'isSlipShareEnabled');
    const amountSwitcherIsVisible = toRef(slipShareStore, 'amountSwitcherIsVisible');
    const showAmountEnabled = toRef(slipShareStore, 'showAmountEnabled');
    const isSharingPlacedBet = toRef(slipShareStore, 'isSharingPlacedBet');
    const isPending = toRef(slipShareStore, 'isPending');
    const errorMessage = toRef(slipShareStore, 'errorMessage');
    const closeModal = ()=>{
        router.closeModal();
    };
    onMounted(()=>{
        if (!shareModalShown.value || !isSlipShareEnabled.value) closeModal();
    });
    const onShowAmountClick = (needToShow)=>{
        regenerateSharePicture(needToShow);
    };
    return {
        bookingCode,
        imageUrl,
        shareLink,
        amountSwitcherIsVisible,
        showAmountEnabled,
        isSharingPlacedBet,
        isPending,
        errorMessage,
        closeModal,
        canselShare,
        onShowAmountClick
    };
}
