/* eslint-disable class-methods-use-this */ import RouteName from '@leon-hub/routing-config';
import { AppModule } from 'web/src/modules/core/plugins/AppModule';
import { ModalDesktopPreset } from 'web/src/modules/core/enums';
import { useWinInfoRoute } from './composable';
import { useSlipShareDialogRoute } from './submodules/slip-share/composables';
import { useGetSharedSlipRoute } from './submodules/get-shared-slip/composables';
export class SlipModule extends AppModule {
    addRoutes(router) {
        const { isWinInfoRouteEnabled, winInfoRouteCaption } = useWinInfoRoute();
        const { isShareRouteEnabled } = useSlipShareDialogRoute();
        const { isReceiveFromCodeEnabled } = useGetSharedSlipRoute();
        if (isShareRouteEnabled.value) router.addModalRoute({
            name: RouteName.SLIP_SHARE,
            path: '/share-slip',
            title: '{{$t(\'WEB2_BET_SHARE_CAPTION\')}}',
            component: ()=>import('web/src/modules/slip/views/SlipShareRoutePage.vue'),
            modalPreset: ModalDesktopPreset.SmallDesktopModal
        });
        if (isReceiveFromCodeEnabled.value) router.addBlankRoute({
            name: RouteName.GET_SHARED_SLIP,
            path: '/betslip/:code',
            component: ()=>import('web/src/modules/slip/views/GetSharedSlipByLinkRoutePage.vue')
        });
        if (isWinInfoRouteEnabled.value) router.addModalRoute({
            name: RouteName.SLIP_WIN_BOOST_INFO,
            path: '/win-boost-info',
            title: winInfoRouteCaption.value,
            // static fallback translation: '{{$t(\'WEB2_WIN_BOOST_INFO_PAGE_TITLE\')}}',
            component: ()=>import('web/src/modules/slip/views/WinBoostInfoRoutePage.vue'),
            modalPreset: ModalDesktopPreset.SmallDesktopModal
        });
    }
}
