import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "title"
];
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { useWinBoostInfo } from './composables/useWinBoostInfo';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WinBoostInfo',
    props: {
        currentBoost: {},
        itemsToNextBoost: {},
        nextBoost: {},
        currentWinBonus: {},
        requiredOdds: {}
    },
    setup (__props) {
        const props = __props;
        const { onInfoClick, currentBoostText, nextBoostText, currentBonus } = useWinBoostInfo(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['win-boost-info'])
            }, [
                _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(_ctx.$style['win-boost-info__help']),
                    title: _ctx.$t('WEB2_CBC_PROMO_MORE'),
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onInfoClick) && _unref(onInfoClick)(...args);
                    })
                }, [
                    _createVNode(_unref(VIcon), {
                        name: _unref(IconName).INFO_OUTLINE,
                        size: _unref(IconSize).SIZE_16
                    }, null, 8, [
                        "name",
                        "size"
                    ])
                ], 10, _hoisted_1),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['win-boost-info__main'])
                }, [
                    _ctx.currentBoost ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['win-boost-info__line'])
                    }, _toDisplayString(_unref(currentBoostText)), 3)) : _createCommentVNode("", true),
                    _ctx.nextBoost && _ctx.currentBoost !== _ctx.nextBoost ? (_openBlock(), _createElementBlock("p", {
                        key: 1,
                        class: _normalizeClass([
                            _ctx.$style['win-boost-info__line'],
                            _ctx.$style['win-boost-info__line--primary']
                        ])
                    }, _toDisplayString(_unref(nextBoostText)), 3)) : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['win-boost-info__right'])
                }, [
                    _unref(currentBonus) ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['win-boost-info__bonus'])
                    }, _toDisplayString(_unref(currentBonus)), 3)) : _createCommentVNode("", true)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'WinBoostInfo'
                ]
            ]);
        };
    }
});
