import { SlipStatus } from '@leon-hub/api-sdk';
import isEntriesHasLimitStatus from './isEntriesHasLimitStatus';
import { AddSlipEntriesError } from '../../../enums';
export default function checkSlipSyncErrors(param) {
    let { status, message, entries } = param;
    if (status === SlipStatus.OK) return null;
    let entriesError = null;
    const marketsWasChanged = status === SlipStatus.MARKETS_CHANGED;
    if (marketsWasChanged && isEntriesHasLimitStatus([
        ...entries
    ])) entriesError = AddSlipEntriesError.LIMIT;
    if (!marketsWasChanged || entriesError) return {
        message: message || '',
        entriesError
    };
    return null;
}
