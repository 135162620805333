import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function getSinglesClearedFromBetById(input, id) {
    const { slipEntries } = input;
    const cleared = slipEntries.filter((param)=>{
        let { entries } = param;
        const first = entries[0];
        return first && getSlipEntryId(first) !== id;
    });
    return cleared;
}
