import { MarketStatus } from '@leon-hub/api-sdk';
import { OddChangeVariants } from '../../../components/SlipListItem/enums';
import { getSlipEntryId } from '../../../utils';
import isPriceChangedMatters from './isPriceChangedMatters';
const getChangeVariant = (marketStatus)=>{
    switch(marketStatus){
        case MarketStatus.PRICE_DOWN:
            return OddChangeVariants.DOWN;
        case MarketStatus.PRICE_UP:
            return OddChangeVariants.UP;
        default:
            return OddChangeVariants.NONE;
    }
};
export default function getCombinedSlipEntryInfo(payload) {
    const { input, betsInfo, multiSinglesMetaInfo, priceChangePolicy } = payload;
    return input.map((entry)=>{
        const id = getSlipEntryId(entry);
        const matchedBetsInfo = betsInfo[id];
        const metaInfo = multiSinglesMetaInfo[id];
        const matchedPriceChangePolicy = !isPriceChangedMatters({
            priceChangePolicy,
            originalOdds: matchedBetsInfo?.originalOdds,
            currentOdds: entry.odds
        });
        let oddChangeVariant = getChangeVariant(entry.marketStatus);
        if (!matchedPriceChangePolicy && entry.odds && matchedBetsInfo.originalOdds) oddChangeVariant = entry.odds > matchedBetsInfo.originalOdds ? OddChangeVariants.UP : OddChangeVariants.DOWN;
        return {
            id,
            ...entry,
            ...matchedBetsInfo,
            metaInfo,
            matchedPriceChangePolicy,
            oddChangeVariant
        };
    });
}
