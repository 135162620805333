export function base64ToBlob(base64String, mimeType) {
    // Remove data URL scheme if present
    const base64Data = base64String.replace(/^data:.+;base64,/, '');
    const byteCharacters = atob(base64Data);
    // Decode Base64 string
    const byteNumbers = new Array(byteCharacters.length);
    for(let i = 0; i < byteCharacters.length; i += 1)byteNumbers[i] = byteCharacters.charCodeAt(i);
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([
        byteArray
    ], {
        type: mimeType
    });
}
