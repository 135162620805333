import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function getSlipDataForUnsettledBets(param, slipUnsettledSingles) {
    let { slipEntries, combiAvailable } = param;
    const updatedEntries = slipEntries.filter((item)=>{
        const firstEntryId = getSlipEntryId(item.entries[0]);
        return slipUnsettledSingles.includes(firstEntryId);
    });
    return {
        slipEntries: updatedEntries,
        combiAvailable
    };
}
