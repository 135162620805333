import { logger } from '@leon-hub/logging';
import convertSlipEntryIdToObject from '../../../utils/convertSlipEntryIdToObject';
export default function logSaveSlipItemsAfterBet(param) {
    let { selectedEntriesIds, leaveBetResultChoice } = param;
    const events = selectedEntriesIds.map((id)=>convertSlipEntryIdToObject(id));
    logger.info('Save bets to slip', {
        events,
        saveChoice: leaveBetResultChoice
    });
}
