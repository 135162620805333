import { computed } from 'vue';
import { KnownTraceTypes } from '../enums/KnownTraceTypes';
import { contentsIsArray, contentsIsString, contentsIsTraceLabelTooltip, contentsIsTraceContent } from '../utils';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ export default function useBetSlipTraceContent(props) {
    const typeId = computed(()=>{
        if (contentsIsTraceContent(props.contents)) return props.contents.typeId;
    });
    const isList = computed(()=>typeId.value === KnownTraceTypes.LIST || !typeId.value && contentsIsArray(props.contents));
    const isTable = computed(()=>typeId.value === KnownTraceTypes.TABLE);
    const listSource = computed(()=>{
        if (contentsIsString(props.contents)) return [];
        if (contentsIsArray(props.contents)) return props.contents;
        if (contentsIsArray(props.contents?.data)) return props.contents?.data ?? [];
        return [];
    });
    const rootTag = computed(()=>{
        if (isTable.value || isList.value) return 'div';
        return 'span';
    });
    const contentTag = computed(()=>{
        if (!typeId.value) return '';
        switch(typeId.value){
            case KnownTraceTypes.TEXT:
                return 'span';
            case KnownTraceTypes.LABEL_WITH_TITLE:
                return 'abbr';
            case KnownTraceTypes.LABEL:
                return 'b';
            default:
                return 'code';
        }
    });
    const text = computed(()=>{
        if (contentsIsString(props.contents)) return props.contents;
        if (contentsIsArray(props.contents)) return;
        if (contentsIsTraceLabelTooltip(props.contents?.data)) return props.contents?.data?.label || '';
        return props.contents?.data ? JSON.stringify(props.contents.data) : '';
    });
    const formattedText = computed(()=>{
        if (!text.value) return '';
        return text.value.// replace arrow not working with v-text (-&gt);
        replace('-&gt;', '→').// remove quotes at the start and end
        replace(/^["']|["']$/gm, '');
    });
    const title = computed(()=>{
        if (contentsIsTraceContent(props.contents) && contentsIsTraceLabelTooltip(props.contents?.data)) return props.contents?.data?.tooltip;
    });
    return {
        isList,
        isTable,
        listSource,
        rootTag,
        contentTag,
        formattedText,
        title,
        typeId
    };
}
