import { ref, computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useBaseInputEvents } from 'web/src/components/Input/composables';
import isFreeBetOption from '../../../guards/isFreeBetOption';
export default function useBetSlipSelect(props, emits) {
    const { $translate } = useI18n();
    const { emitChange } = useBaseInputEvents(emits);
    const isFocused = ref(false);
    const setFocused = ()=>{
        isFocused.value = true;
    };
    const returnFocus = ()=>{
        isFocused.value = false;
    };
    const isSingleValue = computed(()=>props.options?.length === 1);
    const placeHolderText = computed(()=>props.isFreebet ? $translate('WEB2_CHOOSE_YOUR_BONUS').value : '');
    const displayLabel = computed(()=>{
        const matchedLabel = props.options?.find((option)=>option.value === props.selectedValue);
        return matchedLabel?.label || placeHolderText.value;
    });
    const displayValue = computed(()=>{
        if (!props.isFreebet) return '';
        const matchedValue = props.options?.find((option)=>option.value === props.selectedValue);
        if (!matchedValue) return '';
        isFreeBetOption(matchedValue);
        return matchedValue?.freeBetAmount || '';
    });
    const arrowIconProps = computed(()=>({
            name: isSingleValue.value ? IconName.LOCK : IconName.TRIANGLE_DOWN,
            size: IconSize.SIZE_16
        }));
    const selectChange = (event)=>{
        const { target } = event;
        HTMLSelectElement;
        if (!target || target.value === props.selectedValue) return;
        const payload = {
            target: {
                name: props.name || '',
                value: target.value
            }
        };
        emitChange(payload);
    };
    return {
        isFocused,
        isSingleValue,
        placeHolderText,
        displayLabel,
        displayValue,
        arrowIconProps,
        setFocused,
        returnFocus,
        selectChange,
        emitChange
    };
}
