import { computed } from 'vue';
import { useRouter } from 'vue-router';
import RouteName from '@leon-hub/routing-config-names';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { getWinBoostPercentageString } from '../../../submodules/accumulator-boost/utils';
export function useWinBoostInfo(props) {
    const router = useRouter();
    const { $translate } = useI18n();
    const $formatMoney = useFormatMoney();
    const currentBoostTranslationsParams = computed(()=>({
            currentBoost: props.currentBoost ? getWinBoostPercentageString(props.currentBoost) : ''
        }));
    const currentBoostText = computed(()=>$translate('WEB2_WIN_BOOST_CURRENT_BOOST', currentBoostTranslationsParams).value);
    const nextBoostTranslationsParams = computed(()=>({
            nextCount: props.itemsToNextBoost ? `${props.itemsToNextBoost}` : '',
            nextBonus: props.nextBoost ? getWinBoostPercentageString(props.nextBoost) : ''
        }));
    const fistBoostTranslationsParams = computed(()=>({
            ...nextBoostTranslationsParams.value,
            minOdds: props.requiredOdds ?? ''
        }));
    const nextBoostText = computed(()=>props.currentBoost ? $translate('WEB2_WIN_BOOST_NEXT_BOOST', nextBoostTranslationsParams).value : $translate('WEB2_WIN_BOOST_GET_FIRST_BOOST', fistBoostTranslationsParams).value);
    const currentBonus = computed(()=>{
        if (!props.currentWinBonus) return '';
        return $formatMoney(props.currentWinBonus);
    });
    const onInfoClick = ()=>{
        router.push({
            name: RouteName.SLIP_WIN_BOOST_INFO
        });
    };
    return {
        onInfoClick,
        currentBoostText,
        nextBoostText,
        currentBonus
    };
}
