import { KeyboardInputType } from '../../../enums';
const handleBackspace = (currentValue)=>{
    if (!currentValue.length || 1 === currentValue.length) return '';
    return currentValue.slice(0, -1);
};
const handleNumber = (currentValue, numberInput)=>{
    const numbersAfterDot = currentValue.split('.')[1];
    const reachedDecimalLimit = numbersAfterDot && numbersAfterDot.length >= 2;
    if (!numberInput || reachedDecimalLimit) return currentValue;
    if ('0' === currentValue) {
        if ('0' === numberInput) return currentValue;
        return numberInput;
    }
    return `${currentValue}${numberInput}`;
};
const handleDecimalSeparatorInput = (currentValue)=>{
    if (currentValue.includes('.')) return currentValue;
    return `${'' === currentValue ? '0' : currentValue}.`;
};
export default function getModifiedStakeInputValue(source, param) {
    let { action, value, removeCurrentValue } = param;
    const currentValue = removeCurrentValue ? '' : source;
    switch(action){
        case KeyboardInputType.BACKSPACE:
            return handleBackspace(currentValue);
        case KeyboardInputType.NUMBER:
            return handleNumber(currentValue, value);
        case KeyboardInputType.DECIMAL:
            return handleDecimalSeparatorInput(currentValue);
        default:
            return currentValue;
    }
}
