import getAccumulatorBoostPercentage from './getAccumulatorBoostPercentage';
export function getAccumulatorBoostValues(param) {
    let { sortedSettings, isWinBoost } = param;
    const minSettingRecord = sortedSettings[0];
    const maxSettingRecord = sortedSettings[sortedSettings.length - 1];
    const minCount = minSettingRecord.count ?? 0;
    const maxCount = maxSettingRecord.count ?? 0;
    const maxBoostValue = maxSettingRecord.boost ?? 0;
    const minBoostValue = minSettingRecord.boost ?? 0;
    return {
        maxBoost: isWinBoost ? maxBoostValue : getAccumulatorBoostPercentage(maxBoostValue),
        minBoost: isWinBoost ? minBoostValue : getAccumulatorBoostPercentage(minBoostValue),
        minCount,
        maxCount
    };
}
