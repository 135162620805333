import { defineStore } from 'pinia';
import { ref } from 'vue';
import { TopLevelTabs } from '../../../enums';
const useSlipViewSettingsStore = defineStore('slip-view-settings', ()=>{
    /** mobile-only props */ const isHiddenOnMobile = ref(true);
    /** true when enter animation is done */ const isFullyVisibleOnMobile = ref(false);
    const isLeaveEnterAnimationPlaying = ref(false);
    const isFullHeight = ref(false);
    /** mobile-only props end */ const activeTopTabId = ref(TopLevelTabs.SLIP);
    /* ex showSlipSettings */ const isSettingsVisible = ref(false);
    const isClearBetListWarnVisible = ref(false);
    const isSlipFromBookingCodeOverlayVisible = ref(false);
    const setIsFullyVisibleOnMobile = (value)=>{
        isFullyVisibleOnMobile.value = value;
    };
    const setSlipVisibility = (isVisible)=>{
        isHiddenOnMobile.value = !isVisible;
    };
    const setLeaveEnterAnimationStatus = (isPlaying)=>{
        isLeaveEnterAnimationPlaying.value = isPlaying;
    };
    const setSlipFullHeight = (isNowFullHeight)=>{
        isFullHeight.value = isNowFullHeight;
    };
    const selectTopTab = (id)=>{
        activeTopTabId.value = id;
    };
    const setSlipSettingsVisibility = (isVisible)=>{
        isSettingsVisible.value = isVisible;
    };
    const setClearBetListWarnVisibility = (isVisible)=>{
        isClearBetListWarnVisible.value = isVisible;
    };
    const setDefaultSlipView = ()=>{
        setSlipSettingsVisibility(false);
        selectTopTab(TopLevelTabs.SLIP);
    };
    const showSlipSettings = ()=>{
        setSlipSettingsVisibility(true);
        setSlipFullHeight(true);
    };
    const hideSlipSettings = ()=>{
        setSlipFullHeight(false);
        setSlipSettingsVisibility(false);
    };
    const handleSlipVisibilityChange = (isVisible)=>{
        setSlipVisibility(isVisible);
        setLeaveEnterAnimationStatus(true);
    };
    const stopLeaveEnterAnimation = ()=>{
        setLeaveEnterAnimationStatus(false);
    };
    const showSlipFromBookingCodeOverlay = ()=>{
        isSlipFromBookingCodeOverlayVisible.value = true;
    };
    const hideSlipFromBookingCodeOverlay = ()=>{
        isSlipFromBookingCodeOverlayVisible.value = false;
    };
    return {
        isHiddenOnMobile,
        isLeaveEnterAnimationPlaying,
        isFullHeight,
        activeTopTabId,
        isSettingsVisible,
        isClearBetListWarnVisible,
        isFullyVisibleOnMobile,
        isSlipFromBookingCodeOverlayVisible,
        setSlipVisibility,
        setIsFullyVisibleOnMobile,
        stopLeaveEnterAnimation,
        handleSlipVisibilityChange,
        setSlipFullHeight,
        selectTopTab,
        setClearBetListWarnVisibility,
        setDefaultSlipView,
        showSlipSettings,
        hideSlipSettings,
        setSlipSettingsVisibility,
        showSlipFromBookingCodeOverlay,
        hideSlipFromBookingCodeOverlay
    };
});
export default useSlipViewSettingsStore;
