import { SlipStatus } from '@leon-hub/api-sdk';
export function clearStatusOnBatchedSlipInfo(currentSlipInfo) {
    return {
        ...currentSlipInfo,
        slipEntries: currentSlipInfo.slipEntries.map((slipEntry)=>({
                ...slipEntry,
                status: SlipStatus.OK,
                message: ''
            }))
    };
}
