import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import BetSlipMessageBlock from '../BetSlipMessageBlock';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipEmptyPlaceholder',
    props: {
        buttonLabel: {
            default: ''
        },
        hideButton: {
            type: Boolean
        },
        haveButtonInSlot: {
            type: Boolean
        }
    },
    emits: [
        "button-click"
    ],
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(BetSlipMessageBlock), {
                caption: _ctx.$t('WEB2_BETSLIP_EMPTY_SLIP'),
                description: _ctx.$t('WEB2_BETSLIP_EMPTY_SLIP_DESCRIPTION')
            }, _createSlots({
                default: _withCtx(()=>[
                        _renderSlot(_ctx.$slots, "default")
                    ]),
                _: 2
            }, [
                _ctx.hideButton ? void 0 : {
                    name: "footer",
                    fn: _withCtx(()=>[
                            _createVNode(VButton, {
                                label: _ctx.buttonLabel,
                                kind: _ctx.haveButtonInSlot ? _unref(ButtonKind).TRANSPARENT : _unref(ButtonKind).SECONDARY,
                                "full-width": "",
                                onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('button-click'))
                            }, null, 8, [
                                "label",
                                "kind"
                            ])
                        ]),
                    key: "0"
                }
            ]), 1032, [
                "caption",
                "description"
            ])), [
                [
                    _directive_auto_id,
                    'BetSlipEmptyPlaceholder'
                ]
            ]);
        };
    }
});
