import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function convertSlipListItemToBetsInfoObject(slipListItem) {
    const id = getSlipEntryId(slipListItem);
    const { competitors, originalRunnerName, marketName, eventName, odds, oddsStr, isPrimaryMarket } = slipListItem;
    return {
        [id]: {
            competitors,
            originalRunnerName,
            marketName,
            eventName,
            originalOdds: odds,
            originalOddsStr: oddsStr ?? void 0,
            isPrimaryMarket
        }
    };
}
