import getAllBatchedEntries from './getAllBatchedEntries';
import getSlipEntryId from '../../../utils/getSlipEntryId';
export function replaceOriginalOddsValues(betsInfo, updatedSlipInfo) {
    const allEntries = getAllBatchedEntries(updatedSlipInfo.slipEntries);
    const result = {
        ...betsInfo
    };
    for (const entry of allEntries){
        const id = getSlipEntryId(entry);
        result[id].originalOdds = entry.odds;
        if (entry.oddsStr && result[id].originalOddsStr) result[id].originalOddsStr = entry.oddsStr;
    }
    return result;
}
