export default function getMaxAvailableFastBetValue(param) {
    let { isLoggedIn, maxStake, balance, isMultiSinglesMode, sameStakeForSingles, availableMultiSinglesCount } = param;
    if (!isLoggedIn) return maxStake || 0;
    if (isMultiSinglesMode) {
        if (sameStakeForSingles) {
            const maximumOnEverySlip = maxStake && maxStake * availableMultiSinglesCount || 0;
            if (maximumOnEverySlip <= balance) return maxStake || 0;
            return Math.floor(balance / availableMultiSinglesCount * 100) / 100;
        }
        return 0;
    }
    if (maxStake && balance > maxStake) return maxStake;
    return balance;
}
