import { computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { Events as AnalyticsEvent, TargetParamHistory } from '@leon-hub/yandex-metrika';
import RouteName from '@leon-hub/routing-config';
import { historyFilterAllBets } from '@leon-hub/api-sdk';
import { useCashoutStore } from 'web/src/modules/cashout/store';
import { ActiveOrderFilterEnum } from 'web/src/modules/profile/submodules/customer-history/types';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { getHistoryDetailsRoute } from '../utils';
import { TopLevelTabs } from '../enums';
import { usePendingBetsStore } from '../submodules/pending-bets/store';
import { useSlipViewSettingsStore } from '../submodules/slip-view-settings/store';
import { useSlipRootStore } from '../submodules/slip-root/store';
export default function useMyBetsView() {
    const { handleSlipClose } = useSlipRootStore();
    const analytics = useAnalytics();
    const pendingBetsStore = usePendingBetsStore();
    const router = useRouter();
    const { loadPendingBets, loadMorePendingBets } = pendingBetsStore;
    const { selectTopTab, setSlipFullHeight } = useSlipViewSettingsStore();
    const pendingBetsCount = toRef(pendingBetsStore, 'pendingBetsCount');
    const myBetsList = toRef(pendingBetsStore, 'myBets');
    const isPendingBetsLoaded = toRef(pendingBetsStore, 'isLoaded');
    const isPendingBetsLoadingMore = toRef(pendingBetsStore, 'isLoadingMore');
    const cashoutClick = (payload)=>{
        const { setSlipCashoutState, initCashout } = useCashoutStore();
        setSlipFullHeight(true);
        setSlipCashoutState(true);
        initCashout(payload);
    };
    const historyLinkClick = ()=>{
        handleSlipClose();
        selectTopTab(TopLevelTabs.SLIP);
        analytics.push(AnalyticsEvent.CLICK_MAP, {
            clickCounter: {
                history: TargetParamHistory.BET_SLIP
            }
        });
        router.push({
            name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY,
            query: {
                filter: historyFilterAllBets,
                activeOrderFilter: ActiveOrderFilterEnum.PENDING_BETS
            }
        });
    };
    const myBetsItemClick = (itemId)=>{
        handleSlipClose();
        router.push(getHistoryDetailsRoute(`${itemId}`));
    };
    const historyUrl = computed(()=>router.resolve({
            name: RouteName.CUSTOMER_TRANSACTIONS_HISTORY
        }).href);
    return {
        myBetsList,
        pendingBetsCount,
        loadPendingBets,
        loadMorePendingBets,
        isPendingBetsLoaded,
        isPendingBetsLoadingMore,
        cashoutClick,
        historyLinkClick,
        myBetsItemClick,
        historyUrl
    };
}
