import { computed } from 'vue';
import { BetSlipResultState, TopLevelTabs } from '../../../enums';
export default function useSlipLayoutCommon(props, emit) {
    const selectTopTab = (id)=>{
        emit('select-top-tab', id);
    };
    const showMyBetsTab = computed(()=>!!props.isLogged && props.activeTopTabId === TopLevelTabs.MY_BETS);
    const isPendingResultState = computed(()=>props.resultState === BetSlipResultState.PENDING || props.resultState === BetSlipResultState.WAIT_FOR_RETRY);
    return {
        selectTopTab,
        showMyBetsTab,
        isPendingResultState
    };
}
