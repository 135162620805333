import getSlipEntryId from '../../../utils/getSlipEntryId';
import isEntryAvailableForBetting from '../../../utils/isEntryAvailableForBetting';
import getMultiSinglesSlipEntry from './getMultiSinglesSlipEntry';
import validateMultiSinglesMetaStake from './validateMultiSinglesMetaStake';
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function getMultiSinglesMetaInfo(param) {
    let { previousValue, batchedSlipInfo, defaultBetAmount, betsInfo, balance, priceChangePolicy, formatMoney } = param;
    if (!batchedSlipInfo || batchedSlipInfo.slipEntries.length < 2) return {};
    const result = {};
    let entryWithBalanceErrorId = null;
    const haveBalance = null !== balance;
    let totalBetAmount = 0;
    for (const entry of batchedSlipInfo.slipEntries){
        const slipEntry = getMultiSinglesSlipEntry(entry);
        const id = getSlipEntryId(slipEntry);
        const relatedBetsInfo = betsInfo[id];
        const previousRecord = previousValue[id] || {};
        const previousStakeValue = previousRecord.stakeValue;
        if (haveBalance && previousRecord.stakeOverBalance) entryWithBalanceErrorId = id;
        const stakeWasEdited = !!previousRecord?.stakeWasEdited;
        const defaultValue = `${defaultBetAmount || entry.minStake || 0}`;
        const currentStake = stakeWasEdited ? previousStakeValue : defaultValue;
        const clearValueOnFocus = !!previousRecord.selectedFastBetValue || !stakeWasEdited;
        const selectedFastBetValue = previousRecord.selectedFastBetValue || null;
        if (haveBalance && isEntryAvailableForBetting({
            marketStatus: slipEntry.marketStatus,
            currentOdds: slipEntry.odds,
            originalOdds: relatedBetsInfo?.originalOdds,
            priceChangePolicy
        })) totalBetAmount += Number(currentStake) || 0;
        result[id] = validateMultiSinglesMetaStake({
            record: {
                maxStake: entry.maxStake,
                minStake: entry.minStake,
                stakeValue: currentStake,
                selectedFastBetValue,
                stakeWasEdited,
                clearValueOnFocus
            },
            formatMoney
        });
    }
    if (haveBalance && entryWithBalanceErrorId && totalBetAmount > balance) result[entryWithBalanceErrorId].stakeOverBalance = true;
    return result;
}
