import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "href"
];
import { CashoutButton } from 'web/src/components/BetCashOut/components';
import useSlipListHistoryItem from '../composables/useSlipListHistoryItem';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SlipListHistoryItem',
    props: {
        id: {},
        marketName: {},
        itemsQuantity: {},
        cashOutAvailable: {
            type: Boolean
        },
        stake: {},
        eventDate: {},
        betType: {},
        cashoutAmount: {},
        stakeAmount: {},
        eventName: {},
        competitors: {},
        odd: {},
        runnerName: {},
        isLive: {
            type: Boolean
        }
    },
    emits: [
        "cash-out-click",
        "item-click"
    ],
    setup (__props) {
        const props = __props;
        const { computedRunnerName, betDetailsUrl } = useSlipListHistoryItem(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("a", {
                href: _unref(betDetailsUrl),
                class: _normalizeClass({
                    [_ctx.$style['slip-list-item']]: true,
                    [_ctx.$style['slip-list-item--is-done']]: true
                }),
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event)=>_ctx.$emit('item-click'), [
                    "prevent"
                ]))
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['slip-list-item__wrapper-inner'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['slip-list-item__container'])
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['slip-list-item__main'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['slip-list-item__columns'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        _ctx.$style['slip-list-item__column'],
                                        _ctx.$style['slip-list-item__column--status']
                                    ])
                                }, _toDisplayString(_ctx.isLive ? _ctx.$t('WEB2_SLIP_GAME_STARTED') : _ctx.$t('WEB2_SLIP_BET_PLACED')), 3),
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        _ctx.$style['slip-list-item__column'],
                                        _ctx.$style['slip-list-item__column--date']
                                    ])
                                }, _toDisplayString(_ctx.eventDate), 3)
                            ], 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['slip-list-item__columns'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        _ctx.$style['slip-list-item__column'],
                                        _ctx.$style['slip-list-item__column--left']
                                    ])
                                }, [
                                    _ctx.competitors?.length ? (_openBlock(), _createElementBlock("ul", {
                                        key: 0,
                                        class: _normalizeClass(_ctx.$style['slip-list-item__competitors'])
                                    }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.competitors, (team)=>(_openBlock(), _createElementBlock("li", {
                                                key: team,
                                                class: _normalizeClass(_ctx.$style['slip-list-item__competitors-member'])
                                            }, _toDisplayString(team), 3))), 128))
                                    ], 2)) : (_openBlock(), _createElementBlock("p", {
                                        key: 1,
                                        class: _normalizeClass(_ctx.$style['slip-list-item__caption'])
                                    }, _toDisplayString(_ctx.eventName), 3)),
                                    _createElementVNode("p", {
                                        class: _normalizeClass(_ctx.$style['slip-list-item__market-runner'])
                                    }, [
                                        _ctx.isLive ? (_openBlock(), _createElementBlock("span", {
                                            key: 0,
                                            class: _normalizeClass(_ctx.$style['slip-list-item__live-badge'])
                                        }, " LIVE ", 2)) : _createCommentVNode("", true),
                                        _createElementVNode("span", null, _toDisplayString(_ctx.marketName ? `${_ctx.marketName} - ` : ''), 1),
                                        _createElementVNode("span", {
                                            class: _normalizeClass(_ctx.$style['slip-list-item__runner-name'])
                                        }, _toDisplayString(_unref(computedRunnerName)), 3)
                                    ], 2)
                                ], 2),
                                _createElementVNode("div", {
                                    class: _normalizeClass([
                                        _ctx.$style['slip-list-item__column'],
                                        _ctx.$style['slip-list-item__column--right']
                                    ])
                                }, [
                                    _createElementVNode("div", {
                                        class: _normalizeClass(_ctx.$style['slip-list-item__odds-container'])
                                    }, [
                                        _createElementVNode("p", {
                                            class: _normalizeClass(_ctx.$style['slip-list-item__odd'])
                                        }, _toDisplayString(_ctx.odd), 3)
                                    ], 2),
                                    _createElementVNode("p", {
                                        class: _normalizeClass(_ctx.$style['slip-list-item__bet-value'])
                                    }, _toDisplayString(_ctx.stake), 3)
                                ], 2)
                            ], 2)
                        ], 2)
                    ], 2),
                    _ctx.cashOutAvailable ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['slip-list-item__cash-out'])
                    }, [
                        _createVNode(_unref(CashoutButton), {
                            "cashout-amount": _ctx.cashoutAmount,
                            "stake-amount": _ctx.stakeAmount,
                            onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('cash-out-click'))
                        }, null, 8, [
                            "cashout-amount",
                            "stake-amount"
                        ])
                    ], 2)) : _createCommentVNode("", true)
                ], 2)
            ], 10, _hoisted_1)), [
                [
                    _directive_auto_id,
                    'SlipListHistoryItem'
                ]
            ]);
        };
    }
});
