import { MarketStatus } from '@leon-hub/api-sdk';
export default function isUnavailableEntryStatus(marketStatus) {
    if (!marketStatus) return false;
    switch(marketStatus){
        case MarketStatus.LIMIT:
        case MarketStatus.MISSING:
        case MarketStatus.CLOSED:
        case MarketStatus.SUSPENDED:
        case MarketStatus.DUPLICATE:
            return true;
        default:
            return false;
    }
}
