import isMultiSinglesMetaInfoRecordStakeValid from '../../multi-singles/utils/isMultiSinglesMetaInfoRecordStakeValid';
import isEntryAvailableForBetting from '../../../utils/isEntryAvailableForBetting';
export default function getSinglesAvailableForBetting(param) {
    let { input, priceChangePolicy, sameStake } = param;
    return input.filter((item)=>{
        if (!sameStake) {
            const { stakeValue, minStake, maxStake } = item.metaInfo || {};
            if (!item.metaInfo || !isMultiSinglesMetaInfoRecordStakeValid({
                stakeValue,
                minStake,
                maxStake
            })) return false;
        }
        return isEntryAvailableForBetting({
            marketStatus: item.marketStatus,
            priceChangePolicy,
            currentOdds: item.odds,
            originalOdds: item.originalOdds
        });
    });
}
