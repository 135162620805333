import { toRef, computed } from 'vue';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useRootStore } from 'web/src/modules/core/store';
export default function useDefaultBetValue() {
    const customerDataStore = useCustomerDataStore();
    const useStandardBet = toRef(customerDataStore, 'useStandardBet');
    const standardBetAmount = toRef(customerDataStore, 'standardBetAmount');
    const rootStore = useRootStore();
    const slipDefaultAmount = toRef(rootStore, 'slipDefaultAmount');
    const defaultBetValue = computed(()=>{
        if (useStandardBet.value && standardBetAmount.value) return standardBetAmount.value;
        return slipDefaultAmount.value ?? null;
    });
    return {
        defaultBetValue
    };
}
