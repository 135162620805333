import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createVNode as _createVNode, vShow as _vShow } from "vue";
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { BetSlipResultState } from '../../enums';
import useBetSlipResult from './composables/useBetSlipResult';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipResult',
    props: {
        mode: {
            default: BetSlipResultState.PENDING
        },
        errorMessage: {
            default: ''
        },
        limitsExceeded: {
            type: Boolean
        },
        totalBetsPlaced: {
            default: 0
        },
        totalBetsAccepted: {
            default: 0
        },
        isMultiSinglesMode: {
            type: Boolean
        },
        isShareAvailable: {
            type: Boolean
        }
    },
    emits: [
        "go-to-my-bets",
        "leave-click",
        "limits-click",
        "close-on-error",
        "share-bet"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isContentVisible, isPartiallyAccepted, iconName, headingText, descriptionText, isCompleted, isSuccess, isLimitsButtonShown, mainButtonLabel, mainButtonIcon, isCloseAndClearButtonShown, isPartiallyAcceptedByLimits, shareButtonVisible, goToMyBets, onMainButtonClick, onSaveAllClick, onCloseAndClearClick, onLimitsClick, onShareClick } = useBetSlipResult(props, emits);
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['bet-slip-result']]: true,
                    [_ctx.$style['bet-slip-result--completed']]: _unref(isCompleted),
                    [_ctx.$style['bet-slip-result--desktop']]: true
                })
            }, [
                _unref(isContentVisible) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['bet-slip-result__container'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['bet-slip-result__icon']]: true,
                            [_ctx.$style['bet-slip-result__icon--success']]: _unref(isSuccess)
                        })
                    }, [
                        _unref(isCompleted) && _unref(iconName) ? (_openBlock(), _createBlock(_unref(VIcon), {
                            key: 0,
                            size: _unref(IconSize).SIZE_60,
                            name: _unref(iconName)
                        }, null, 8, [
                            "size",
                            "name"
                        ])) : _createCommentVNode("", true)
                    ], 2),
                    _unref(headingText) ? (_openBlock(), _createElementBlock("p", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['bet-slip-result__heading'])
                    }, _toDisplayString(_unref(headingText)), 3)) : _createCommentVNode("", true),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bet-slip-result__description'])
                    }, [
                        _unref(isCompleted) ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createTextVNode(_toDisplayString(_unref(descriptionText)) + " ", 1),
                            _unref(isSuccess) && !_unref(isPartiallyAcceptedByLimits) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['bet-slip-result__description-second'])
                            }, [
                                _createElementVNode("button", {
                                    class: _normalizeClass(_ctx.$style['bet-slip-result__to-my-bets']),
                                    "data-test-id": "my-bets-button",
                                    type: "button",
                                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                                    function() {
                                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                            args[_key] = arguments[_key];
                                        }
                                        return _unref(goToMyBets) && _unref(goToMyBets)(...args);
                                    })
                                }, _toDisplayString(_ctx.$t('WEB2_SLIP_MY_BETS')), 3)
                            ], 2)) : _createCommentVNode("", true)
                        ], 64)) : _createCommentVNode("", true)
                    ], 2)
                ], 2)) : _createCommentVNode("", true),
                _withDirectives(_createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bet-slip-result__buttons'])
                }, [
                    _unref(isLimitsButtonShown) ? _withDirectives((_openBlock(), _createBlock(VButton, {
                        key: 0,
                        "full-width": "",
                        kind: _unref(ButtonKind).PRIMARY,
                        label: _ctx.$t('WEB2_PLACE_BET_LIMITS_BUTTON'),
                        class: _normalizeClass(_ctx.$style['bet-slip-result__limits-button']),
                        onClick: _unref(onLimitsClick)
                    }, null, 8, [
                        "kind",
                        "label",
                        "class",
                        "onClick"
                    ])), [
                        [
                            _directive_data_test,
                            {
                                el: 'bet-slip-button_result_limits'
                            }
                        ]
                    ]) : _createCommentVNode("", true),
                    _withDirectives(_createVNode(VButton, {
                        label: _unref(mainButtonLabel),
                        kind: _unref(isSuccess) ? _unref(ButtonKind).PRIMARY : _unref(ButtonKind).SECONDARY,
                        "icon-name": _unref(mainButtonIcon),
                        "full-width": "",
                        onClick: _unref(onMainButtonClick)
                    }, null, 8, [
                        "label",
                        "kind",
                        "icon-name",
                        "onClick"
                    ]), [
                        [
                            _directive_data_test,
                            {
                                el: 'bet-slip-button_result',
                                mode: _ctx.mode
                            }
                        ]
                    ]),
                    _unref(shareButtonVisible) ? (_openBlock(), _createBlock(VButton, {
                        key: 1,
                        label: _ctx.$t('WEB2_SHARE'),
                        "full-width": "",
                        kind: _unref(ButtonKind).SECONDARY,
                        "icon-name": _unref(IconName).SHARE,
                        class: _normalizeClass(_ctx.$style['bet-slip-result__button-top-margin']),
                        onClick: _unref(onShareClick)
                    }, null, 8, [
                        "label",
                        "kind",
                        "icon-name",
                        "class",
                        "onClick"
                    ])) : _createCommentVNode("", true),
                    _unref(isSuccess) && !_unref(isPartiallyAcceptedByLimits) ? (_openBlock(), _createBlock(VButton, {
                        key: 2,
                        label: _ctx.$t('WEB2_SLIP_SAVE_BETS_ALL'),
                        kind: _unref(shareButtonVisible) || !_unref(isPartiallyAccepted) ? _unref(ButtonKind).TRANSPARENT : _unref(ButtonKind).SECONDARY,
                        "full-width": "",
                        class: _normalizeClass(_ctx.$style['bet-slip-result__button-top-margin']),
                        onClick: _unref(onSaveAllClick)
                    }, null, 8, [
                        "label",
                        "kind",
                        "class",
                        "onClick"
                    ])) : _createCommentVNode("", true),
                    _unref(isCloseAndClearButtonShown) ? (_openBlock(), _createBlock(VButton, {
                        key: 3,
                        label: _unref(isPartiallyAcceptedByLimits) ? _ctx.$t('WEB2_CLOSE') : _ctx.$t('WEB2_SLIP_SAVE_BETS_CLEAR'),
                        kind: _unref(ButtonKind).TRANSPARENT,
                        "full-width": "",
                        class: _normalizeClass(_ctx.$style['bet-slip-result__close-clear-button']),
                        onClick: _unref(onCloseAndClearClick)
                    }, null, 8, [
                        "label",
                        "kind",
                        "class",
                        "onClick"
                    ])) : _createCommentVNode("", true)
                ], 2), [
                    [
                        _vShow,
                        _unref(isLimitsButtonShown) || _unref(isCompleted)
                    ]
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetSlipResult'
                ]
            ]);
        };
    }
});
