import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function getMultiSinglesMetaInfoForCache(param) {
    let { slipEntryCache, currentMetaInfo, defaultBetAmount } = param;
    const cacheMetaInfo = {};
    for (const item of slipEntryCache)cacheMetaInfo[getSlipEntryId(item)] = {
        stakeValue: `${defaultBetAmount || 0}`
    };
    return {
        ...currentMetaInfo,
        ...cacheMetaInfo
    };
}
