import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
import { SettingsTabs } from '../../../enums';
export default function useBetSlipSettingsView(emit) {
    const { $translate } = useI18n();
    const settingsTabs = computed(()=>[
            {
                id: SettingsTabs.COMMON_SETTINGS,
                label: $translate('WEB2_SLIP_SETTINGS_TAB_COMMON').value
            },
            {
                id: SettingsTabs.FAST_BETS,
                label: $translate('WEB2_SLIP_SETTINGS_TAB_FAST_BETS').value
            }
        ]);
    const emitSelectSettingsTab = (id)=>{
        emit('select-settings-tab', id);
    };
    const emitSettingsBackClick = ()=>{
        emit('settings-back-click');
    };
    const handleScroll = (event)=>{};
    return {
        settingsTabs,
        emitSelectSettingsTab,
        emitSettingsBackClick,
        handleScroll
    };
}
