import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { BetType } from '@leon-hub/api-sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getHistoryDetailsRoute } from '../../../utils';
export default function useSlipListHistoryItem(props) {
    const { $translate } = useI18n();
    const router = useRouter();
    const count = computed(()=>({
            COUNT: `${props.itemsQuantity ?? ''}`
        }));
    const computedRunnerName = computed(()=>{
        if (props.betType === BetType.EXPRESS) return $translate('WEB2_BET_EXPRESS_MULTIPLICITY', count).value;
        return props.runnerName ?? '';
    });
    const betDetailsUrl = computed(()=>router.resolve(getHistoryDetailsRoute(`${props.id}`)).href);
    return {
        computedRunnerName,
        betDetailsUrl
    };
}
