export default function modifyMetaInfoRecordOnBlur(id, currentMetaInfo) {
    const matchedItem = currentMetaInfo[id];
    if (!matchedItem || matchedItem.stakeValue) return null;
    const updatedItem = {
        ...matchedItem,
        stakeValue: '0',
        clearValueOnFocus: true
    };
    const copy = {
        ...currentMetaInfo
    };
    copy[id] = updatedItem;
    return copy;
}
