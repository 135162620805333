import { logger } from '@leon-hub/logging';
import isSlipEntry from '../../../guards/isSlipEntry';
import findSlipEntryById from '../../../utils/findSlipEntryById';
export default function logRemoveFromSlip(id, allBatchedItems) {
    const slipEntry = findSlipEntryById(id, allBatchedItems) ?? {};
    isSlipEntry(slipEntry);
    const { betline, event, market, runner, odds } = slipEntry;
    logger.info('Removing from betslip', {
        betline,
        event,
        market,
        runner,
        odds
    });
}
