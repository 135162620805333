import { SlipTypeId } from '@leon-hub/api-sdk';
import { SlipTypeSingle } from '../../../constants';
export default function getMatchedSlipType(param) {
    let { betMode, slipTypes, selectedSystemValue, slipSize } = param;
    let matchedOption;
    switch(betMode){
        case SlipTypeId.SINGLE:
            return SlipTypeSingle;
        case SlipTypeId.EXPRESS:
            matchedOption = slipTypes.find((item)=>item && item.typeId === betMode);
            if (matchedOption) return matchedOption.code;
            break;
        case SlipTypeId.SYSTEM:
            if (slipSize < 3) break;
            if (selectedSystemValue) matchedOption = slipTypes.find((item)=>item && item.typeId === betMode && item.code === selectedSystemValue);
            if (!matchedOption) matchedOption = slipTypes.find((item)=>item && item.typeId === betMode);
            return matchedOption ? matchedOption.code : `0_2/${slipSize}`;
        default:
            break;
    }
    return '';
}
