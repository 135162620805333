import { KeyboardInputType } from '../../../enums';
export function getKeyBoardInputPayloadByKey(key) {
    let removeCurrentValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    let payload = null;
    if (!key) return null;
    if (/^\d*$/.test(key)) payload = {
        action: KeyboardInputType.NUMBER,
        value: key,
        removeCurrentValue
    };
    if (/[,.]/.test(key)) payload = {
        action: KeyboardInputType.DECIMAL,
        removeCurrentValue
    };
    if ('backspace' === key.toLowerCase()) payload = {
        action: KeyboardInputType.BACKSPACE
    };
    return payload;
}
