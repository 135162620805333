import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { useWinBoostInfoPage } from '../composable';
export default /*@__PURE__*/ _defineComponent({
    __name: 'WinBoostInfoRoutePage',
    setup (__props) {
        const { descriptionText, tableRows } = useWinBoostInfoPage();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['win-boost-info'])
            }, [
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style['win-boost-info__description'])
                }, _toDisplayString(_unref(descriptionText)), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['win-boost-info__table-background'])
                }, [
                    _createElementVNode("table", {
                        class: _normalizeClass(_ctx.$style['win-boost-table'])
                    }, [
                        _createElementVNode("caption", {
                            class: _normalizeClass(_ctx.$style['win-boost-table__caption'])
                        }, _toDisplayString(_ctx.$t('WEB2_WIN_BOOST_INFO_TABLE_CAPTION')), 3),
                        _createElementVNode("colgroup", null, [
                            _createElementVNode("col", {
                                class: _normalizeClass(_ctx.$style['win-boost-table__col-count'])
                            }, null, 2),
                            _createElementVNode("col", {
                                class: _normalizeClass(_ctx.$style['win-boost-table__col-boost'])
                            }, null, 2)
                        ]),
                        _createElementVNode("thead", {
                            class: _normalizeClass(_ctx.$style['win-boost-table__head'])
                        }, [
                            _createElementVNode("tr", {
                                class: _normalizeClass(_ctx.$style['win-boost-table__row'])
                            }, [
                                _createElementVNode("th", {
                                    class: _normalizeClass([
                                        _ctx.$style['win-boost-table__cell'],
                                        _ctx.$style['win-boost-table__cell--count']
                                    ])
                                }, _toDisplayString(_ctx.$t('WEB2_WIN_BOOST_INFO_TABLE_COUNT')), 3),
                                _createElementVNode("th", {
                                    class: _normalizeClass([
                                        _ctx.$style['win-boost-table__cell'],
                                        _ctx.$style['win-boost-table__cell--boost']
                                    ])
                                }, _toDisplayString(_ctx.$t('WEB2_WIN_BOOST_INFO_TABLE_BOOST')), 3)
                            ], 2)
                        ], 2),
                        _createElementVNode("tbody", {
                            class: _normalizeClass(_ctx.$style['win-boost-table__body'])
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tableRows), (row, index)=>(_openBlock(), _createElementBlock("tr", {
                                    key: index,
                                    class: _normalizeClass(_ctx.$style['win-boost-table__row'])
                                }, [
                                    _createElementVNode("td", {
                                        class: _normalizeClass([
                                            _ctx.$style['win-boost-table__cell'],
                                            _ctx.$style['win-boost-table__cell--count']
                                        ])
                                    }, _toDisplayString(row.count), 3),
                                    _createElementVNode("td", {
                                        class: _normalizeClass([
                                            _ctx.$style['win-boost-table__cell'],
                                            _ctx.$style['win-boost-table__cell--boost']
                                        ])
                                    }, _toDisplayString(row.boost), 3)
                                ], 2))), 128))
                        ], 2)
                    ], 2)
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'WinBoostInfoRoutePage'
                ]
            ]);
        };
    }
});
