import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    class: "bet-slip-desktop-system-dropdown"
};
import { VCoreDropdown } from 'web/src/components/CoreDropdown';
import { DropdownMenu } from 'web/src/components/Select';
import { useDropdownSelect } from 'web/src/components/Select/composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipDesktopDropdown',
    props: {
        isFreebet: {
            type: Boolean
        },
        options: {
            default: ()=>[]
        },
        ignoreScroll: {
            type: Boolean
        },
        name: {
            default: 'SlipDesktopDropdown'
        },
        selectedValue: {
            default: ''
        }
    },
    emits: [
        "input",
        "change",
        "focus",
        "blur",
        "close",
        "open"
    ],
    setup (__props, param) {
        let { expose: __expose, emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isDropdownShown, onFocus, onChange, onBlur, close, toggleOpen, focusOnDropdownButton, dropdownProps, dropdownButton, optionsToRender, handleKeyDown, setPreselectedIndex, preselectedListIndex } = useDropdownSelect(props, emits);
        __expose({
            focus: focusOnDropdownButton
        });
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("button", {
                    ref_key: "dropdownButton",
                    ref: dropdownButton,
                    type: "button",
                    class: _normalizeClass(_ctx.$style['bet-slip-desktop-system-dropdown__button']),
                    onFocus: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onFocus) && _unref(onFocus)(...args);
                    }),
                    onBlur: _cache[1] || (_cache[1] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(onBlur) && _unref(onBlur)(...args);
                    }),
                    onClick: _cache[2] || (_cache[2] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(toggleOpen) && _unref(toggleOpen)(...args);
                    }),
                    onKeydown: _cache[3] || (_cache[3] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(handleKeyDown) && _unref(handleKeyDown)(...args);
                    })
                }, null, 34),
                _unref(isDropdownShown) ? (_openBlock(), _createElementBlock(_Fragment, {
                    key: 0
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bet-slip-desktop-system-dropdown__mask']),
                        onClick: _cache[4] || (_cache[4] = _withModifiers(()=>{}, [
                            "stop"
                        ]))
                    }, null, 2),
                    _unref(dropdownProps) ? (_openBlock(), _createBlock(_unref(VCoreDropdown), _mergeProps({
                        key: 0
                    }, _unref(dropdownProps), {
                        "match-parent-width": "",
                        "ignore-scroll": _ctx.ignoreScroll,
                        onClose: _unref(close)
                    }), {
                        default: _withCtx((param)=>{
                            let { maxHeight, directionTop, mounted, onContentRedraw } = param;
                            return [
                                _createVNode(_unref(DropdownMenu), {
                                    name: _ctx.name,
                                    class: _normalizeClass(_ctx.$style['bet-slip-desktop-system-dropdown__drop']),
                                    "max-height": maxHeight,
                                    "direction-top": directionTop,
                                    "is-freebet": _ctx.isFreebet,
                                    "selected-value": _ctx.selectedValue,
                                    options: _unref(optionsToRender),
                                    "preselected-list-index": _unref(preselectedListIndex),
                                    "forced-light-theme": "",
                                    onChange: _unref(onChange),
                                    onClose: _unref(close),
                                    onMounted: mounted,
                                    onOptionHover: _unref(setPreselectedIndex),
                                    onListSizeUpdated: onContentRedraw
                                }, null, 8, [
                                    "name",
                                    "class",
                                    "max-height",
                                    "direction-top",
                                    "is-freebet",
                                    "selected-value",
                                    "options",
                                    "preselected-list-index",
                                    "onChange",
                                    "onClose",
                                    "onMounted",
                                    "onOptionHover",
                                    "onListSizeUpdated"
                                ])
                            ];
                        }),
                        _: 1
                    }, 16, [
                        "ignore-scroll",
                        "onClose"
                    ])) : _createCommentVNode("", true)
                ], 64)) : _createCommentVNode("", true)
            ])), [
                [
                    _directive_auto_id,
                    'BetSlipDesktopDropdown'
                ]
            ]);
        };
    }
});
