import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function getSlipEntriesWithToggledBanker(id, currentSlipEntries) {
    const slipEntriesCopy = [
        ...currentSlipEntries
    ];
    const matchedEventIndex = slipEntriesCopy.findIndex((entry)=>getSlipEntryId(entry) === id);
    if (matchedEventIndex < 0) return null;
    const matchedEntry = slipEntriesCopy[matchedEventIndex];
    slipEntriesCopy[matchedEventIndex] = {
        ...matchedEntry,
        banker: !matchedEntry.banker
    };
    return slipEntriesCopy;
}
