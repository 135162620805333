import { Timer } from '@leon-hub/utils';
import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function handleClosedMarketsChange(param, removeFunction) {
    let { changedEntries, currentlyClosedMarkets, unlockedEventsIds, timeout } = param;
    const closedMarketsCopy = {
        ...currentlyClosedMarkets
    };
    const deadEventsIds = changedEntries.map((entry)=>getSlipEntryId(entry));
    const recentlyDeadEvents = deadEventsIds.filter((id)=>!closedMarketsCopy[id]);
    const resurrectedEvents = [
        ...unlockedEventsIds
    ].filter((id)=>closedMarketsCopy[id]);
    for (const id of resurrectedEvents){
        const event = closedMarketsCopy[id];
        if (!!event) {
            Timer.clearTimeout(event);
            delete closedMarketsCopy[id];
        }
    }
    for (const id of recentlyDeadEvents)closedMarketsCopy[id] = Timer.setTimeout(()=>{
        removeFunction(id);
    }, timeout);
    return closedMarketsCopy;
}
