import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipConfirmClearOverlay',
    emits: [
        "confirm",
        "cancel"
    ],
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['bet-slip-clear-overlay'])
            }, [
                _createVNode(_unref(VIcon), {
                    name: _unref(IconName).ATTENTION_OUTLINE,
                    class: _normalizeClass(_ctx.$style['bet-slip-clear-overlay__icon'])
                }, null, 8, [
                    "name",
                    "class"
                ]),
                _createElementVNode("p", {
                    class: _normalizeClass(_ctx.$style['bet-slip-clear-overlay__label'])
                }, _toDisplayString(_ctx.$t('WEB2_SLIP_CLEAR_WARNING')), 3),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bet-slip-clear-overlay__footer'])
                }, [
                    _createVNode(VButton, {
                        class: _normalizeClass(_ctx.$style['bet-slip-clear-overlay__button']),
                        label: _ctx.$t('JSPFIN_BETSLIM_DEL'),
                        kind: _unref(ButtonKind).PRIMARY,
                        onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('confirm'))
                    }, null, 8, [
                        "class",
                        "label",
                        "kind"
                    ]),
                    _createVNode(VButton, {
                        class: _normalizeClass([
                            _ctx.$style['bet-slip-clear-overlay__button'],
                            _ctx.$style['bet-slip-clear-overlay__button--cancel']
                        ]),
                        label: _ctx.$t('WEB2_MOBILE_CANCEL'),
                        kind: _unref(ButtonKind).TRANSPARENT,
                        onClick: _cache[1] || (_cache[1] = ($event)=>_ctx.$emit('cancel'))
                    }, null, 8, [
                        "class",
                        "label",
                        "kind"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetSlipConfirmClearOverlay'
                ]
            ]);
        };
    }
});
