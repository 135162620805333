import { computed } from 'vue';
import { getFreebetMaxPrize } from 'web/src/modules/slip/utils';
export function useMaxPrize(props) {
    const { isMultiSinglesMode, totalOdds, selectedStakeValue, currentFreeBet, multiSinglesAvailableForBet, sameStakeForSingles } = props;
    const multiSinglesTotalWin = computed(()=>{
        if (!isMultiSinglesMode.value) return 0;
        return multiSinglesAvailableForBet.value.reduce((accumulator, currentEntry)=>{
            const amount = sameStakeForSingles.value ? selectedStakeValue.value : Number(currentEntry.metaInfo?.stakeValue || 0);
            return accumulator + amount * currentEntry.odds;
        }, 0);
    });
    const maxPrize = computed(()=>{
        if (isMultiSinglesMode.value) return multiSinglesTotalWin.value;
        if (!totalOdds.value || !selectedStakeValue.value) return 0;
        if (currentFreeBet.value) return getFreebetMaxPrize(currentFreeBet.value, totalOdds.value);
        return totalOdds.value * selectedStakeValue.value;
    });
    return {
        maxPrize
    };
}
