import { computed } from 'vue';
import { useI18n } from 'web/src/modules/i18n/composables';
export function useBookingCodeLoadResult(props, emit) {
    const { $translate } = useI18n();
    const logoUrl = computed(()=>{
        let name = 'mascot';
        if (props.isLightTheme) name += '--light';
        // eslint-disable-next-line global-require,import/no-dynamic-require
        return require(`web/src/assets/mascot/li/default/${name}.svg`);
    });
    const buttonText = computed(()=>{
        if (props.isAllEventsExpired) return props.isLoggedIn ? $translate('WEB2_BOOKING_CODE_TO_HOMEPAGE').value : $translate('WEB2_DO_LOGIN').value;
        return $translate('WEB2_BOOKING_CODE_TO_SLIP').value;
    });
    const onButtonClick = ()=>emit('button-click');
    return {
        logoUrl,
        buttonText,
        onButtonClick
    };
}
