import RouteName from '@leon-hub/routing-config';
import { TransactionGroup } from '@leon-hub/api-sdk';
export default function getHistoryDetailsRoute(id) {
    return {
        name: RouteName.CUSTOMER_HISTORY_TRANSACTION_DETAILS,
        params: {
            id,
            group: TransactionGroup.BET.toLowerCase()
        }
    };
}
