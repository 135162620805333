import findSlipEntryById from '../../../utils/findSlipEntryById';
export default function updateBetsInfoOddById(param) {
    let { id, allEntries, currentBetsInfo } = param;
    const currentItem = findSlipEntryById(id, allEntries);
    if (currentItem && currentBetsInfo[id]) return {
        ...currentBetsInfo,
        [id]: {
            ...currentBetsInfo[id],
            originalOdds: currentItem.odds
        }
    };
    return null;
}
