import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "data-test-id",
    "onClick"
];
import { TopLevelTabs } from '../../enums';
import useBetSlipTabs from './composables/useBetSlipTabs';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipTabs',
    props: {
        isLogged: {
            type: Boolean
        },
        activeTabId: {
            default: TopLevelTabs.SLIP
        },
        slipSize: {
            default: 0
        },
        myBetsCount: {
            default: 0
        }
    },
    emits: [
        "tab-click"
    ],
    setup (__props) {
        const props = __props;
        const { tabsItems } = useBetSlipTabs(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['bet-slip-tabs'])
            }, [
                _createElementVNode("ul", {
                    class: _normalizeClass(_ctx.$style['bet-slip-tabs__list'])
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tabsItems), (param)=>{
                        let { id, label, count, isActive } = param;
                        return _openBlock(), _createElementBlock("li", {
                            key: id,
                            class: _normalizeClass(_ctx.$style['bet-slip-tabs__item'])
                        }, [
                            _createElementVNode("button", {
                                class: _normalizeClass({
                                    [_ctx.$style['bet-slip-tabs__button']]: true,
                                    [_ctx.$style['bet-slip-tabs__button--active']]: isActive
                                }),
                                "data-test-id": `tab-${id}`,
                                type: "button",
                                onClick: ($event)=>_ctx.$emit('tab-click', id)
                            }, [
                                _createTextVNode(_toDisplayString(label) + " ", 1),
                                _createElementVNode("span", {
                                    class: _normalizeClass({
                                        [_ctx.$style['bet-slip-tabs__count']]: true,
                                        [_ctx.$style['bet-slip-tabs__count--active']]: isActive
                                    })
                                }, _toDisplayString(count), 3)
                            ], 10, _hoisted_1)
                        ], 2);
                    }), 128))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetSlipTabs'
                ]
            ]);
        };
    }
});
