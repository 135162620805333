export function getSlipEntryFromSharedSlipEntry(input) {
    return {
        ts: input.ts,
        event: input.event,
        market: input.market,
        runner: input.runner,
        odds: input.odds,
        oddsStr: input.oddsStr,
        banker: input.banker,
        marketStatus: input.marketStatus,
        betline: input.betline,
        zeroMargin: input.zeroMargin
    };
}
