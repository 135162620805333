import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "src"
];
import { ButtonKind } from '@leon-hub/module-buttons';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { useBookingCodeLoadResult } from '../composables/useBookingCodeLoadResult';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BookingCodeLoadResult',
    props: {
        isAllEventsExpired: {
            type: Boolean
        },
        isLoggedIn: {
            type: Boolean
        },
        isLightTheme: {
            type: Boolean
        },
        isFromLink: {
            type: Boolean
        }
    },
    emits: [
        "button-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { logoUrl, buttonText, onButtonClick } = useBookingCodeLoadResult(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['booking-code-load-result'])
            }, [
                _createElementVNode("div", null, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['booking-code-load-result__logo'])
                    }, [
                        _createElementVNode("img", {
                            src: _unref(logoUrl),
                            alt: "logo",
                            class: _normalizeClass(_ctx.$style['booking-code-load-result__img'])
                        }, null, 10, _hoisted_1)
                    ], 2),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['booking-code-load-result__header'])
                    }, _toDisplayString(_ctx.isAllEventsExpired ? _ctx.$t('WEB2_BOOKING_CODE_ALL_EXPIRED_CAPTION') : _ctx.$t('WEB2_BOOKING_CODE_CERTAIN_EXPIRED_CAPTION')), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['booking-code-load-result__text'])
                    }, [
                        _ctx.isAllEventsExpired ? (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('WEB2_BOOKING_CODE_ALL_EXPIRED_DESCRIPTION')), 1),
                            _createElementVNode("div", null, _toDisplayString(_ctx.$t('WEB2_BOOKING_CODE_ALL_EXPIRED_LOGIN')), 1)
                        ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                            key: 1
                        }, [
                            _createTextVNode(_toDisplayString(_ctx.isFromLink ? _ctx.$t('WEB2_BOOKING_CODE_CERTAIN_EXPIRED_FROM_LINK') : _ctx.$t('WEB2_BOOKING_CODE_CERTAIN_EXPIRED_FROM_CODE')), 1)
                        ], 64))
                    ], 2)
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['booking-code-load-result__button'])
                }, [
                    _createVNode(VButton, {
                        label: _unref(buttonText),
                        kind: _unref(ButtonKind).PRIMARY,
                        "full-width": "",
                        onClick: _unref(onButtonClick)
                    }, null, 8, [
                        "label",
                        "kind",
                        "onClick"
                    ])
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BookingCodeLoadResult'
                ]
            ]);
        };
    }
});
