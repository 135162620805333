import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "title"
];
import { IconDirection, IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import VTabs from 'web/src/components/Tabs/VTabs/VTabs.vue';
import { SettingsTabs } from '../../enums';
import useBetSlipSettingsView from './composables/useBetSlipSettingsView';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipSettingsView',
    props: {
        activeSettingsTabId: {
            default: SettingsTabs.COMMON_SETTINGS
        }
    },
    emits: [
        "select-settings-tab",
        "settings-back-click",
        "scroll"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emits = __emit;
        const { settingsTabs, emitSelectSettingsTab, emitSettingsBackClick, handleScroll } = useBetSlipSettingsView(emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['bet-slip-settings-view'])
            }, [
                _createElementVNode("header", {
                    class: _normalizeClass(_ctx.$style['bet-slip-settings-view__caption'])
                }, [
                    _createElementVNode("button", {
                        type: "button",
                        title: _ctx.$t('WEB2_BACK_LABEL'),
                        class: _normalizeClass(_ctx.$style['bet-slip-settings-view__caption-button']),
                        "data-test-id": "settings-back",
                        onClick: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(emitSettingsBackClick) && _unref(emitSettingsBackClick)(...args);
                        })
                    }, [
                        _createVNode(_unref(VIcon), {
                            name: _unref(IconName).ARROW,
                            direction: _unref(IconDirection).WEST,
                            size: _unref(IconSize).SIZE_24
                        }, null, 8, [
                            "name",
                            "direction",
                            "size"
                        ])
                    ], 10, _hoisted_1),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bet-slip-settings-view__caption-text'])
                    }, _toDisplayString(_ctx.$t('JS_SLIP_BET_SETTINGS')), 3)
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bet-slip-settings-view__tabs'])
                }, [
                    _createVNode(VTabs, {
                        items: _unref(settingsTabs),
                        "active-id": _ctx.activeSettingsTabId,
                        "full-width": "",
                        "data-test-id": "settings-tabs",
                        onTabClick: _unref(emitSelectSettingsTab)
                    }, null, 8, [
                        "items",
                        "active-id",
                        "onTabClick"
                    ])
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bet-slip-settings-view__main']),
                    "data-test-id": "settings-scroll-component",
                    onScroll: _cache[1] || (_cache[1] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(handleScroll) && _unref(handleScroll)(...args);
                    })
                }, [
                    _renderSlot(_ctx.$slots, "default")
                ], 34)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetSlipSettingsView'
                ]
            ]);
        };
    }
});
