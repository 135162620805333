import { computed, toRef } from 'vue';
import { useSiteConfigStore } from 'web/src/modules/core/store';
export function useSlipShareDialogRoute() {
    const siteConfigStore = useSiteConfigStore();
    const slipBlock = toRef(siteConfigStore, 'slipBlock');
    const isShareRouteEnabled = computed(()=>!!(slipBlock.value?.sharedBetSlipBookingCodeEnabled || // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        slipBlock.value?.sharedBetSlipLinkEnabled));
    return {
        isShareRouteEnabled
    };
}
