import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withCtx as _withCtx, withDirectives as _withDirectives } from "vue";
import BetSlipSettingsView from '../components/BetSlipSettingsView';
import SlipCommonSettings from '../components/SlipCommonSettings';
import BetSlipEditFastBet from '../components/BetSlipEditFastBet';
import { SettingsTabs } from '../enums';
import { useBetSlipSettings } from '../composable';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipSettings',
    emits: [
        "scroll"
    ],
    setup (__props) {
        const { activeSettingsTabId, priceChangePolicy, showSlipOnFirstAdded, changePriceChangePolicy, toggleShowSlipOnFirstAdded, minFastBet, maxFastBet, maxEnabled, changesIsSaved, fastBets, useStandardBet, minStandardBet, maxStandardBet, standardBetAmount, standardBetAmountIsSaved, standardBetInputErrorKind, initFastBetInputValues, onBastBetSettingsLeave, handleFastBetValuesBlur, handleFastBetValuesChange, onStandardBetFocus, handleFastBetMaxSwitch, toggleUseStandardBet, onStandardBetInput, onStandardBetBlur, selectSettingsTab, onSettingsBackClick } = useBetSlipSettings();
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(BetSlipSettingsView), {
                "active-settings-tab-id": _unref(activeSettingsTabId),
                onSelectSettingsTab: _unref(selectSettingsTab),
                onSettingsBackClick: _unref(onSettingsBackClick),
                onScroll: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('scroll'))
            }, {
                default: _withCtx(()=>[
                        _unref(activeSettingsTabId) === _unref(SettingsTabs).COMMON_SETTINGS ? (_openBlock(), _createBlock(_unref(SlipCommonSettings), {
                            key: 0,
                            "price-change-policy": _unref(priceChangePolicy),
                            "show-slip-on-first-added": _unref(showSlipOnFirstAdded),
                            onChangePolicySelect: _unref(changePriceChangePolicy),
                            onToggleShowSlipOnFirstAdded: _unref(toggleShowSlipOnFirstAdded)
                        }, null, 8, [
                            "price-change-policy",
                            "show-slip-on-first-added",
                            "onChangePolicySelect",
                            "onToggleShowSlipOnFirstAdded"
                        ])) : (_openBlock(), _createBlock(_unref(BetSlipEditFastBet), {
                            key: 1,
                            "max-enabled": _unref(maxEnabled),
                            "changes-is-saved": _unref(changesIsSaved),
                            "fast-bets": _unref(fastBets),
                            "min-fast-bet": _unref(minFastBet),
                            "max-fast-bet": _unref(maxFastBet) ?? void 0,
                            "use-standard-bet": _unref(useStandardBet),
                            "max-standard-bet": _unref(maxStandardBet),
                            "min-standard-bet": _unref(minStandardBet),
                            "standard-bet-amount": _unref(standardBetAmount) ?? void 0,
                            "standard-bet-amount-is-saved": _unref(standardBetAmountIsSaved),
                            "standard-bet-input-error-kind": _unref(standardBetInputErrorKind),
                            onVnodeMounted: _unref(initFastBetInputValues),
                            onVnodeBeforeUnmount: _unref(onBastBetSettingsLeave),
                            onFastBetsSubmit: _unref(handleFastBetValuesBlur),
                            onFastBetsBlur: _unref(handleFastBetValuesBlur),
                            onFastBetsInput: _unref(handleFastBetValuesChange),
                            onSwitchMaxEnabled: _unref(handleFastBetMaxSwitch),
                            onToggleUseStandardBet: _unref(toggleUseStandardBet),
                            onStandardSumFocus: _unref(onStandardBetFocus),
                            onStandardSumInput: _unref(onStandardBetInput),
                            onStandardSumBlur: _unref(onStandardBetBlur)
                        }, null, 8, [
                            "max-enabled",
                            "changes-is-saved",
                            "fast-bets",
                            "min-fast-bet",
                            "max-fast-bet",
                            "use-standard-bet",
                            "max-standard-bet",
                            "min-standard-bet",
                            "standard-bet-amount",
                            "standard-bet-amount-is-saved",
                            "standard-bet-input-error-kind",
                            "onVnodeMounted",
                            "onVnodeBeforeUnmount",
                            "onFastBetsSubmit",
                            "onFastBetsBlur",
                            "onFastBetsInput",
                            "onSwitchMaxEnabled",
                            "onToggleUseStandardBet",
                            "onStandardSumFocus",
                            "onStandardSumInput",
                            "onStandardSumBlur"
                        ]))
                    ]),
                _: 1
            }, 8, [
                "active-settings-tab-id",
                "onSelectSettingsTab",
                "onSettingsBackClick"
            ])), [
                [
                    _directive_auto_id,
                    'BetSlipSettings'
                ]
            ]);
        };
    }
});
