import { computed, ref, toRef, watch } from 'vue';
import { defineStore } from 'pinia';
import { PriceChangePolicy, SlipStatus, SlipTypeId } from '@leon-hub/api-sdk';
import { sleep, Timer } from '@leon-hub/utils';
import { GqlApiServiceSuspendedError } from '@leon-hub/api';
import { logger } from '@leon-hub/logging';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useBalance } from 'web/src/modules/customer/store/composables';
import formatOdd from 'web/src/utils/formatOdd';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useIsDevIP } from 'web/src/modules/core/composables/root';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import usePriceChangePolicy from 'web/src/modules/customer/composables/usePriceChangePolicy';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useErrorsConverter } from 'web/src/modules/errors/composables';
import isMatchedArrays from 'web/src/utils/array/isMatchedArrays';
import isSamePlainObject from 'web/src/utils/object/isSamePlainObject';
import { useOddsSettings } from 'web/src/modules/sportline/composables/settings';
import { useMaxPrize } from 'web/src/modules/slip/submodules/slip-root/composables/useMaxPrize';
import { logBetsLimitError } from 'web/src/modules/slip/submodules/slip-info/utils/logBetsLimitError';
import { useSlipViewSettingsStore } from '../../slip-view-settings/store';
import { useSlipSelectedEntriesStore } from '../../slipSelectedEntries/store';
import isSlipTypeArray from '../../../guards/isSlipTypeArray';
import isSlipEntryArray from '../../../guards/isSlipEntryArray';
import { SlipTypeSingle } from '../../../constants';
import { AddSlipEntriesError, BetSlipWarningTypes, LeaveSlipResultChoice } from '../../../enums';
import { useStakeInputStore } from '../../stake-input/store';
import { useFreebetStore } from '../../freebet/store';
import { useMultiSinglesStore } from '../../multi-singles/store';
import { useDefaultBetValue } from '../../default-bet-value/composables';
import { checkSlipSyncErrors, convertSlipListItemToBetsInfoObject, clearStatusOnBatchedSlipInfo, getCombiClearedFromBetById, getMaxAvailableFastBetValue, getMetaInfoWithBalanceCheck, getMultiSinglesCommonMaxStake, getMultiSinglesCommonMinStake, getMultiSinglesTotalPrice, getSlipEntriesWithToggledBanker, getSlipInfoWithoutTrace, handleClosedMarketsChange, requestBatchedSlipInfo, updateBetsInfoOddById, updateSlipInfoBySlipEntries, logAcceptChanges, getCombinedSlipEntryInfo, isPriceChangedMatters, sortSlipEntries, logAddingToSlip, logRemoveFromSlip, logSaveSlipItemsAfterBet, mapCombinedSlipEntryToExtendedSlipListBettingItemProps, getSafeSlipType, getClosedAndUnlockedEventsData, getAllBatchedEntries, isSlipEntriesMatchSelected, getEventsWithChangedMarkets, mapSlipEntryToEventWithChangedStatus, getSlipEntriesWithChangedStatus, isEntriesHasLimitStatus, getUniqueSlipEntriesArray, getOnlyAvailableSlipEntries, getNotSyncedEntries, getSlipEntryFromSlipListItem, getSlipInfoRequestEntries, getItemsForUpdatedPrice, getMatchedSlipType, getFreeBetsFromBatchedSlipInfo, getCombiSlipInfo, getSinglesAvailableForBetting, getSinglesClearedFromBetById, getSlipDataForUnsettledBets, shouldForceSingleMode, updateSlipInfoFromSportlineData, replaceOriginalOddsValues } from '../utils';
import { getMultiSinglesMetaInfoForCache, getMultiSinglesMetaInfo, clearMultiSingleMarketStatusById } from '../../multi-singles/utils';
import { useSlipLocalStorage } from '../composables';
import { getSlipEntryId, convertSlipEntryIdToObject, findSlipEntryById, isUnavailableEntryStatus, isLiveEventSlipEntry } from '../../../utils';
const useSlipInfoStore = defineStore('slip-info-store', ()=>{
    const apiClient = useGraphqlClient();
    const formatMoney = useFormatMoney();
    const { $translate } = useI18n();
    const bus = useEventsBus();
    const { balance } = useBalance();
    const { isDevIP } = useIsDevIP();
    const { isLoggedIn } = useIsLoggedIn();
    const siteConfigStore = useSiteConfigStore();
    const slipViewSettingsStore = useSlipViewSettingsStore();
    const { convertToBaseError } = useErrorsConverter();
    const { clearLocalStorageState, saveSnapshotToLocalStorage, getSnapshotFromLocalStorage } = useSlipLocalStorage();
    const sportLineBlock = toRef(siteConfigStore, 'sportLineBlock');
    const slipSelectedEntriesStore = useSlipSelectedEntriesStore();
    const { setSelectedEntriesIds, removeFromSelectedEntriesIds, addToSelectedEntriesIds } = slipSelectedEntriesStore;
    const { priceChangePolicy } = usePriceChangePolicy();
    const { isOddsFormatSwitcherEnabled, currentOddsType } = useOddsSettings();
    const multiSinglesStore = useMultiSinglesStore();
    const { setLastModifiedMultiSingleId, setMultiSinglesMetaInfo, clearFocusOnUnavailableEvents } = multiSinglesStore;
    const isMultiSinglesEnabled = toRef(multiSinglesStore, 'isMultiSinglesEnabled');
    const sameStakeForSingles = toRef(multiSinglesStore, 'sameStakeForSingles');
    const lastModifiedMultiSingleId = toRef(multiSinglesStore, 'lastModifiedId');
    const multiSinglesMetaInfo = toRef(multiSinglesStore, 'multiSinglesMetaInfo');
    const stakeInputStore = useStakeInputStore();
    const { safeSetStakeValue } = stakeInputStore;
    const safeStakeValue = toRef(stakeInputStore, 'safeStakeValue');
    const stakeInputValue = toRef(stakeInputStore, 'stakeInputValue');
    const { defaultBetValue } = useDefaultBetValue();
    const customerDataStore = useCustomerDataStore();
    const showSlipOnFirstAdded = toRef(customerDataStore, 'showSlipOnFirstAdded');
    const selectedEntriesIds = toRef(slipSelectedEntriesStore, 'selectedEntriesIds');
    const freebetStore = useFreebetStore();
    const { updateFreeBets, checkIfNeedToCancelFreebet, clearFreeBet } = freebetStore;
    const currentFreeBet = toRef(freebetStore, 'currentFreeBet');
    const isLeaveEnterAnimationPlaying = toRef(slipViewSettingsStore, 'isLeaveEnterAnimationPlaying');
    const { setSlipVisibility, handleSlipVisibilityChange, setDefaultSlipView } = slipViewSettingsStore;
    let syncIntervalId = null;
    /** state / mutations */ const batchedSlipInfo = ref(null);
    const setBatchedSlipInfo = (slipInfo)=>{
        batchedSlipInfo.value = slipInfo;
    };
    const setSlipEntries = (slipEntries)=>{
        if (!batchedSlipInfo.value) return;
        const { combiAvailable } = batchedSlipInfo.value;
        setBatchedSlipInfo({
            slipEntries,
            combiAvailable
        });
    };
    const setSlipType = (slipType)=>{
        if (!batchedSlipInfo.value) return;
        const slipEntries = batchedSlipInfo.value.slipEntries.map((entry)=>({
                ...entry,
                slipType
            }));
        setSlipEntries(slipEntries);
    };
    const betsInfo = ref({});
    const setBetsInfo = (snapshot)=>{
        betsInfo.value = snapshot;
    };
    const slipEntryCache = ref([]);
    const setSlipEntryCache = (items)=>{
        slipEntryCache.value = items;
    };
    /** time of the last update - events was added/removed by user */ const lastChangeTime = ref(0);
    const setLastChangeTime = (timestamp)=>{
        lastChangeTime.value = timestamp;
    };
    /** to disable main CTA button until data between slip info and selectedEntriesIds have difference */ const incorrectSyncState = ref(false);
    const setIncorrectSyncStatus = (isIncorrect)=>{
        incorrectSyncState.value = isIncorrect;
    };
    /** state when waiting data after accept changes action */ const isPendingChanges = ref(false);
    const setPendingChanges = (isPending)=>{
        isPendingChanges.value = isPending;
    };
    const clearBetListScheduled = ref(false);
    const setClearBetListScheduled = (isScheduled)=>{
        clearBetListScheduled.value = isScheduled;
    };
    const isSlipUpdatesLocked = ref(false);
    const setUpdateLockStatus = (isLocked)=>{
        isSlipUpdatesLocked.value = isLocked;
    };
    const betMode = ref(SlipTypeId.SINGLE);
    const setBetMode = (mode)=>{
        betMode.value = mode;
    };
    const canSwitchToCombi = computed(()=>{
        if (betMode.value === SlipTypeId.SINGLE) return batchedSlipInfo.value?.combiAvailable ?? true;
        return true;
    });
    const betModeWasManuallyChanged = ref(false);
    const setBetModeWasManuallyChanged = (wasChanged)=>{
        betModeWasManuallyChanged.value = wasChanged;
    };
    const selectedSystemValue = ref(null);
    const setSystemValue = (value)=>{
        selectedSystemValue.value = value;
    };
    const entriesWithChangedStatus = ref([]);
    const setEntriesWithChangedStatus = (entries)=>{
        entriesWithChangedStatus.value = entries;
    };
    /** Dictionary to clear slipEntries with timeout */ const closedMarkets = ref({});
    const setClosedMarkets = (value)=>{
        closedMarkets.value = value;
    };
    /** identifier to avoid duplicate bets */ const lastBetTimeStamp = ref(null);
    const setLastBetTimeStamp = (stamp)=>{
        lastBetTimeStamp.value = stamp;
    };
    /** prevents override new added events by copy from localstorage and shows loader */ const restoringSlipFromLS = ref(false);
    const setRestoreFromLS = (isRestoring)=>{
        restoringSlipFromLS.value = isRestoring;
    };
    const removeFromClosedMarkets = (id)=>{
        const timeoutId = closedMarkets.value[id];
        if (timeoutId) {
            Timer.clearTimeout(timeoutId);
            const copy = {
                ...closedMarkets.value
            };
            delete copy[id];
            setClosedMarkets(copy);
        }
    };
    const customWarningMessage = ref('');
    const setCustomWarningMessage = function() {
        let message = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '';
        customWarningMessage.value = message;
    };
    /** former getters */ const slipEventsUpdateInterval = computed(()=>sportLineBlock.value?.slipEventsUpdateInterval || 3000);
    const slipDeleteClosedEventTimeout = computed(()=>sportLineBlock.value?.deleteClosedEventTimeout || 1200000);
    const isZeroMarginEnabled = computed(()=>sportLineBlock.value?.zeroMarginEnabled ?? false);
    const betsInfoCount = computed(()=>Object.keys(betsInfo.value).length);
    const combiOrFirstSlip = computed(()=>{
        if (!batchedSlipInfo.value) return null;
        return getCombiSlipInfo(batchedSlipInfo.value);
    });
    const slipType = computed(()=>combiOrFirstSlip.value?.slipType || null);
    const matchedBetMode = computed(()=>{
        if (!slipType.value || slipType.value === SlipTypeSingle) return SlipTypeId.SINGLE;
        if (slipType.value.startsWith('e')) return SlipTypeId.EXPRESS;
        return SlipTypeId.SYSTEM;
    });
    const slipTypes = computed(()=>{
        const slipTypesArray = combiOrFirstSlip.value?.slipTypes;
        if (!slipTypesArray) return [];
        isSlipTypeArray(slipTypesArray);
        return slipTypesArray;
    });
    const isMultiSinglesMode = computed(()=>betMode.value === SlipTypeId.SINGLE && selectedEntriesIds.value.length > 1);
    const slipEntries = computed(()=>{
        if (!batchedSlipInfo.value) return [];
        return getAllBatchedEntries(batchedSlipInfo.value.slipEntries);
    });
    const allEntries = computed(()=>getUniqueSlipEntriesArray([
            ...slipEntries.value,
            ...slipEntryCache.value
        ]));
    const bankersAvailable = computed(()=>betMode.value === SlipTypeId.SYSTEM && slipEntries.value.length > 3);
    const bankerCount = computed(()=>slipEntries.value.filter((entry)=>entry.banker).length);
    const maxBankers = computed(()=>combiOrFirstSlip.value?.maxBankers || null);
    const systemOptions = computed(()=>{
        const systemOnlyTypes = slipTypes.value.filter((item)=>item && item.typeId === SlipTypeId.SYSTEM);
        return systemOnlyTypes.map((param)=>{
            let { code, shortName, name } = param;
            return {
                value: code,
                label: shortName || name
            };
        });
    });
    const maxSlipSize = computed(()=>combiOrFirstSlip.value?.maxSlipSize || null);
    const maxSystemSize = computed(()=>combiOrFirstSlip.value?.maxSystemSize || null);
    /** show LIVE badge only if its mixed live and prematch events */ const shouldShowLiveStatus = computed(()=>{
        let liveCount = 0;
        let prematchCount = 0;
        return slipEntries.value.some((entry)=>{
            if (isLiveEventSlipEntry(entry)) liveCount += 1;
            else prematchCount += 1;
            return liveCount > 0 && prematchCount > 0;
        });
    });
    const totalOdds = computed(()=>combiOrFirstSlip.value?.totalOdds || null);
    const formattedTotalOdds = computed(()=>{
        if (!totalOdds.value || incorrectSyncState.value) return '-';
        if (isOddsFormatSwitcherEnabled.value) return combiOrFirstSlip.value?.totalOddsStr ?? '';
        return formatOdd(totalOdds.value);
    });
    const hasLimitError = computed(()=>isEntriesHasLimitStatus(slipEntries.value));
    const marketsChanged = computed(()=>{
        if (isMultiSinglesMode.value) return false;
        return combiOrFirstSlip.value?.status === SlipStatus.MARKETS_CHANGED;
    });
    const pricesChanged = computed(()=>{
        if (isMultiSinglesMode.value || !isLoggedIn.value || priceChangePolicy.value === PriceChangePolicy.ALLOW_CHANGES) return false;
        return !!combiOrFirstSlip.value?.entries.some((entry)=>{
            const id = getSlipEntryId(entry);
            const additionalInfo = betsInfo.value[id];
            return isPriceChangedMatters({
                priceChangePolicy: priceChangePolicy.value,
                originalOdds: additionalInfo?.originalOdds,
                currentOdds: entry.odds
            });
        });
    });
    const mustAcceptChanges = computed(()=>marketsChanged.value || pricesChanged.value);
    const warningMessage = computed(()=>{
        if (isMultiSinglesMode.value) return '';
        if (customWarningMessage.value) return customWarningMessage.value;
        return mustAcceptChanges.value ? combiOrFirstSlip.value?.message ?? '' : '';
    });
    const isSyncInProgress = computed(()=>!!(incorrectSyncState.value || slipEntryCache.value.length));
    const slipTraceLines = computed(()=>{
        if (!isDevIP.value || !batchedSlipInfo.value) return null;
        return batchedSlipInfo.value.slipEntries.map((slipEntry)=>slipEntry?.trace?.lines ?? []);
    });
    const combinedEntriesInfo = computed(()=>getCombinedSlipEntryInfo({
            input: slipEntries.value,
            priceChangePolicy: priceChangePolicy.value,
            betsInfo: betsInfo.value,
            multiSinglesMetaInfo: multiSinglesMetaInfo.value
        }));
    const combinedCachedEntriesInfo = computed(()=>getCombinedSlipEntryInfo({
            input: slipEntryCache.value,
            priceChangePolicy: priceChangePolicy.value,
            betsInfo: betsInfo.value,
            multiSinglesMetaInfo: multiSinglesMetaInfo.value
        }));
    const multiSinglesAvailableForBet = computed(()=>{
        if (!combinedEntriesInfo.value.length) return [];
        return getSinglesAvailableForBetting({
            input: combinedEntriesInfo.value,
            priceChangePolicy: priceChangePolicy.value,
            sameStake: sameStakeForSingles.value
        });
    });
    const multiSinglesTotalPrice = computed(()=>{
        if (!isMultiSinglesMode.value) return 0;
        return getMultiSinglesTotalPrice({
            availableItems: multiSinglesAvailableForBet.value,
            sameStakeForSingles: sameStakeForSingles.value,
            commonStakeValue: safeStakeValue.value
        });
    });
    const multiSinglesCommonMinStake = computed(()=>{
        if (!isMultiSinglesMode.value || !sameStakeForSingles.value) return 0;
        return getMultiSinglesCommonMinStake(multiSinglesAvailableForBet.value);
    });
    const multiSinglesCommonMaxStake = computed(()=>{
        if (!isMultiSinglesMode.value || !sameStakeForSingles.value) return 0;
        return getMultiSinglesCommonMaxStake(multiSinglesAvailableForBet.value);
    });
    const maxStake = computed(()=>{
        if (isMultiSinglesMode.value) return multiSinglesCommonMaxStake.value || null;
        return combiOrFirstSlip.value?.maxStake || null;
    });
    const minStake = computed(()=>{
        if (isMultiSinglesMode.value) return multiSinglesCommonMinStake.value || null;
        return combiOrFirstSlip.value?.minStake || null;
    });
    const bankersLimitReached = computed(()=>{
        if (mustAcceptChanges.value) return true;
        return !!(maxBankers.value && bankerCount.value >= maxBankers.value);
    });
    const slipEvents = computed(()=>{
        const stakeOverBalanceText = $translate('WEB2_STAKE_ABOVE_BALANCE').value;
        const restOfBalance = balance.value - multiSinglesTotalPrice.value;
        return combinedEntriesInfo.value.map((combinedEntry)=>{
            const isLive = shouldShowLiveStatus.value && isLiveEventSlipEntry(combinedEntry);
            let errorMessage = '';
            if (isMultiSinglesMode.value && !sameStakeForSingles.value) errorMessage = combinedEntry.metaInfo?.stakeOverBalance ? stakeOverBalanceText : combinedEntry.metaInfo?.error || '';
            const showAcceptButton = isMultiSinglesMode.value && !combinedEntry.matchedPriceChangePolicy;
            return mapCombinedSlipEntryToExtendedSlipListBettingItemProps(combinedEntry, {
                disableBankers: bankersLimitReached.value,
                isLive,
                isZeroMarginEnabled: isZeroMarginEnabled.value,
                errorMessage,
                restOfBalance,
                showAcceptButton,
                isOddsFormatSwitcherEnabled: isOddsFormatSwitcherEnabled.value
            });
        });
    });
    const cacheEvents = computed(()=>combinedCachedEntriesInfo.value.map((combinedEntry)=>{
            const isLive = shouldShowLiveStatus.value && isLiveEventSlipEntry(combinedEntry);
            return mapCombinedSlipEntryToExtendedSlipListBettingItemProps(combinedEntry, {
                disableBankers: true,
                isLive,
                isZeroMarginEnabled: isZeroMarginEnabled.value,
                isOddsFormatSwitcherEnabled: isOddsFormatSwitcherEnabled.value
            });
        }));
    const betEvents = computed(()=>[
            ...slipEvents.value,
            ...cacheEvents.value
        ]);
    const slipEventsCount = computed(()=>betEvents.value.length);
    const isBetModeMatchesSlipTypes = computed(()=>{
        switch(betMode.value){
            case SlipTypeId.SINGLE:
                return isMultiSinglesEnabled.value || 1 === slipEventsCount.value;
            case SlipTypeId.EXPRESS:
                return slipEventsCount.value >= 2;
            case SlipTypeId.SYSTEM:
                return slipEventsCount.value >= 3;
            default:
                return false;
        }
    });
    const eventsWithChangedMarkets = computed(()=>getEventsWithChangedMarkets(slipEntries.value));
    const selectedStakeValue = computed(()=>{
        if (currentFreeBet.value) return currentFreeBet.value.amount;
        return safeStakeValue.value;
    });
    const slipSizeIsNotExceeded = computed(()=>!maxSlipSize.value || slipEntries.value.length <= maxSlipSize.value);
    const singleModeAvailable = computed(()=>{
        if (isMultiSinglesEnabled.value) return slipSizeIsNotExceeded.value;
        return selectedEntriesIds.value.length <= 1;
    });
    const expressModeAvailable = computed(()=>canSwitchToCombi.value && slipSizeIsNotExceeded.value);
    const systemModeAvailable = computed(()=>{
        if (!canSwitchToCombi.value) return false;
        return !maxSystemSize.value || slipEntries.value.length <= maxSystemSize.value;
    });
    const multiSinglesUiCount = computed(()=>allEntries.value.reduce((accumulator, param)=>{
            let { marketStatus } = param;
            return isUnavailableEntryStatus(marketStatus) ? accumulator : accumulator + 1;
        }, 0));
    const displayedSlipEventsCount = computed(()=>isMultiSinglesMode.value ? multiSinglesUiCount.value : slipEventsCount.value);
    const maxAvailableFastBetValue = computed(()=>getMaxAvailableFastBetValue({
            isLoggedIn: isLoggedIn.value,
            maxStake: maxStake.value,
            balance: balance.value,
            isMultiSinglesMode: isMultiSinglesMode.value,
            sameStakeForSingles: sameStakeForSingles.value,
            availableMultiSinglesCount: multiSinglesAvailableForBet.value.length || 1
        }));
    const { maxPrize } = useMaxPrize({
        isMultiSinglesMode,
        totalOdds,
        selectedStakeValue,
        currentFreeBet,
        multiSinglesAvailableForBet,
        sameStakeForSingles
    });
    const limitErrorInfoPayload = computed(()=>({
            bets: combinedEntriesInfo.value.map((item)=>({
                    event: item.event,
                    market: item.market,
                    betChoice: item.runnerName ?? item.originalRunnerName
                })),
            stakeValue: selectedStakeValue.value,
            totalOdds: totalOdds.value,
            maxPrize: maxPrize.value
        }));
    const taxPercent = computed(()=>combiOrFirstSlip.value?.taxPercent ?? null);
    /** former actions */ const getBatchedSlipData = (payload)=>requestBatchedSlipInfo(payload, apiClient);
    const addToBetsInfo = (slipListItem)=>{
        setBetsInfo({
            ...betsInfo.value,
            ...convertSlipListItemToBetsInfoObject(slipListItem)
        });
    };
    const addToSlipEntryCache = (item)=>{
        const updatedCache = [
            ...slipEntryCache.value.filter((entry)=>getSlipEntryId(item) !== getSlipEntryId(entry)),
            item
        ];
        setSlipEntryCache(updatedCache);
        if (isMultiSinglesMode.value) setMultiSinglesMetaInfo(getMultiSinglesMetaInfoForCache({
            slipEntryCache: updatedCache,
            currentMetaInfo: multiSinglesMetaInfo.value,
            defaultBetAmount: defaultBetValue.value
        }));
    };
    const removeFromSlipEntryCache = (id)=>{
        setSlipEntryCache(slipEntryCache.value.filter((entry)=>getSlipEntryId(entry) !== id));
    };
    const updateLocalSlipInfoEntries = (payload)=>{
        const updatedSlipEntries = updateSlipInfoBySlipEntries({
            ...payload,
            currentSlipInfo: batchedSlipInfo.value,
            selectedEntriesIds: selectedEntriesIds.value
        });
        if (updatedSlipEntries) setSlipEntries(updatedSlipEntries);
    };
    const matchSlipType = ()=>{
        if (betMode.value !== matchedBetMode.value) setBetMode(matchedBetMode.value);
    };
    const updateSingleOriginalOdd = (id)=>{
        const updatedBetsInfo = updateBetsInfoOddById({
            id,
            allEntries: allEntries.value,
            currentBetsInfo: betsInfo.value
        });
        if (updatedBetsInfo && batchedSlipInfo.value) {
            setBetsInfo(updatedBetsInfo);
            const updatedSlipEntries = clearMultiSingleMarketStatusById({
                currentSlipInfo: batchedSlipInfo.value,
                id
            });
            setSlipEntries(updatedSlipEntries);
        }
    };
    const updateMultiSinglesMetaInfo = ()=>{
        const updatedMetaInfo = getMultiSinglesMetaInfo({
            batchedSlipInfo: batchedSlipInfo.value,
            defaultBetAmount: defaultBetValue.value,
            previousValue: multiSinglesMetaInfo.value,
            betsInfo: betsInfo.value,
            priceChangePolicy: priceChangePolicy.value,
            balance: isLoggedIn.value ? balance.value : null,
            formatMoney
        });
        setMultiSinglesMetaInfo(updatedMetaInfo);
    };
    const checkMultiSinglesStakeOverBalance = ()=>{
        const checkedMetaInfo = getMetaInfoWithBalanceCheck({
            balance: balance.value,
            currentMetaInfo: multiSinglesMetaInfo.value,
            multiSinglesAvailableForBet: multiSinglesAvailableForBet.value,
            lastModifiedId: lastModifiedMultiSingleId.value
        });
        setMultiSinglesMetaInfo(checkedMetaInfo);
        setLastModifiedMultiSingleId(null);
    };
    const safeSetSystemValue = (value)=>{
        if (value && /^\d/.test(value)) setSystemValue(value);
    };
    const claimToChangeSportEventsStatuses = (entries)=>{
        const mappedEntries = entries.map((entry)=>mapSlipEntryToEventWithChangedStatus(entry));
        bus.emit(BusEvent.SPORT_EVENTS_STATUS_CHANGED, {
            events: mappedEntries,
            source: 'slip'
        });
    };
    // need to be defined this way, used both on Ui and for outdated event removal
    let removeBetClick;
    const onClosedMarketsChange = (payload)=>{
        /** will add timers to delete closed/missed events or remove timers for events which is alive again */ setClosedMarkets(handleClosedMarketsChange({
            ...payload,
            currentlyClosedMarkets: closedMarkets.value,
            timeout: slipDeleteClosedEventTimeout.value
        }, removeBetClick));
    };
    const checkEventsWithChangedStatus = ()=>{
        const changedEvents = getSlipEntriesWithChangedStatus(slipEntries.value);
        if (isMatchedArrays(changedEvents, entriesWithChangedStatus.value, sortSlipEntries)) return;
        clearFocusOnUnavailableEvents(changedEvents);
        const { unlockedEventsData, unlockedEventsIds, entriesWithClosedMarket } = getClosedAndUnlockedEventsData({
            slipEntries: slipEntries.value,
            changedEvents,
            entriesWithChangedStatus: entriesWithChangedStatus.value
        });
        claimToChangeSportEventsStatuses([
            ...unlockedEventsData,
            ...changedEvents
        ]);
        // save changed
        setEntriesWithChangedStatus(changedEvents);
        // schedule delete for closed markets
        if (entriesWithClosedMarket.length || unlockedEventsIds.size) onClosedMarketsChange({
            changedEntries: entriesWithClosedMarket,
            unlockedEventsIds
        });
    };
    const checkEventsWithChangedMarkets = ()=>{
        if (eventsWithChangedMarkets.value.length) bus.emit(BusEvent.SPORT_EVENTS_RUNNERS_CHANGED, {
            events: [
                ...eventsWithChangedMarkets.value
            ],
            source: 'slip'
        });
    };
    const saveToLocalStorage = ()=>{
        const snapshot = {
            batchedSlipInfo: getSlipInfoWithoutTrace(batchedSlipInfo.value),
            betsInfo: betsInfo.value,
            betMode: betMode.value,
            stakeInputValue: stakeInputValue.value,
            selectedEntriesIds: selectedEntriesIds.value,
            lastBetTimeStamp: lastBetTimeStamp.value,
            multiSinglesMetaInfo: multiSinglesMetaInfo.value
        };
        saveSnapshotToLocalStorage(snapshot);
    };
    const applyBatchedSlipInfoUpdate = (param)=>{
        let { updatedSlipInfo, isBackgroundUpdate } = param;
        if (!updatedSlipInfo?.slipEntries.length || isSamePlainObject(batchedSlipInfo.value, updatedSlipInfo)) return;
        const allSlipEntries = getAllBatchedEntries(updatedSlipInfo.slipEntries);
        const isValid = isSlipEntriesMatchSelected(allSlipEntries, selectedEntriesIds.value);
        /** mask as sync error */ setIncorrectSyncStatus(!isValid);
        if (!isValid) return;
        /** is ok - update store */ setBatchedSlipInfo(updatedSlipInfo);
        updateMultiSinglesMetaInfo();
        updateFreeBets(getFreeBetsFromBatchedSlipInfo(updatedSlipInfo));
        /** remove added events from slipEntryCache */ if (slipEntryCache.value.length) {
            const missedItems = getNotSyncedEntries(slipEntryCache.value, allSlipEntries);
            setSlipEntryCache(missedItems);
        }
        /** autoset/autofix stake value */ if (!isBackgroundUpdate) {
            const nextStakeValue = selectedStakeValue.value || defaultBetValue.value || 0;
            safeSetStakeValue({
                value: nextStakeValue,
                minStake: minStake.value,
                maxStake: maxStake.value
            });
        }
        /** save selected system value */ safeSetSystemValue(updatedSlipInfo.slipEntries[0].slipType);
        saveToLocalStorage();
        /** update sportline if necessary */ if (entriesWithChangedStatus.value.length || updatedSlipInfo.slipEntries.some((entry)=>entry && entry?.status === SlipStatus.MARKETS_CHANGED)) checkEventsWithChangedStatus();
        checkEventsWithChangedMarkets();
    };
    const backgroundUpdateSlipInfo = async ()=>{
        if (!slipEntries.value.length || isSlipUpdatesLocked.value) return;
        const savedLastChangeTime = lastChangeTime.value;
        const response = await getBatchedSlipData({
            slipEntries: getSlipInfoRequestEntries(slipEntries.value, slipType.value),
            slipType: slipType.value,
            oddsType: currentOddsType.value,
            silent: true
        });
        if (response) {
            if (!betsInfoCount.value || isSlipUpdatesLocked.value || savedLastChangeTime < lastChangeTime.value) /**
         * !betsInfoCount === list was cleared after request was sent
         * lastChangeTime < this.state.lastChangeTime - data is outdated and can break local state
         * */ return;
            applyBatchedSlipInfoUpdate({
                updatedSlipInfo: response,
                isBackgroundUpdate: true
            });
        }
    };
    const startSyncSlip = ()=>{
        if (syncIntervalId) return;
        syncIntervalId = Timer.setInterval(()=>{
            backgroundUpdateSlipInfo();
        }, slipEventsUpdateInterval.value);
    };
    const clearSyncInterval = ()=>{
        if (syncIntervalId) Timer.clearInterval(syncIntervalId);
        syncIntervalId = null;
    };
    const enableUpdates = ()=>{
        setUpdateLockStatus(false);
        startSyncSlip();
    };
    const disableUpdates = ()=>{
        setUpdateLockStatus(true);
        clearSyncInterval();
    };
    const emitSlipSelectionChange = ()=>{
        const selected = selectedEntriesIds.value.map((id)=>convertSlipEntryIdToObject(id));
        bus.emit(BusEvent.SLIP_SELECTION_CHANGE, {
            selected
        });
    };
    const clearAddedItemsQueue = ()=>{
        const cachedEventsIds = slipEntryCache.value.map((entry)=>getSlipEntryId(entry));
        setSelectedEntriesIds(selectedEntriesIds.value.filter((item)=>!cachedEventsIds.includes(item)));
        emitSlipSelectionChange();
        setSlipEntryCache([]);
    };
    const handleSlipClose = ()=>{
        bus.emit(BusEvent.CLAIM_TO_SLIP_CLOSE, {});
    };
    const cancelSlipAdding = ()=>{
        clearAddedItemsQueue();
        if (slipEntries.value.length) enableUpdates();
        else handleSlipClose();
    };
    const checkFreeBet = ()=>{
        checkIfNeedToCancelFreebet(betMode.value, {
            value: defaultBetValue.value || 0,
            minStake: minStake.value,
            maxStake: maxStake.value
        });
    };
    const onAddingToSlipError = (param)=>{
        let { message, entriesError } = param;
        let busEventPayload = {
            type: BetSlipWarningTypes.GENERIC_WARNING,
            message
        };
        if (entriesError === AddSlipEntriesError.LIMIT) {
            busEventPayload = {
                type: BetSlipWarningTypes.MARKET_STATUS_LIMIT
            };
            logBetsLimitError(limitErrorInfoPayload.value);
        }
        if (entriesError === AddSlipEntriesError.DUPLICATE) busEventPayload = {
            type: BetSlipWarningTypes.MARKET_STATUS_DUPLICATE,
            message
        };
        cancelSlipAdding();
        bus.emit(BusEvent.SHOW_SLIP_MODAL_WARNING, {
            ...busEventPayload
        });
    };
    const catchSyncSlipError = (error)=>{
        if (error instanceof GqlApiServiceSuspendedError) {
            bus.emit(BusEvent.SHOW_SLIP_MODAL_WARNING, {
                type: BetSlipWarningTypes.GENERIC_WARNING,
                message: error.message
            });
            cancelSlipAdding();
        } else {
            logger.error(convertToBaseError(error));
            bus.emit(BusEvent.SHOW_SLIP_MODAL_WARNING, {
                type: BetSlipWarningTypes.CONNECTION_ERROR
            });
        }
    };
    const syncAddedSlipItems = async function() {
        let { isAdding, newSlipType, refreshOriginalOdds } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
        if (!allEntries.value.length) return;
        const computedSlipType = getSafeSlipType(newSlipType || slipType.value, allEntries.value.length);
        const slipEntriesForRequest = getSlipInfoRequestEntries(allEntries.value, computedSlipType);
        const savedLastChangeTime = lastChangeTime.value;
        try {
            const response = await getBatchedSlipData({
                slipEntries: slipEntriesForRequest,
                slipType: computedSlipType,
                oddsType: currentOddsType.value
            });
            if (!response) throw new Error('Unable to get slip data');
            if (savedLastChangeTime < lastChangeTime.value) /** do not apply update if its answer not for the latest query */ return;
            if (computedSlipType !== SlipTypeSingle && shouldForceSingleMode(response)) {
                /** recursive call here */ syncAddedSlipItems({
                    newSlipType: SlipTypeSingle
                });
                return;
            }
            /** check for errors */ if (isAdding && 1 === response.slipEntries.length) {
                // single or combi slip
                const addingErrors = checkSlipSyncErrors(response.slipEntries[0]);
                if (addingErrors) {
                    onAddingToSlipError(addingErrors);
                    return;
                }
            }
            if (isLeaveEnterAnimationPlaying.value) /** to let slow devices complete slip animation smoothly */ await sleep(100);
            applyBatchedSlipInfoUpdate({
                updatedSlipInfo: response
            });
            if (refreshOriginalOdds) {
                const updatedBetsInfo = replaceOriginalOddsValues(betsInfo.value, response);
                setBetsInfo(updatedBetsInfo);
            }
            matchSlipType();
            checkFreeBet();
            enableUpdates();
        } catch (rawError) {
            catchSyncSlipError(rawError);
        }
    };
    const selectBetMode = async (mode)=>{
        setBetMode(mode);
        checkFreeBet();
        setCustomWarningMessage('');
        if (batchedSlipInfo.value && isBetModeMatchesSlipTypes.value && matchedBetMode.value !== mode) {
            disableUpdates();
            setLastChangeTime(Date.now());
            setBetModeWasManuallyChanged(true);
            const updatedSlipType = getMatchedSlipType({
                betMode: mode,
                slipTypes: slipTypes.value,
                slipSize: slipEntries.value.length,
                selectedSystemValue: selectedSystemValue.value
            });
            setSlipType(updatedSlipType);
            setSystemValue(updatedSlipType);
            await syncAddedSlipItems({
                newSlipType: updatedSlipType
            });
        }
    };
    const clearClosedMarketsData = ()=>{
        for (const timeoutId of Object.values(closedMarkets.value))Timer.clearTimeout(timeoutId);
        setClosedMarkets({});
    };
    const onSlipClear = ()=>{
        setBetModeWasManuallyChanged(false);
        setBatchedSlipInfo(null);
        setMultiSinglesMetaInfo({});
        setBetsInfo({});
        setSelectedEntriesIds([]);
        clearClosedMarketsData();
        emitSlipSelectionChange();
        safeSetStakeValue({
            maxStake: null,
            minStake: null
        });
        clearLocalStorageState();
        setEntriesWithChangedStatus([]);
        clearFreeBet();
        setCustomWarningMessage('');
    };
    const removeBetFromList = (id)=>{
        removeFromSelectedEntriesIds(id);
        removeFromSlipEntryCache(id);
        setLastBetTimeStamp(null);
        logRemoveFromSlip(id, allEntries.value);
        removeFromClosedMarkets(id);
        emitSlipSelectionChange();
        const slipEntriesCount = batchedSlipInfo.value?.slipEntries.length;
        if (!slipEntriesCount || !batchedSlipInfo.value) return;
        const clearedEntries = slipEntriesCount > 1 ? getSinglesClearedFromBetById(batchedSlipInfo.value, id) : getCombiClearedFromBetById(batchedSlipInfo.value, id);
        if (!clearedEntries.length && !slipEntryCache.value.length) {
            onSlipClear();
            handleSlipClose();
            return;
        }
        setSlipEntries(clearedEntries);
        updateMultiSinglesMetaInfo();
    };
    removeBetClick = async (id)=>{
        disableUpdates();
        removeBetFromList(id);
        if (1 === slipEventsCount.value) setBetMode(SlipTypeId.SINGLE);
        setLastChangeTime(Date.now());
        await syncAddedSlipItems();
    };
    const removeBetEvents = function() {
        let { leaveBetResultChoice, slipUnsettledSingles } = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
            leaveBetResultChoice: LeaveSlipResultChoice.CLEAR_ALL,
            slipUnsettledSingles: null
        };
        disableUpdates();
        if (batchedSlipInfo.value && slipUnsettledSingles && leaveBetResultChoice === LeaveSlipResultChoice.SAVE_UNACCEPTED) {
            const dataForUnsettledBets = getSlipDataForUnsettledBets(batchedSlipInfo.value, slipUnsettledSingles);
            setSelectedEntriesIds(slipUnsettledSingles);
            applyBatchedSlipInfoUpdate({
                updatedSlipInfo: dataForUnsettledBets
            });
        }
        if (leaveBetResultChoice !== LeaveSlipResultChoice.CLEAR_ALL) {
            logSaveSlipItemsAfterBet({
                selectedEntriesIds: selectedEntriesIds.value,
                leaveBetResultChoice
            });
            enableUpdates();
        }
        if (leaveBetResultChoice === LeaveSlipResultChoice.CLEAR_ALL) {
            setSelectedEntriesIds([]);
            setSlipEntryCache([]);
            setBetMode(SlipTypeId.SINGLE);
            onSlipClear();
        }
        emitSlipSelectionChange();
    };
    const acceptSlipChanges = async ()=>{
        disableUpdates();
        setCustomWarningMessage('');
        setPendingChanges(true);
        const currentEntries = [
            ...slipEntries.value
        ];
        let updatedEvents = currentEntries;
        logAcceptChanges(currentEntries);
        if (marketsChanged.value) {
            updatedEvents = getOnlyAvailableSlipEntries(updatedEvents);
            if (!updatedEvents.length) {
                removeBetEvents();
                setPendingChanges(false);
                return;
            }
            const availableEventsIds = updatedEvents.map((item)=>getSlipEntryId(item));
            setSelectedEntriesIds(availableEventsIds);
            setEntriesWithChangedStatus([]);
            clearClosedMarketsData();
            emitSlipSelectionChange();
        }
        if (pricesChanged.value) {
            const { updatedEntries, updatedBetsInfo } = getItemsForUpdatedPrice(updatedEvents, betsInfo.value);
            setBetsInfo(updatedBetsInfo);
            updatedEvents = updatedEntries;
        }
        updateLocalSlipInfoEntries({
            entries: updatedEvents
        });
        await syncAddedSlipItems();
        setPendingChanges(false);
    };
    const selectSystemValue = async (value)=>{
        disableUpdates();
        await syncAddedSlipItems({
            newSlipType: value
        });
    };
    const toggleBanker = async (id)=>{
        const updatedEntries = getSlipEntriesWithToggledBanker(id, slipEntries.value);
        if (updatedEntries) {
            disableUpdates();
            updateLocalSlipInfoEntries({
                entries: updatedEntries
            });
            await syncAddedSlipItems();
        }
    };
    const cancelRestoreSlipFromLocalStorage = ()=>{
        clearLocalStorageState();
        setMultiSinglesMetaInfo({});
        setRestoreFromLS(false);
    };
    const restoreSlipInfoFromLocalStorage = async ()=>{
        try {
            const snapshot = getSnapshotFromLocalStorage();
            if (!snapshot?.batchedSlipInfo) {
                cancelRestoreSlipFromLocalStorage();
                return;
            }
            setRestoreFromLS(true);
            setMultiSinglesMetaInfo(snapshot.multiSinglesMetaInfo);
            const restoredEntries = getAllBatchedEntries(snapshot.batchedSlipInfo.slipEntries);
            isSlipEntryArray(restoredEntries);
            if (Object.values(snapshot.betsInfo).some((entry)=>'betChoice' in entry || 'oddsType' in entry)) {
                /*
        old property names in localstorage, cancel to not get entries without proper runner/market labels
        if values will come from api, have no sense
        */ cancelRestoreSlipFromLocalStorage();
                return;
            }
            const slipTypeFromSnapshot = getCombiSlipInfo(snapshot.batchedSlipInfo)?.slipType || null;
            const actualData = await getBatchedSlipData({
                slipEntries: getSlipInfoRequestEntries(restoredEntries, slipTypeFromSnapshot),
                slipType: slipTypeFromSnapshot,
                oddsType: currentOddsType.value
            });
            if (!actualData || !restoringSlipFromLS.value) {
                cancelRestoreSlipFromLocalStorage();
                return;
            }
            const actualEntries = getOnlyAvailableSlipEntries(getAllBatchedEntries(actualData.slipEntries));
            if (!actualEntries.length || !isMultiSinglesEnabled.value && snapshot.betMode === SlipTypeId.SINGLE && actualEntries.length > 1) {
                cancelRestoreSlipFromLocalStorage();
                return;
            }
            // ok, setting values
            setBetMode(snapshot.betMode);
            setSystemValue(slipTypeFromSnapshot);
            const actualSelectedIds = actualEntries.map((entry)=>getSlipEntryId(entry));
            setSelectedEntriesIds(actualSelectedIds);
            setBetsInfo(snapshot.betsInfo);
            setLastBetTimeStamp(snapshot.lastBetTimeStamp);
            "1";
            /** otherwise causes bugs with desktop overlays */ setSlipVisibility(true);
            const actualSlipEntries = actualData.slipEntries.map((slipEntry)=>({
                    ...slipEntry,
                    status: SlipStatus.OK
                }));
            setBatchedSlipInfo({
                slipEntries: actualSlipEntries,
                combiAvailable: actualData.combiAvailable
            });
            updateLocalSlipInfoEntries({
                entries: actualEntries,
                overwriteMultisingles: snapshot.betMode === SlipTypeId.SINGLE
            });
            updateMultiSinglesMetaInfo();
            const parsedInputValue = Number.parseFloat(stakeInputValue.value);
            safeSetStakeValue({
                value: Number.isNaN(parsedInputValue) ? void 0 : parsedInputValue,
                maxStake: maxStake.value,
                minStake: minStake.value
            });
            emitSlipSelectionChange();
            setRestoreFromLS(false);
            syncAddedSlipItems();
        } catch (error) {
            logger.error(`restoreSlipInfoFromLocalStorage error: ${error}`);
            cancelRestoreSlipFromLocalStorage();
        }
    };
    const addToSlip = (item)=>{
        /** reset view if its new bet after just placed one */ setDefaultSlipView();
        /** set next placeBet request as new */ setLastBetTimeStamp(null);
        setCustomWarningMessage('');
        /** save last change timestamp */ setLastChangeTime(Date.now());
        /** prevent replace from previous update */ disableUpdates();
        const initialEntriesCount = slipEventsCount.value;
        const isMatchedBetMode = isBetModeMatchesSlipTypes.value;
        /** save items info for client UI */ addToBetsInfo(item);
        if (restoringSlipFromLS.value) setRestoreFromLS(false);
        /** register event id */ addToSelectedEntriesIds(getSlipEntryId(item));
        emitSlipSelectionChange();
        logAddingToSlip(item);
        /** get correct object shape for API request */ const slipEntry = getSlipEntryFromSlipListItem(item);
        /** save cache */ addToSlipEntryCache(slipEntry);
        /** open automatically if its first bet */ if (0 === initialEntriesCount && showSlipOnFirstAdded.value) handleSlipVisibilityChange(true);
        let newSlipType;
        /** adding second bet will switch to express */ if (1 === initialEntriesCount && (!isMultiSinglesEnabled.value || !betModeWasManuallyChanged.value && canSwitchToCombi.value)) {
            newSlipType = 'e-2';
            // e-2 - express, two entries
            setBetMode(SlipTypeId.EXPRESS);
        }
        if (!isMatchedBetMode && 2 === initialEntriesCount && betMode.value === SlipTypeId.SYSTEM) /** adding 3rd item with active system tab, with message like "to enable system, add atleast 3 item in slip" */ newSlipType = '0_2/3';
        // system 2/3 no bankers
        Promise.resolve().then(()=>{
            syncAddedSlipItems({
                isAdding: true,
                newSlipType
            });
        });
    };
    const acceptSinglePriceChanges = async (id)=>{
        const matchedEntry = findSlipEntryById(id, slipEntries.value);
        logAcceptChanges(matchedEntry ? [
            matchedEntry
        ] : []);
        updateSingleOriginalOdd(id);
        await backgroundUpdateSlipInfo();
    };
    const setupSlipFromSharedData = async (payload)=>{
        setLastChangeTime(Date.now());
        const actualData = await requestBatchedSlipInfo({
            slipEntries: getSlipInfoRequestEntries(payload.slipEntries, payload.slipType),
            slipType: payload.slipType,
            oddsType: currentOddsType.value,
            silent: false
        }, apiClient);
        setSelectedEntriesIds(payload.selectedEntriesIds);
        setBetsInfo(payload.betsInfo);
        setBatchedSlipInfo(actualData);
        matchSlipType();
        safeSetStakeValue({
            value: defaultBetValue.value || 0,
            minStake: minStake.value,
            maxStake: maxStake.value
        });
        enableUpdates();
    };
    // bus related
    const updateSlipEventsFromSportline = (payload)=>{
        if (!batchedSlipInfo.value || 'slip' === payload.source) return;
        setSlipEntries(updateSlipInfoFromSportlineData(batchedSlipInfo.value, payload));
    };
    const init = ()=>{
        restoreSlipInfoFromLocalStorage();
        bus.on(BusEvent.SPORT_EVENTS_RUNNERS_CHANGED, (data)=>{
            updateSlipEventsFromSportline(data);
        });
        bus.on(BusEvent.SPORT_EVENTS_STATUS_CHANGED, (data)=>{
            updateSlipEventsFromSportline(data);
        });
    };
    init();
    watch(multiSinglesTotalPrice, (value, oldValue)=>{
        saveToLocalStorage();
        if (isLoggedIn.value && (value > balance.value || oldValue > balance.value)) // run only if total price is more then balance, or
        // if oldValue > balance.value and value <= balance.value - we need run function to clean error
        checkMultiSinglesStakeOverBalance();
    });
    watch(currentOddsType, ()=>{
        syncAddedSlipItems({
            refreshOriginalOdds: true
        });
    });
    const resetPriceChangesError = ()=>{
        setCustomWarningMessage('');
        if (batchedSlipInfo.value) applyBatchedSlipInfoUpdate({
            updatedSlipInfo: clearStatusOnBatchedSlipInfo(batchedSlipInfo.value),
            isBackgroundUpdate: true
        });
    };
    return {
        addToSlip,
        syncAddedSlipItems,
        cancelSlipAdding,
        removeBetEvents,
        removeBetClick,
        selectBetMode,
        acceptSlipChanges,
        acceptSinglePriceChanges,
        checkMultiSinglesStakeOverBalance,
        toggleBanker,
        selectSystemValue,
        setClearBetListScheduled,
        removeBetFromList,
        enableUpdates,
        disableUpdates,
        matchSlipType,
        applyBatchedSlipInfoUpdate,
        saveToLocalStorage,
        setUpdateLockStatus,
        setBetMode,
        setLastBetTimeStamp,
        clearLocalStorageState,
        onAddingToSlipError,
        setCustomWarningMessage,
        resetPriceChangesError,
        setupSlipFromSharedData,
        slipEventsCount,
        bankersAvailable,
        systemOptions,
        formattedTotalOdds,
        hasLimitError,
        warningMessage,
        mustAcceptChanges,
        isSyncInProgress,
        slipTraceLines,
        multiSinglesTotalPrice,
        singleModeAvailable,
        expressModeAvailable,
        systemModeAvailable,
        slipType,
        isMultiSinglesMode,
        betEvents,
        combinedEntriesInfo,
        combiOrFirstSlip,
        isBetModeMatchesSlipTypes,
        slipEntries,
        bankerCount,
        multiSinglesUiCount,
        displayedSlipEventsCount,
        maxSlipSize,
        maxSystemSize,
        totalOdds,
        maxStake,
        minStake,
        multiSinglesAvailableForBet,
        pricesChanged,
        marketsChanged,
        isPendingChanges,
        batchedSlipInfo,
        clearBetListScheduled,
        betMode,
        selectedSystemValue,
        lastBetTimeStamp,
        restoringSlipFromLS,
        maxAvailableFastBetValue,
        selectedStakeValue,
        taxPercent
    };
});
export default useSlipInfoStore;
