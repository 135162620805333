import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective } from "vue";
import { SlipTypeId } from '@leon-hub/api-sdk';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { VSpinner, VSpinnerSize } from '@components/spinner';
import { StakeInput } from 'web/src/components/Input';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonHeight, ButtonKind } from 'web/src/components/Button/VButton/enums';
import { BetSlipSummaryStatus } from '../../enums';
import { BetSlipSelect } from '../BetSlipSelect';
import useBetSlipSummary from './composables/useBetSlipSummary';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipSummary',
    props: {
        balance: {
            default: '$0'
        },
        betMode: {
            default: SlipTypeId.SINGLE
        },
        currencySymbol: {
            default: '$'
        },
        denyPriceChanges: {
            type: Boolean
        },
        freeBetOptions: {
            default: ()=>[]
        },
        freeBetSwitchChecked: {
            type: Boolean
        },
        freeBetSwitchDisabled: {
            type: Boolean
        },
        freeBetTermKey: {
            default: ''
        },
        inputFocused: {
            type: Boolean
        },
        isMultiSingles: {
            type: Boolean
        },
        isSyncInProgress: {
            type: Boolean
        },
        isEnteringAnimationPlaying: {
            type: Boolean
        },
        maxPrize: {},
        maxPrizeWithoutBoost: {},
        maxStake: {},
        minStake: {},
        readyToDisplayErrors: {
            type: Boolean
        },
        sameStakeForSingles: {
            type: Boolean,
            default: true
        },
        selectedFreeBet: {
            default: ''
        },
        selectedSystemValue: {},
        showFreeBet: {
            type: Boolean
        },
        stakeValue: {},
        status: {
            default: BetSlipSummaryStatus.DEFAULT
        },
        systemOptions: {
            default: ()=>[]
        },
        totalOdds: {},
        totalOddsWithoutBoost: {},
        isLoggedIn: {
            type: Boolean
        },
        warningMessage: {},
        wrongBetMode: {
            type: Boolean
        },
        isVisible: {
            type: Boolean,
            default: true
        },
        shareEnabled: {
            type: Boolean
        },
        taxPercent: {},
        taxAmount: {}
    },
    emits: [
        "stake-focus",
        "stake-input",
        "stake-blur",
        "allow-price-changes",
        "freebet-switch",
        "system-select",
        "freebet-select",
        "toggle-same-stake-for-singles",
        "clear-button-click",
        "settings-click",
        "share-slip"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { canShowFreeBetSelector, computedWarningMessage, errorLabel, freebetTermsRouteParams, inputError, isSystemMode, mustShowBalanceMessage, mustShowMessage, mustShowPrizeMessage, showAmountValue, showWrongModeMessage, wrongModeMessage, isPcpSwitchCollapsed, emitStakeInput, emitStakeFocus, emitStakeBlur, onAllowPriceChanges, emitToggleSameStakeForSingles, onFreeBetSwitchClick, emitFreeBetSwitch, emitFreebetSelect, emitSystemSelect, emitClearClick, emitSettingsClick } = useBetSlipSummary(props, emits);
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['bet-slip-summary']]: true,
                    [_ctx.$style['bet-slip-summary--wrong-mode']]: _ctx.wrongBetMode,
                    [_ctx.$style['bet-slip-summary--phone']]: false,
                    [_ctx.$style['bet-slip-summary--desktop']]: true
                })
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bet-slip-summary__controls'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['bet-slip-summary__contols-group'])
                    }, [
                        _createVNode(VButton, {
                            kind: _unref(ButtonKind).SECONDARY,
                            height: _unref(ButtonHeight).TINY,
                            onClick: _unref(emitClearClick)
                        }, {
                            default: _withCtx(()=>[
                                    _createVNode(_unref(VIcon), {
                                        name: _unref(IconName).BIN,
                                        size: _unref(IconSize).SIZE_16,
                                        class: _normalizeClass(_ctx.$style['bet-slip-summary__colored-icon'])
                                    }, null, 8, [
                                        "name",
                                        "size",
                                        "class"
                                    ]),
                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SLIP_REMOVE_ALL_EVENTS')), 1)
                                ]),
                            _: 1
                        }, 8, [
                            "kind",
                            "height",
                            "onClick"
                        ]),
                        _ctx.shareEnabled ? (_openBlock(), _createBlock(VButton, {
                            key: 0,
                            kind: _unref(ButtonKind).SECONDARY,
                            height: _unref(ButtonHeight).TINY,
                            onClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('share-slip'))
                        }, {
                            default: _withCtx(()=>[
                                    _createVNode(_unref(VIcon), {
                                        name: _unref(IconName).SHARE,
                                        size: _unref(IconSize).SIZE_16,
                                        class: _normalizeClass(_ctx.$style['bet-slip-summary__colored-icon'])
                                    }, null, 8, [
                                        "name",
                                        "size",
                                        "class"
                                    ]),
                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_SHARE')), 1)
                                ]),
                            _: 1
                        }, 8, [
                            "kind",
                            "height"
                        ])) : _createCommentVNode("", true)
                    ], 2),
                    _ctx.isLoggedIn ? (_openBlock(), _createBlock(VButton, {
                        key: 0,
                        title: _ctx.$t('WEB2_SETTINGS'),
                        kind: _unref(ButtonKind).SECONDARY,
                        height: _unref(ButtonHeight).TINY,
                        "icon-name": _unref(IconName).COG,
                        onClick: _unref(emitSettingsClick)
                    }, null, 8, [
                        "title",
                        "kind",
                        "height",
                        "icon-name",
                        "onClick"
                    ])) : _createCommentVNode("", true)
                ], 2),
                _ctx.isLoggedIn ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass({
                        [_ctx.$style['bet-slip-summary__collapsible']]: true,
                        [_ctx.$style['bet-slip-summary__collapsible--collapsed']]: _unref(isPcpSwitchCollapsed)
                    })
                }, [
                    _createVNode(VSwitch, {
                        class: _normalizeClass(_ctx.$style['bet-slip-summary__switch']),
                        checked: !_ctx.denyPriceChanges,
                        label: _ctx.$t('JS_PRICE_CHANGE_POLICY_CHECKBOX'),
                        onAnimationCompleted: _unref(onAllowPriceChanges)
                    }, null, 8, [
                        "class",
                        "checked",
                        "label",
                        "onAnimationCompleted"
                    ])
                ], 2)) : _createCommentVNode("", true),
                _ctx.isMultiSingles ? (_openBlock(), _createBlock(VSwitch, {
                    key: 1,
                    class: _normalizeClass(_ctx.$style['bet-slip-summary__switch']),
                    checked: _ctx.sameStakeForSingles,
                    label: _ctx.$t('WEB2_SLIP_SAME_AMOUNT_FOR_SINGLES'),
                    onChange: _unref(emitToggleSameStakeForSingles)
                }, null, 8, [
                    "class",
                    "checked",
                    "label",
                    "onChange"
                ])) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bet-slip-summary__content'])
                }, [
                    _unref(showWrongModeMessage) ? (_openBlock(), _createElementBlock(_Fragment, {
                        key: 1
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['bet-slip-summary__wrong-mode-message'])
                        }, _toDisplayString(_unref(wrongModeMessage)), 3),
                        _createCommentVNode("", true)
                    ], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                        key: 0
                    }, [
                        _unref(canShowFreeBetSelector) ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['bet-slip-summary__freebet-switch']),
                            onClick: _cache[1] || (_cache[1] = //@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(onFreeBetSwitchClick) && _unref(onFreeBetSwitchClick)(...args);
                            })
                        }, [
                            _createVNode(VSwitch, {
                                checked: _ctx.freeBetSwitchChecked,
                                disabled: _ctx.freeBetSwitchDisabled,
                                label: _ctx.$t('WEB2_USE_FREEBET'),
                                onChange: _unref(emitFreeBetSwitch)
                            }, null, 8, [
                                "checked",
                                "disabled",
                                "label",
                                "onChange"
                            ])
                        ], 2)) : _createCommentVNode("", true),
                        _withDirectives(_createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['bet-slip-summary__input'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['bet-slip-summary__amount'])
                            }, [
                                _ctx.betMode === _unref(SlipTypeId).EXPRESS ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass({
                                        [_ctx.$style['bet-slip-summary__total']]: true,
                                        [_ctx.$style['bet-slip-summary__total--wide']]: _ctx.freeBetSwitchChecked,
                                        [_ctx.$style['bet-slip-summary__total--boosted']]: !!_ctx.totalOddsWithoutBoost
                                    })
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['bet-slip-summary__total-label'])
                                    }, _toDisplayString(_ctx.$t('WEB2_SLIP_ODDS_LABEL')) + ": ", 3),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['bet-slip-summary__total-coefficient'])
                                    }, [
                                        _ctx.totalOddsWithoutBoost ? (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 0
                                        }, [
                                            _createElementVNode("span", {
                                                class: _normalizeClass(_ctx.$style['bet-slip-summary__total-inactive'])
                                            }, _toDisplayString(_ctx.totalOddsWithoutBoost), 3),
                                            _cache[2] || (_cache[2] = _createTextVNode(" "))
                                        ], 64)) : _createCommentVNode("", true),
                                        _createElementVNode("span", {
                                            class: _normalizeClass({
                                                [_ctx.$style['bet-slip-summary__total-value']]: true,
                                                [_ctx.$style['bet-slip-summary__total-value--boosted']]: !!_ctx.totalOddsWithoutBoost
                                            })
                                        }, _toDisplayString(_ctx.totalOdds), 3)
                                    ], 2)
                                ], 2)) : _createCommentVNode("", true),
                                _unref(isSystemMode) ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: _normalizeClass(_ctx.$style['bet-slip-summary__system-select'])
                                }, [
                                    _createVNode(_unref(BetSlipSelect), {
                                        name: "slipSystemSelect",
                                        options: _ctx.systemOptions,
                                        "selected-value": _ctx.selectedSystemValue,
                                        onChange: _unref(emitSystemSelect)
                                    }, null, 8, [
                                        "options",
                                        "selected-value",
                                        "onChange"
                                    ])
                                ], 2)) : _createCommentVNode("", true),
                                _ctx.freeBetSwitchChecked ? _createCommentVNode("", true) : (_openBlock(), _createBlock(_unref(StakeInput), {
                                    key: 2,
                                    value: _ctx.stakeValue,
                                    "have-error": _unref(inputError),
                                    "is-focused": _ctx.inputFocused,
                                    "currency-symbol": _ctx.currencySymbol,
                                    "bet-mode": _ctx.betMode,
                                    onFocus: _unref(emitStakeFocus),
                                    onBlur: _unref(emitStakeBlur),
                                    onInput: _unref(emitStakeInput)
                                }, null, 8, [
                                    "value",
                                    "have-error",
                                    "is-focused",
                                    "currency-symbol",
                                    "bet-mode",
                                    "onFocus",
                                    "onBlur",
                                    "onInput"
                                ]))
                            ], 2),
                            _ctx.freeBetSwitchChecked && !_unref(isSystemMode) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['bet-slip-summary__amount'])
                            }, [
                                _createVNode(_unref(BetSlipSelect), {
                                    name: "slipFreebetSelect",
                                    "is-freebet": "",
                                    "selected-value": _ctx.selectedFreeBet,
                                    options: _ctx.freeBetOptions,
                                    onChange: _unref(emitFreebetSelect)
                                }, null, 8, [
                                    "selected-value",
                                    "options",
                                    "onChange"
                                ])
                            ], 2)) : _createCommentVNode("", true),
                            _unref(errorLabel) ? (_openBlock(), _createElementBlock("div", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['bet-slip-summary__error-label'])
                            }, _toDisplayString(_unref(errorLabel)), 3)) : _createCommentVNode("", true),
                            _ctx.freeBetSwitchChecked ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock(_Fragment, {
                                key: 2
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['bet-slip-summary__fast-bets']),
                                    "data-test-id": "control-fast-bets"
                                }, [
                                    _renderSlot(_ctx.$slots, "fast-bets")
                                ], 2),
                                _createCommentVNode("", true)
                            ], 64))
                        ], 2), [
                            [
                                _vShow,
                                _unref(showAmountValue)
                            ]
                        ]),
                        _ctx.freeBetSwitchChecked ? (_openBlock(), _createBlock(_component_router_link, {
                            key: 1,
                            to: _unref(freebetTermsRouteParams),
                            class: _normalizeClass(_ctx.$style['bet-slip-summary__freebet-terms-link']),
                            "data-test-id": "freebet-terms-link"
                        }, {
                            default: _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_ctx.$t('WEB2_FREEBET_TERMS')), 1)
                                ]),
                            _: 1
                        }, 8, [
                            "to",
                            "class"
                        ])) : _createCommentVNode("", true),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['bet-slip-summary__footer'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['bet-slip-summary__button'])
                            }, [
                                _renderSlot(_ctx.$slots, "button")
                            ], 2),
                            _ctx.taxAmount && _unref(mustShowPrizeMessage) ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass({
                                    [_ctx.$style['bet-slip-summary__message']]: true,
                                    [_ctx.$style['bet-slip-summary__message--tax']]: true,
                                    [_ctx.$style['bet-slip-summary__message--hidden']]: _ctx.isEnteringAnimationPlaying
                                })
                            }, [
                                _createElementVNode("span", {
                                    class: _normalizeClass([
                                        _ctx.$style['bet-slip-summary__message-label'],
                                        _ctx.$style['bet-slip-summary__message-label--tax']
                                    ])
                                }, _toDisplayString(_ctx.$t('WEB2_BET_DETAILS_TAX', {
                                    amount: _ctx.taxPercent ?? ''
                                })), 3),
                                _createElementVNode("span", {
                                    class: _normalizeClass(_ctx.$style['bet-slip-summary__message-right'])
                                }, _toDisplayString(_ctx.taxAmount), 3)
                            ], 2)) : _createCommentVNode("", true),
                            _createElementVNode("div", {
                                class: _normalizeClass({
                                    [_ctx.$style['bet-slip-summary__message']]: true,
                                    [_ctx.$style['bet-slip-summary__message--hidden']]: _ctx.isEnteringAnimationPlaying,
                                    [_ctx.$style['bet-slip-summary__message--centered']]: _unref(mustShowMessage) || _ctx.isSyncInProgress
                                })
                            }, [
                                _ctx.isSyncInProgress ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['bet-slip-summary__sync'])
                                }, [
                                    _createVNode(_unref(VSpinner), {
                                        size: _unref(VSpinnerSize).SMALL,
                                        class: _normalizeClass(_ctx.$style['bet-slip-summary__sync-icon'])
                                    }, null, 8, [
                                        "size",
                                        "class"
                                    ]),
                                    _createTextVNode(" " + _toDisplayString(_ctx.$t('WEB2_SYNCING_DATA')), 1)
                                ], 2)) : _unref(mustShowBalanceMessage) ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 1
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['bet-slip-summary__message-label'])
                                    }, _toDisplayString(_ctx.$t('JSP_FNGAMES_BALANCE_TEXT')) + ": ", 3),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['bet-slip-summary__message-right'])
                                    }, [
                                        _createElementVNode("span", {
                                            class: _normalizeClass([
                                                _ctx.$style['bet-slip-summary__message-amount'],
                                                _ctx.$style['bet-slip-summary__message-amount--balance']
                                            ])
                                        }, _toDisplayString(_ctx.balance), 3)
                                    ], 2)
                                ], 64)) : _unref(mustShowPrizeMessage) ? (_openBlock(), _createElementBlock(_Fragment, {
                                    key: 2
                                }, [
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['bet-slip-summary__message-label'])
                                    }, _toDisplayString(_ctx.$t('JSPBET_BETDET_MAX_WIN')) + ": ", 3),
                                    _createElementVNode("span", {
                                        class: _normalizeClass(_ctx.$style['bet-slip-summary__message-right'])
                                    }, [
                                        _ctx.maxPrizeWithoutBoost ? (_openBlock(), _createElementBlock("span", {
                                            key: 0,
                                            class: _normalizeClass([
                                                _ctx.$style['bet-slip-summary__message-amount'],
                                                _ctx.$style['bet-slip-summary__message-amount--prize-wthout-boost']
                                            ])
                                        }, _toDisplayString(_ctx.maxPrizeWithoutBoost), 3)) : _createCommentVNode("", true),
                                        _createElementVNode("span", {
                                            class: _normalizeClass([
                                                _ctx.$style['bet-slip-summary__message-amount'],
                                                _ctx.$style['bet-slip-summary__message-amount--prize']
                                            ])
                                        }, "  " + _toDisplayString(_ctx.maxPrize), 3)
                                    ], 2)
                                ], 64)) : _unref(mustShowMessage) ? (_openBlock(), _createElementBlock("span", {
                                    key: 3,
                                    class: _normalizeClass(_ctx.$style['bet-slip-summary__warning-message'])
                                }, _toDisplayString(_unref(computedWarningMessage)), 3)) : _createCommentVNode("", true)
                            ], 2)
                        ], 2)
                    ], 64))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetSlipSummary'
                ]
            ]);
        };
    }
});
