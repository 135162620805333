import isSlipEntryId from '../../../guards/isSlipEntryId';
export default function getMetaInfoWithBalanceCheck(param) {
    let { multiSinglesAvailableForBet, currentMetaInfo, balance, lastModifiedId } = param;
    const metaInfoCopy = {};
    for (const id of Object.keys(currentMetaInfo)){
        isSlipEntryId(id);
        metaInfoCopy[id] = {
            ...currentMetaInfo[id],
            stakeOverBalance: false
        };
    }
    let totalPrice = 0;
    let highestValue = 0;
    let entryWithHighestStake;
    for (const entry of multiSinglesAvailableForBet){
        const stakeValue = Number(entry.metaInfo?.stakeValue || '0');
        totalPrice += stakeValue;
        if (stakeValue >= highestValue) {
            highestValue = stakeValue;
            entryWithHighestStake = entry.id;
        }
    }
    const stakeOverBalance = null !== balance && totalPrice > balance;
    if (stakeOverBalance) {
        const entryToShowError = lastModifiedId ?? entryWithHighestStake;
        isSlipEntryId(entryToShowError);
        metaInfoCopy[entryToShowError] = {
            ...currentMetaInfo[entryToShowError],
            stakeOverBalance: true
        };
    }
    return metaInfoCopy;
}
