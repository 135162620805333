import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
import { BetSlipMainButtonMode } from '../../../enums';
export default function useBetSlipPlaceBetButton(props, emit) {
    const formatMoney = useFormatMoney();
    const { $translate } = useI18n();
    const emitClick = ()=>{
        emit('click');
    };
    const isLoading = computed(()=>{
        switch(props.mode){
            case BetSlipMainButtonMode.PENDING_CHANGES:
            case BetSlipMainButtonMode.PENDING_PLACE_BET:
                return true;
            default:
                return false;
        }
    });
    const multiSinglesTotalPriceString = computed(()=>({
            amount: props.multiSinglesTotalPrice ? formatMoney(props.multiSinglesTotalPrice) : ''
        }));
    const multisinglesDoBetLabel = $translate('WEB2_SLIP_PLACE_MULTI_SINGLE_BET', multiSinglesTotalPriceString);
    const label = computed(()=>{
        switch(props.mode){
            case BetSlipMainButtonMode.NOT_LOGGED_IN:
                return $translate('WEB2_SLIP_AUTH_BUTTON').value;
            case BetSlipMainButtonMode.MUST_ACCEPT_CHANGES:
            case BetSlipMainButtonMode.PENDING_CHANGES:
                return $translate('JSPBET_ACCEPT_CHANGES').value;
            case BetSlipMainButtonMode.NOT_ENOUGH_BALANCE:
                return $translate('WEB2_MAKE_DEPOSIT').value;
            case BetSlipMainButtonMode.PENDING_PLACE_BET:
                return $translate('WEB2_PLACE_BET_BUTTON_PENDING').value;
            case BetSlipMainButtonMode.PLACE_BET_ERROR:
                return $translate('WEB2_PLACE_BET_BUTTON_ERROR').value;
            case BetSlipMainButtonMode.BET_SUCCESSFULLY_PLACED:
                return $translate('WEB2_DONE').value;
            case BetSlipMainButtonMode.INCORRECT_RANGE:
                return $translate('WEB2_SLIP_FIX_AMOUNT').value;
            case BetSlipMainButtonMode.NOT_ENOUGH_EVENTS:
                return $translate('WEB2_SLIP_ADD_EVENT').value;
            case BetSlipMainButtonMode.IDENTIFICATION_REQUIRED:
                return $translate('WEB2_NOTIFICATION_IDENTIFICATION_PASS_TEXT').value;
            default:
                return props.isMultiSinglesMode ? multisinglesDoBetLabel.value : $translate('JSPBET_PLBET_DOBET').value;
        }
    });
    const buttonKind = computed(()=>{
        switch(props.mode){
            case BetSlipMainButtonMode.INCORRECT_RANGE:
            case BetSlipMainButtonMode.NOT_ENOUGH_BALANCE:
            case BetSlipMainButtonMode.MUST_ACCEPT_CHANGES:
                return ButtonKind.YELLOW;
            case BetSlipMainButtonMode.PLACE_BET_ERROR:
            case BetSlipMainButtonMode.NOT_ENOUGH_EVENTS:
                return ButtonKind.SECONDARY;
            default:
                return ButtonKind.PRIMARY;
        }
    });
    const iconName = computed(()=>{
        switch(props.mode){
            case BetSlipMainButtonMode.NOT_LOGGED_IN:
                return IconName.PERSON_FILLED;
            case BetSlipMainButtonMode.BET_SUCCESSFULLY_PLACED:
                return IconName.CHECK_FILLED;
            case BetSlipMainButtonMode.NOT_ENOUGH_BALANCE:
                return IconName.ADD;
            case BetSlipMainButtonMode.MUST_ACCEPT_CHANGES:
                return IconName.ATTENTION_OUTLINE;
            default:
                return;
        }
    });
    return {
        emitClick,
        isLoading,
        buttonKind,
        iconName,
        label
    };
}
