import { computed } from 'vue';
import { IconName, IconSize } from '@leon-hub/icons';
import { useDialogs } from 'web/src/modules/dialogs/composables';
import { DialogAction, PresetName } from 'web/src/modules/dialogs/enums';
import { JumbotronIconKind } from 'web/src/components/Jumbotron';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { useI18n } from 'web/src/modules/i18n/composables';
import { getSafePercentage } from 'web/src/modules/core/utils/math';
export default function useAccumulatorBoostInfo(props) {
    const { showDialog } = useDialogs();
    const { $translate } = useI18n();
    const oddsTranslateParams = computed(()=>({
            minOdds: props.requiredOdds ?? ''
        }));
    const boost = computed(()=>({
            boost: `${props.nextMultiplier}`
        }));
    const termsTranslateParams = computed(()=>({
            ...oddsTranslateParams.value,
            itemsToNextBoost: `${props.itemsToNextBoost ?? ''}`
        }));
    const progress = computed(()=>getSafePercentage(props.currentMultiplier ?? 0, props.maxMultiplier ?? 0));
    const showInfoModal = ()=>{
        const modalProperties = {
            presetName: PresetName.ALERT_WARNING,
            options: {
                title: $translate('WEB2_ACCUMULATOR_BOOST_MODAL_CAPTION').value,
                confirmMessage: $translate('WEB2_ACCUMULATOR_BOOST_MODAL_TEXT', oddsTranslateParams).value,
                iconName: IconName.LIGHTNING,
                iconSize: IconSize.SIZE_60,
                iconKind: JumbotronIconKind.SUCCESS,
                fullHeight: void 0,
                isOverlayCloseAllowed: true,
                buttons: [
                    {
                        label: $translate('WEB2_CLOSE').value,
                        kind: ButtonKind.PRIMARY,
                        action: DialogAction.MODAL_CLOSE
                    }
                ]
            },
            id: 'accumulatorBoostMoreInfo'
        };
        showDialog(modalProperties);
    };
    return {
        showInfoModal,
        progress,
        boost,
        termsTranslateParams
    };
}
