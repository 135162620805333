import { computed, toRef } from 'vue';
import { useFastBetsStore } from 'web/src/modules/fast-bets/store';
import { usePriceChangePolicy } from 'web/src/modules/customer/composables';
import { SettingsTabs } from '../enums';
import useSlipUserSettings from '../submodules/slip-user-settings/store/useSlipUserSettings';
import { useSlipViewSettingsStore } from '../submodules/slip-view-settings/store';
import { useSlipInfoStore } from '../submodules/slip-info/store';
// settings block
// SlipCommonSettings
// editFastBetProps
export function useBetSlipSettings() {
    const { priceChangePolicy, savePriceChangePolicy } = usePriceChangePolicy();
    const { resetPriceChangesError } = useSlipInfoStore();
    const slipSettingsStore = useSlipUserSettings();
    const fastBetsStore = useFastBetsStore();
    const slipViewSettingsStore = useSlipViewSettingsStore();
    const { initFastBetInputValues, handleFastBetValuesBlur, handleFastBetValuesChange, clearFastBetInputValues, handleFastBetMaxSwitch } = fastBetsStore;
    const { selectSettingsTab, saveBetSlipConfiguration, clearEditStandardBet, onStandardBetFocus, onStandardBetInput, onStandardBetBlur } = slipSettingsStore;
    const activeSettingsTabId = toRef(slipSettingsStore, 'activeSettingsTabId');
    const showSlipOnFirstAdded = toRef(slipSettingsStore, 'showSlipOnFirstAdded');
    // SlipEditFastBet
    const minFastBet = toRef(fastBetsStore, 'minAvailableFastBet');
    const maxFastBet = toRef(fastBetsStore, 'maxAvailableFastBet');
    const fastBets = toRef(fastBetsStore, 'fastBetInputItems');
    const changesIsSaved = toRef(fastBetsStore, 'fastBetsSaved');
    const maxEnabled = toRef(fastBetsStore, 'fastBetsShowMax');
    const standardBetLimitations = toRef(slipSettingsStore, 'standardBetLimitations');
    const standardBetInputErrorKind = toRef(slipSettingsStore, 'standardBetInputErrorKind');
    const standardBetAmountIsSaved = toRef(slipSettingsStore, 'standardBetAmountIsSaved');
    const standardBetAmount = toRef(slipSettingsStore, 'standardBetAmount');
    const useStandardBet = toRef(slipSettingsStore, 'useStandardBet');
    const minStandardBet = computed(()=>standardBetLimitations.value?.min || 0);
    const maxStandardBet = computed(()=>standardBetLimitations.value?.max);
    const toggleShowSlipOnFirstAdded = ()=>{
        saveBetSlipConfiguration({
            showSlipOnFirstAdded: !showSlipOnFirstAdded.value
        });
    };
    const toggleUseStandardBet = ()=>{
        saveBetSlipConfiguration({
            useStandardBet: !useStandardBet.value
        });
    };
    const onBastBetSettingsLeave = ()=>{
        clearFastBetInputValues();
        clearEditStandardBet();
        onStandardBetFocus();
    };
    const { hideSlipSettings } = slipViewSettingsStore;
    const onSettingsBackClick = ()=>{
        hideSlipSettings();
        selectSettingsTab(SettingsTabs.COMMON_SETTINGS);
    };
    const changePriceChangePolicy = (value)=>{
        resetPriceChangesError();
        savePriceChangePolicy(value);
    };
    return {
        activeSettingsTabId,
        selectSettingsTab,
        onSettingsBackClick,
        priceChangePolicy,
        showSlipOnFirstAdded,
        changePriceChangePolicy,
        toggleShowSlipOnFirstAdded,
        minFastBet,
        maxFastBet,
        maxEnabled,
        changesIsSaved,
        fastBets,
        useStandardBet,
        minStandardBet,
        maxStandardBet,
        standardBetAmount,
        standardBetAmountIsSaved,
        standardBetInputErrorKind,
        initFastBetInputValues,
        onBastBetSettingsLeave,
        handleFastBetValuesBlur,
        handleFastBetValuesChange,
        onStandardBetFocus,
        handleFastBetMaxSwitch,
        toggleUseStandardBet,
        onStandardBetInput,
        onStandardBetBlur
    };
}
