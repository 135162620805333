import { computed } from 'vue';
import { SlipTypeId } from '@leon-hub/api-sdk';
import { useI18n } from 'web/src/modules/i18n/composables';
import isSlipTypeId from '../../../guards/isSlipTypeId';
import { KeyboardInputType } from '../../../enums';
export default function useBetSlipMain(props, emit) {
    const { $translate } = useI18n();
    const betModeTabs = computed(()=>[
            {
                id: SlipTypeId.SINGLE,
                label: $translate('WEB2_SLIP_BET_MODE_SINGLE').value,
                disabled: props.singleModeDisabled
            },
            {
                id: SlipTypeId.EXPRESS,
                label: $translate('JSPNAV_BETSLIP_TYPE2').value,
                disabled: props.expressModeDisabled
            },
            {
                id: SlipTypeId.SYSTEM,
                label: $translate('JSPNAV_BETSLIP_TYPE5').value,
                disabled: props.systemModeDisabled
            }
        ]);
    const showDeleteAnimation = computed(()=>{
        "1";
        return false;
    });
    const onStakeInputBlur = (id)=>{
        emit('stake-input-blur', id);
    };
    const clearMobileFocus = ()=>{};
    // eslint-disable-next-line sonarjs/no-collapsible-if
    const selectBetMode = (id)=>{
        clearMobileFocus();
        isSlipTypeId(id);
        emit('select-bet-mode', id);
    };
    const onEventDeleteClick = (id)=>{
        clearMobileFocus();
        emit('event-delete-click', id);
    };
    const onEventBankerClick = (id)=>{
        emit('event-banker-click', id);
    };
    const onTraceClick = function() {
        let index = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
        emit('trace-click', index);
    };
    const showInputBlock = computed(()=>!!(props.isMultiSinglesMode && !props.sameStakeForSingles));
    const emitStakeInput = (payload)=>{
        emit('stake-input', payload);
    };
    const onStakeInput = (value, id)=>{
        emitStakeInput({
            value,
            id
        });
    };
    const onStakeInputFocus = (id)=>{
        clearMobileFocus();
        emit('stake-input-focus', id);
    };
    const onEventAcceptChanges = (id)=>{
        clearMobileFocus();
        emit('event-accept-changes', id);
    };
    const emitSinglesMobileInput = (payload)=>{
        emit('singles-mobile-input', payload);
    };
    const onBackspaceInput = (id)=>{
        emitSinglesMobileInput({
            id,
            action: KeyboardInputType.BACKSPACE
        });
    };
    const onNumberInput = (value, id)=>{
        emitSinglesMobileInput({
            id,
            value,
            action: KeyboardInputType.NUMBER
        });
    };
    const onDecimalInput = (id)=>{
        emitSinglesMobileInput({
            id,
            action: KeyboardInputType.DECIMAL
        });
    };
    const onAppear = ()=>{};
    const onDisappear = ()=>{};
    const selectFastBet = (value, id)=>{
        emitStakeInput({
            value: `${value}`,
            id,
            isFastBetValue: true
        });
    };
    const onEditFastBets = ()=>{
        emit('edit-fast-bets');
    };
    return {
        betModeTabs,
        showDeleteAnimation,
        selectBetMode,
        onEventDeleteClick,
        onEventBankerClick,
        showInputBlock,
        onTraceClick,
        onStakeInput,
        onStakeInputFocus,
        onStakeInputBlur,
        onEventAcceptChanges,
        onAppear,
        onDisappear,
        onBackspaceInput,
        onNumberInput,
        onDecimalInput,
        selectFastBet,
        onEditFastBets
    };
}
