import { getBatchSlipInfo } from '@leon-hub/api-sdk';
import { OddsType } from 'web/src/modules/sportline/submodules/odds/enums/OddsType';
export default function requestBatchedSlipInfo(param, apiClient) {
    let { slipEntries, slipType = null, oddsType = OddsType.CLASSIC, silent = false } = param;
    return getBatchSlipInfo(apiClient, (node)=>node.queries.betSlip.getBatchSlipInfo, {
        options: {
            slipType,
            slipEntries,
            oddsType,
            checkCombiBetAvailability: true
        }
    }, {
        silent
    });
}
