import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
import ConfirmModal from 'web/src/modules/dialogs/views/ConfirmModal/ConfirmModal.vue';
import { ModalWidth } from 'web/src/components/Modal/enums';
import BetSlipTraceContent from './BetSlipTraceContent.vue';
import useBetSlipTrace from '../composables/useBetSlipTrace';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipTrace',
    props: {
        lines: {
            default: ()=>[]
        }
    },
    emits: [
        "close-modal"
    ],
    setup (__props) {
        const props = __props;
        const { formattedLines } = useBetSlipTrace(props);
        const modal = {
            width: ModalWidth.DEFAULT,
            buttons: []
        };
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(ConfirmModal, {
                class: _normalizeClass(_ctx.$style['slip-trace-modal']),
                modal: modal,
                "is-alert": "",
                onClose: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('close-modal'))
            }, {
                default: _withCtx(()=>[
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['bet-slip-trace'])
                        }, [
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['bet-slip-trace__heading'])
                            }, " Slip trace ", 2),
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['bet-slip-trace__content'])
                            }, [
                                _createElementVNode("table", {
                                    class: _normalizeClass(_ctx.$style['bet-slip-trace__table'])
                                }, [
                                    _createElementVNode("tbody", null, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(formattedLines), (line, index)=>(_openBlock(), _createElementBlock("tr", {
                                                key: index,
                                                class: _normalizeClass({
                                                    [_ctx.$style['bet-slip-trace__row']]: true,
                                                    [_ctx.$style['bet-slip-trace__row--error']]: line.error
                                                })
                                            }, [
                                                _createElementVNode("td", {
                                                    class: _normalizeClass([
                                                        _ctx.$style['bet-slip-trace__cell'],
                                                        _ctx.$style['bet-slip-trace__cell--time']
                                                    ])
                                                }, _toDisplayString(line.formattedTime), 3),
                                                _createElementVNode("td", {
                                                    class: _normalizeClass([
                                                        _ctx.$style['bet-slip-trace__cell'],
                                                        _ctx.$style['bet-slip-trace__cell--content']
                                                    ])
                                                }, [
                                                    _createVNode(BetSlipTraceContent, {
                                                        contents: line.contents
                                                    }, null, 8, [
                                                        "contents"
                                                    ])
                                                ], 2)
                                            ], 2))), 128))
                                    ])
                                ], 2)
                            ], 2)
                        ], 2)
                    ]),
                _: 1
            }, 8, [
                "class"
            ])), [
                [
                    _directive_auto_id,
                    'BetSlipTrace'
                ]
            ]);
        };
    }
});
