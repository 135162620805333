import { MarketStatus } from '@leon-hub/api-sdk';
export default function getEventsWithChangedMarkets(entries) {
    return entries.filter((entry)=>{
        if (!entry) return false;
        const { marketStatus } = entry;
        return marketStatus === MarketStatus.PRICE_DOWN || marketStatus === MarketStatus.PRICE_UP;
    }).map((param)=>{
        let { event, odds, market, runner } = param;
        return {
            event,
            odds,
            runner,
            market
        };
    });
}
