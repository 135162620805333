export default function validateMultiSinglesMetaStake(payload) {
    const { record, newStakeValue, fastBetValue } = payload;
    const { maxStake, minStake } = record;
    const isNewValue = 'newStakeValue' in payload;
    const stakeWasEdited = isNewValue || record.stakeWasEdited;
    const stakeValue = (isNewValue ? newStakeValue : record.stakeValue) || '';
    const max = maxStake || 0;
    const min = minStake || 0;
    let error = '';
    const numericStake = Number(stakeValue);
    if (numericStake > 0 && numericStake < min || max && numericStake > max) {
        const from = payload.formatMoney(min, {
            hideCurrency: true
        });
        const to = payload.formatMoney(max);
        error = `${from} – ${to}`;
    }
    let additionalFields = {};
    if (fastBetValue) additionalFields = {
        selectedFastBetValue: fastBetValue,
        clearValueOnFocus: true
    };
    if (isNewValue && !fastBetValue) additionalFields = {
        selectedFastBetValue: null,
        clearValueOnFocus: false
    };
    return {
        ...record,
        ...additionalFields,
        stakeValue,
        stakeWasEdited,
        error
    };
}
