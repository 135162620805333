import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonHeight } from 'web/src/components/Button/VButton/enums';
import { BetSlipMainButtonMode } from '../../enums';
import useBetSlipPlaceBetButton from './composables/useBetSlipPlaceBetButton';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipPlaceBetButton',
    props: {
        mode: {
            default: BetSlipMainButtonMode.READY_TO_PLACE_BET
        },
        disabled: {
            type: Boolean
        },
        isMultiSinglesMode: {
            type: Boolean
        },
        multiSinglesTotalPrice: {
            default: 0
        }
    },
    emits: [
        "click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { emitClick, isLoading, buttonKind, iconName, label } = useBetSlipPlaceBetButton(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(VButton, {
                label: _unref(label),
                "is-loading": _unref(isLoading),
                kind: _unref(buttonKind),
                disabled: _ctx.disabled,
                "icon-name": _unref(iconName),
                height: _unref(ButtonHeight).LARGE,
                "full-width": "",
                onClick: _unref(emitClick)
            }, null, 8, [
                "label",
                "is-loading",
                "kind",
                "disabled",
                "icon-name",
                "height",
                "onClick"
            ])), [
                [
                    _directive_auto_id,
                    'BetSlipPlaceBetButton'
                ]
            ]);
        };
    }
});
