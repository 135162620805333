import { computed } from 'vue';
import { IconName } from '@leon-hub/icons';
import { useI18n } from 'web/src/modules/i18n/composables';
import { BetSlipResultState, LeaveSlipResultChoice } from '../../../enums';
export default function useBetSlipResult(props, emit) {
    const { $translate } = useI18n();
    const isContentVisible = computed(()=>props.mode !== BetSlipResultState.INITIAL && props.mode !== BetSlipResultState.PENDING);
    const isPartiallyAccepted = computed(()=>!!(props.isMultiSinglesMode && (props.totalBetsPlaced ?? 0) > (props.totalBetsAccepted ?? 0)));
    const isError = computed(()=>props.mode === BetSlipResultState.ERROR);
    const isSuccess = computed(()=>props.mode === BetSlipResultState.SUCCESS);
    const isCompleted = computed(()=>isError.value || isSuccess.value);
    /** caught limits exception on multisingles */ const isPartiallyAcceptedByLimits = computed(()=>!!(props.isMultiSinglesMode && props.limitsExceeded && isSuccess.value));
    const iconName = computed(()=>{
        switch(props.mode){
            case BetSlipResultState.SUCCESS:
                return isPartiallyAccepted.value ? IconName.CHECKBOX_MULTIPLE : IconName.CHECK_OUTLINE;
            case BetSlipResultState.ERROR:
                return IconName.ATTENTION_OUTLINE;
            default:
                return;
        }
    });
    const multiSuccessTranslateParams = computed(()=>({
            placed: `${props.totalBetsPlaced ?? 0}`,
            accepted: `${props.totalBetsAccepted ?? 0}`
        }));
    const headingText = computed(()=>{
        switch(props.mode){
            case BetSlipResultState.SUCCESS:
                return props.isMultiSinglesMode ? $translate('WEB2_PLACE_BET_MULTIPLE_RESULT', multiSuccessTranslateParams).value : $translate('JS_SLIP_BET_ACCEPTED').value;
            case BetSlipResultState.ERROR:
                return props.isMultiSinglesMode ? $translate('WEB2_PLACE_BET_MULTIPLE_ERROR_CAPTION').value : $translate('WEB2_PLACE_BET_ERROR_CAPTION').value;
            default:
                return '';
        }
    });
    const descriptionText = computed(()=>{
        const defaultErrorMessage = props.isMultiSinglesMode ? $translate('WEB2_PLACE_BET_MULTIPLE_ERROR_DESCRIPTION').value : $translate('WEB2_PLACE_BET_ERROR_DESCRIPTION').value;
        if (isPartiallyAcceptedByLimits.value) return $translate('WEB2_PLACE_BET_MULTIPLE_BET_LIMITS').value;
        switch(props.mode){
            case BetSlipResultState.SUCCESS:
                return props.isMultiSinglesMode ? $translate('WEB2_PLACE_BET_OK_DESCRIPTION_MULTIPLE').value : $translate('WEB2_PLACE_BET_OK_DESCRIPTION').value;
            case BetSlipResultState.ERROR:
                return props.errorMessage || defaultErrorMessage;
            default:
                return '';
        }
    });
    const isLimitsButtonShown = computed(()=>!!(props.limitsExceeded && isError.value));
    const isCloseAndClearButtonShown = computed(()=>{
        if (isPartiallyAcceptedByLimits.value) return true;
        return !!(props.isMultiSinglesMode && isPartiallyAccepted.value && isSuccess.value);
    });
    const mainButtonLabel = computed(()=>{
        if (isSuccess.value) return isPartiallyAccepted.value ? $translate('WEB2_SLIP_SAVE_BETS_UNACCEPTED').value : $translate('WEB2_DONE').value;
        return $translate('WEB2_PLACE_BET_BUTTON_ERROR').value;
    });
    const mainButtonIcon = computed(()=>{
        if (isSuccess.value && !isPartiallyAccepted.value) return IconName.CHECK_FILLED;
    });
    const shareButtonVisible = computed(()=>{
        if (!props.isShareAvailable || props.isMultiSinglesMode) return false;
        return isSuccess.value && !props.limitsExceeded;
    });
    const goToMyBets = ()=>{
        emit('go-to-my-bets');
    };
    const emitLeaveClick = (choice)=>{
        emit('leave-click', choice);
    };
    const onMainButtonClick = ()=>{
        if (isError.value) {
            emit('close-on-error');
            return;
        }
        emitLeaveClick(isPartiallyAccepted.value ? LeaveSlipResultChoice.SAVE_UNACCEPTED : LeaveSlipResultChoice.CLEAR_ALL);
    };
    const onSaveAllClick = ()=>{
        emitLeaveClick(LeaveSlipResultChoice.SAVE_ALL);
    };
    const onCloseAndClearClick = ()=>{
        emitLeaveClick(LeaveSlipResultChoice.CLEAR_ALL);
    };
    const onLimitsClick = ()=>{
        emit('limits-click');
    };
    const onShareClick = ()=>{
        emit('share-bet');
    };
    return {
        isContentVisible,
        isPartiallyAccepted,
        iconName,
        headingText,
        descriptionText,
        isCompleted,
        isSuccess,
        isLimitsButtonShown,
        mainButtonLabel,
        mainButtonIcon,
        isPartiallyAcceptedByLimits,
        isCloseAndClearButtonShown,
        shareButtonVisible,
        goToMyBets,
        onMainButtonClick,
        onSaveAllClick,
        onCloseAndClearClick,
        onLimitsClick,
        onShareClick
    };
}
