import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, mergeProps as _mergeProps, withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import { NumberStringInput } from 'web/src/components/Input/components';
import useBetSlipEditFastBet from './composables/useBetSlipEditFastBet';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipEditFastBet',
    props: {
        maxEnabled: {
            type: Boolean
        },
        changesIsSaved: {
            type: Boolean
        },
        fastBets: {
            default: ()=>[]
        },
        minFastBet: {},
        maxFastBet: {},
        useStandardBet: {
            type: Boolean
        },
        minStandardBet: {},
        maxStandardBet: {},
        standardBetAmount: {},
        standardBetAmountIsSaved: {
            type: Boolean
        },
        standardBetInputErrorKind: {}
    },
    emits: [
        "fast-bets-submit",
        "fast-bets-blur",
        "fast-bets-input",
        "switch-max-enabled",
        "toggle-use-standard-bet",
        "standard-sum-focus",
        "standard-sum-blur",
        "standard-sum-input"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emit = __emit;
        const { standardSumProps, computedItems, onFastBetsBlur, onFastBetsInput, onStandardSumInput, onSwitchMaxEnabled } = useBetSlipEditFastBet(props, emit);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['edit-fast-bet'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['edit-fast-bet__default-sum'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['edit-fast-bet__checkbox'])
                    }, [
                        _createVNode(VSwitch, {
                            checked: _ctx.useStandardBet,
                            label: _ctx.$t('WEB2_SLIP_SETTINGS_STANDARD_BET_CHECKBOX'),
                            onChange: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('toggle-use-standard-bet'))
                        }, null, 8, [
                            "checked",
                            "label"
                        ]),
                        _ctx.useStandardBet ? (_openBlock(), _createElementBlock("form", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['edit-fast-bet__default-sum-form']),
                            onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event)=>_ctx.$emit('standard-sum-blur'), [
                                "prevent"
                            ]))
                        }, [
                            _createVNode(_unref(NumberStringInput), _mergeProps(_unref(standardSumProps), {
                                "is-slip-style": "",
                                onFocus: _cache[1] || (_cache[1] = ($event)=>_ctx.$emit('standard-sum-focus')),
                                onBlur: _cache[2] || (_cache[2] = ($event)=>_ctx.$emit('standard-sum-blur')),
                                onInput: _unref(onStandardSumInput),
                                onChange: _unref(onStandardSumInput)
                            }), null, 16, [
                                "onInput",
                                "onChange"
                            ])
                        ], 34)) : (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(_ctx.$style['edit-fast-bet__default-sum-info'])
                        }, _toDisplayString(_ctx.$t('WEB2_SLIP_SETTINGS_STANDARD_BET_INFO')), 3))
                    ], 2)
                ], 2),
                _createElementVNode("form", {
                    class: _normalizeClass(_ctx.$style['edit-fast-bet__form']),
                    onSubmit: _cache[4] || (_cache[4] = _withModifiers(($event)=>_ctx.$emit('fast-bets-submit'), [
                        "prevent"
                    ]))
                }, [
                    _createElementVNode("p", {
                        class: _normalizeClass(_ctx.$style['edit-fast-bet__caption'])
                    }, _toDisplayString(_ctx.$t('WEB2_SLIP_SETTINGS_FAST_BETS_CAPTION')), 3),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(computedItems), (item, index)=>(_openBlock(), _createElementBlock("div", {
                            key: item.name,
                            class: _normalizeClass(_ctx.$style['edit-fast-bet__row'])
                        }, [
                            _createVNode(_unref(NumberStringInput), _mergeProps({
                                ref_for: true
                            }, item, {
                                "is-slip-style": "",
                                onBlur: ($event)=>_unref(onFastBetsBlur)($event, index),
                                onInput: ($event)=>_unref(onFastBetsInput)($event, index),
                                onChange: ($event)=>_unref(onFastBetsInput)($event, index)
                            }), null, 16, [
                                "onBlur",
                                "onInput",
                                "onChange"
                            ])
                        ], 2))), 128)),
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['edit-fast-bet__toggle'])
                    }, [
                        _createVNode(VSwitch, {
                            checked: _ctx.maxEnabled,
                            label: `${_ctx.$t('WEB2_FAST_BET_SWITCH_LABEL')} MAX`,
                            class: _normalizeClass(_ctx.$style['edit-fast-bet__switch']),
                            name: "showMaxSwitch",
                            onChange: _unref(onSwitchMaxEnabled)
                        }, null, 8, [
                            "checked",
                            "label",
                            "class",
                            "onChange"
                        ]),
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['edit-fast-bet__switch-hint'])
                        }, [
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['edit-fast-bet__max'])
                            }, "MAX", 2),
                            _createTextVNode(" " + _toDisplayString(_ctx.$t('WEB2_FAST_BET_SWITCH_HINT')), 1)
                        ], 2)
                    ], 2),
                    _cache[5] || (_cache[5] = _createElementVNode("input", {
                        type: "submit",
                        hidden: ""
                    }, null, -1))
                ], 34)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetSlipEditFastBet'
                ]
            ]);
        };
    }
});
