import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { convertSlipEntryIdToObject } from '../../../utils';
const useSlipSelectedEntriesStore = defineStore('slip-selected-entries', ()=>{
    /** keep events ids as source of truth during the updates */ const selectedEntriesIds = ref([]);
    const selectedEventsInfo = computed(()=>selectedEntriesIds.value.map((id)=>convertSlipEntryIdToObject(id)));
    function addToSelectedEntriesIds(id) {
        if (selectedEntriesIds.value.includes(id)) return;
        selectedEntriesIds.value = [
            ...selectedEntriesIds.value,
            id
        ];
    }
    function removeFromSelectedEntriesIds(id) {
        selectedEntriesIds.value = selectedEntriesIds.value.filter((item)=>item !== id);
    }
    function setSelectedEntriesIds(ids) {
        selectedEntriesIds.value = ids;
    }
    return {
        selectedEntriesIds,
        selectedEventsInfo,
        addToSelectedEntriesIds,
        removeFromSelectedEntriesIds,
        setSelectedEntriesIds
    };
});
export default useSlipSelectedEntriesStore;
