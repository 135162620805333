import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted } from 'vue';
import BetSlipMyBets from '../components/BetSlipMyBets';
import { useMyBetsView } from '../composable';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipMyBetsView',
    setup (__props) {
        const { myBetsList, pendingBetsCount, loadPendingBets, loadMorePendingBets, isPendingBetsLoaded, isPendingBetsLoadingMore, cashoutClick, historyLinkClick, myBetsItemClick, historyUrl } = useMyBetsView();
        onMounted(loadPendingBets);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(BetSlipMyBets), {
                list: _unref(myBetsList),
                "is-loaded": _unref(isPendingBetsLoaded),
                "is-loading-more": _unref(isPendingBetsLoadingMore),
                "placed-bets-count": _unref(pendingBetsCount),
                "history-url": _unref(historyUrl),
                onLoadMore: _unref(loadMorePendingBets),
                onCashOut: _unref(cashoutClick),
                onHistoryLinkClick: _unref(historyLinkClick),
                onItemClick: _unref(myBetsItemClick)
            }, null, 8, [
                "list",
                "is-loaded",
                "is-loading-more",
                "placed-bets-count",
                "history-url",
                "onLoadMore",
                "onCashOut",
                "onHistoryLinkClick",
                "onItemClick"
            ])), [
                [
                    _directive_auto_id,
                    'BetSlipMyBetsView'
                ]
            ]);
        };
    }
});
