import { computed, ref, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { Timer } from '@leon-hub/utils';
import RouteName from '@leon-hub/routing-config';
import { HelpButtonMode } from '@leon-hub/api-sdk';
import { useAnalytics } from 'web/src/modules/analytics/composables';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useCashoutStore } from 'web/src/modules/cashout/store';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFastBetsValues } from 'web/src/modules/fast-bets/composables';
import { useCurrencyStore } from 'web/src/modules/money/store';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { usePlaceBetStore } from '../submodules/place-bet/store';
import { usePendingBetsStore } from '../submodules/pending-bets/store';
import useSlipUserSettings from '../submodules/slip-user-settings/store/useSlipUserSettings';
import { BetSlipResultState, SettingsTabs, TopLevelTabs } from '../enums';
import { useSlipRootStore } from '../submodules/slip-root/store';
import { useSlipViewSettingsStore } from '../submodules/slip-view-settings/store';
import { useStakeInputStore } from '../submodules/stake-input/store';
import { useSlipInfoStore } from '../submodules/slip-info/store';
import { useMultiSinglesStore } from '../submodules/multi-singles/store';
// BetSlipContainer related
// BetSlipView related
// loader and empty placeholder
// BetSlipMain
// SlipResult
// life-cycle
export default function useBetSlipRoot() {
    const { $translate } = useI18n();
    const router = useRouter();
    const slipRootStore = useSlipRootStore();
    const slipViewSettingsStore = useSlipViewSettingsStore();
    const slipSettingsStore = useSlipUserSettings();
    const pendingBetsStore = usePendingBetsStore();
    const { isLoggedIn } = useIsLoggedIn();
    useAnalytics();
    const cashoutStore = useCashoutStore();
    const stakeInputStore = useStakeInputStore();
    const slipInfoStore = useSlipInfoStore();
    const { handleDisplayAnimationEnd, handleSlipClose, clearBetList, switchToMyBets } = slipRootStore;
    const { setUpdateLockStatus, acceptSinglePriceChanges, selectBetMode, removeBetClick, toggleBanker } = slipInfoStore;
    const multiSinglesStore = useMultiSinglesStore();
    const { updateStakeValueInMultiSingles, onMultiSinglesInputFocus, onMultiSinglesInputBlur, handleSinglesMobileInput } = multiSinglesStore;
    const placeBetStore = usePlaceBetStore();
    const { handleSlipResultLeave, resetResultErrorState, onLimitsButtonClick } = placeBetStore;
    const resultState = toRef(placeBetStore, 'resultState');
    const placeBetErrorText = toRef(placeBetStore, 'placeBetErrorText');
    const limitsExceeded = toRef(placeBetStore, 'limitsExceeded');
    const totalEntriesInSlip = toRef(placeBetStore, 'totalEntriesInSlip');
    const totalBetsAccepted = toRef(placeBetStore, 'totalBetsAccepted');
    // container
    toRef(slipViewSettingsStore, 'isHiddenOnMobile');
    toRef(slipViewSettingsStore, 'isFullHeight');
    const activeTopTabId = toRef(slipViewSettingsStore, 'activeTopTabId');
    /** prop to keep height of mobile slip if user changing tabs. Can't relay on betMode coz
     * it will lead to another bugs */ const lastManuallySelectedTab = ref('');
    const safeActiveTopTab = computed(()=>{
        if (!isLoggedIn.value) return TopLevelTabs.SLIP;
        return activeTopTabId.value;
    });
    computed(()=>resultState.value === BetSlipResultState.PENDING || resultState.value === BetSlipResultState.WAIT_FOR_RETRY);
    const isCurrentlyScrolling = ref(false);
    const setCurrentlyScrolling = (isScrolling)=>{
        isCurrentlyScrolling.value = isScrolling;
    };
    let scrollingTimeoutId = 0;
    const containerProps = computed(()=>{
        "1";
        return {};
    });
    const onManualClose = (closingType)=>{
        handleSlipClose();
    };
    // BetSlipView related
    const isSettingsVisible = toRef(slipViewSettingsStore, 'isSettingsVisible');
    const isClearBetListWarnVisible = toRef(slipViewSettingsStore, 'isClearBetListWarnVisible');
    const activeSettingsTabId = toRef(slipSettingsStore, 'activeSettingsTabId');
    const pendingBetsCount = toRef(pendingBetsStore, 'pendingBetsCount');
    const slipCashoutInProgress = toRef(cashoutStore, 'slipCashoutInProgress');
    const isMobileKeyboardShown = toRef(stakeInputStore, 'isMobileKeyboardShown');
    const displayedSlipEventsCount = toRef(slipInfoStore, 'displayedSlipEventsCount');
    const supportBlock = toRef(useSiteConfigStore(), 'supportBlock');
    const isDesktopHelpButtonEnabled = computed(()=>supportBlock.value?.helpButtonMode === HelpButtonMode.HELP);
    const slipLayoutProps = computed(()=>({
            isLogged: isLoggedIn.value,
            activeTopTabId: safeActiveTopTab.value,
            isSettingsVisible: isSettingsVisible.value,
            slipSize: displayedSlipEventsCount.value,
            myBetsCount: pendingBetsCount.value,
            resultState: resultState.value,
            activeSettingsTabId: activeSettingsTabId.value,
            cashoutInProgress: slipCashoutInProgress.value,
            showMobileKeyboard: isMobileKeyboardShown.value,
            showHelpButton: isDesktopHelpButtonEnabled.value,
            clearBetListWarnVisible: isClearBetListWarnVisible.value
        }));
    const { selectTopTab, showSlipSettings, setClearBetListWarnVisibility } = slipViewSettingsStore;
    const onClearBetsCancel = ()=>{
        setClearBetListWarnVisibility(false);
    };
    const handleInnerScroll = ()=>{
        Timer.clearTimeout(scrollingTimeoutId);
        setCurrentlyScrolling(true);
        setUpdateLockStatus(true);
        scrollingTimeoutId = Timer.setTimeout(()=>{
            setCurrentlyScrolling(false);
            setUpdateLockStatus(false);
        }, 300);
    };
    const { selectSettingsTab } = slipSettingsStore;
    // loader and empty placeholder
    const isRestoringSlipFromLS = toRef(slipInfoStore, 'restoringSlipFromLS');
    const slipEventsCount = toRef(slipInfoStore, 'slipEventsCount');
    const isEmpty = computed(()=>!isRestoringSlipFromLS.value && !slipEventsCount.value);
    const isEmptySlipButtonHidden = computed(()=>!isLoggedIn.value);
    const emptySlipButtonLabel = computed(()=>isLoggedIn.value ? $translate('JS_SLIP_BET_SETTINGS').value : $translate('JSPNAV_BETSLIP_HOWTOPLACE_BET').value);
    const emptySlipButtonClick = ()=>{
        if (isLoggedIn.value) showSlipSettings();
        else {
            handleSlipClose();
            router.push({
                name: RouteName.CMS_HOW_TO_BET
            });
        }
    };
    // BetSlipMain
    const currencyStore = useCurrencyStore();
    const currencySymbol = toRef(currencyStore, 'formattedCurrency');
    const { fastBetsOptions, fastBetsShowMax, isFastBetsEnabled } = useFastBetsValues();
    const stakeInputValidationTimeout = toRef(stakeInputStore, 'stakeInputValidationTimeout');
    const betEvents = toRef(slipInfoStore, 'betEvents');
    const singleModeAvailable = toRef(slipInfoStore, 'singleModeAvailable');
    const expressModeAvailable = toRef(slipInfoStore, 'expressModeAvailable');
    const systemModeAvailable = toRef(slipInfoStore, 'systemModeAvailable');
    const bankersAvailable = toRef(slipInfoStore, 'bankersAvailable');
    const isMultiSinglesMode = toRef(slipInfoStore, 'isMultiSinglesMode');
    const betMode = toRef(slipInfoStore, 'betMode');
    const sameStakeForSingles = toRef(multiSinglesStore, 'sameStakeForSingles');
    const hideInputErrorsId = toRef(multiSinglesStore, 'hideInputErrorsId');
    const lastFocusedItemId = toRef(multiSinglesStore, 'lastFocusedItemId');
    const focusedItemId = toRef(multiSinglesStore, 'focusedItemId');
    const betSlipMainProps = computed(()=>({
            isHidden: false,
            betMode: betMode.value,
            singleModeDisabled: !singleModeAvailable.value,
            expressModeDisabled: !expressModeAvailable.value,
            systemModeDisabled: !systemModeAvailable.value,
            betEvents: betEvents.value,
            bankersAvailable: bankersAvailable.value,
            fastBetsItems: fastBetsOptions.value,
            isLoggedIn: isLoggedIn.value,
            maxFastBetEnabled: fastBetsShowMax.value,
            stakeInputValidationTimeout: stakeInputValidationTimeout.value,
            sameStakeForSingles: sameStakeForSingles.value,
            isMultiSinglesMode: isMultiSinglesMode.value,
            isFastBetsEnabled: isFastBetsEnabled.value,
            currencySymbol: currencySymbol.value,
            showStakeInput: isMultiSinglesMode.value && !sameStakeForSingles.value,
            hideInputErrorsId: hideInputErrorsId.value ?? void 0,
            lastFocusedItemId: lastFocusedItemId.value ?? void 0,
            focusedItemId: focusedItemId.value ?? void 0
        }));
    const onSelectBetMode = (mode)=>{
        selectBetMode(mode);
        lastManuallySelectedTab.value = mode;
    };
    const editFastBets = ()=>{
        selectSettingsTab(SettingsTabs.FAST_BETS);
        showSlipSettings();
    };
    // SlipResult
    const resultViewProps = computed(()=>({
            mode: resultState.value,
            errorMessage: placeBetErrorText.value,
            limitsExceeded: limitsExceeded.value,
            totalBetsPlaced: totalEntriesInSlip.value,
            totalBetsAccepted: totalBetsAccepted.value,
            isMultiSinglesMode: isMultiSinglesMode.value
        }));
    const handleLimitsClick = ()=>{
        onLimitsButtonClick();
        router.push({
            name: RouteName.RESPONSIBLE_GAMBLING_BETTING_LIMITS
        });
    };
    // life-cycle related
    const onDisappear = ()=>{
        Timer.clearTimeout(scrollingTimeoutId);
        isCurrentlyScrolling.value = false;
    };
    return {
        // BetSlipContainer related
        containerProps,
        onDisplayAnimationEnd: handleDisplayAnimationEnd,
        onManualClose,
        // SlipLayout related
        slipLayoutProps,
        isClearBetListWarnVisible,
        selectTopTab,
        showSlipSettings,
        onClearBetsCancel,
        onClearBetsConfirm: clearBetList,
        handleInnerScroll,
        // loader and empty placeholder
        isRestoringSlipFromLS,
        isEmpty,
        isEmptySlipButtonHidden,
        emptySlipButtonLabel,
        emptySlipButtonClick,
        // BetSlipMain
        betSlipMainProps,
        onSelectBetMode,
        onEventDeleteClick: removeBetClick,
        onBankerClick: toggleBanker,
        onSingleStakeInput: updateStakeValueInMultiSingles,
        onMultiSinglesInputFocus,
        onMultiSinglesInputBlur,
        onAcceptSinglePriceChanges: acceptSinglePriceChanges,
        editFastBets,
        handleSinglesMobileInput,
        // SlipResult
        resultViewProps,
        switchToMyBets,
        handleSlipResultLeave,
        handleLimitsClick,
        resetResultErrorState,
        // life-cycle related
        onDisappear
    };
}
