import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { provide } from 'vue';
import { ButtonKind, ButtonType } from '@leon-hub/module-buttons';
import { TextInput } from 'web/src/components/Input';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { FormProvidable } from 'web/src/components/Form/enums';
import { useBookingCodeForm } from './composables/useBookingCodeForm';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipBookingCodeForm',
    props: {
        error: {},
        isPending: {
            type: Boolean
        },
        placeholder: {},
        value: {}
    },
    emits: [
        "submit-code",
        "input-code"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        provide(FormProvidable.FormContext, {
            isFromModal: true
        });
        const { onSubmit, onInput, onClear } = useBookingCodeForm(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("form", {
                class: _normalizeClass(_ctx.$style['booking-code-form']),
                onSubmit: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                function() {
                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                        args[_key] = arguments[_key];
                    }
                    return _unref(onSubmit) && _unref(onSubmit)(...args);
                }, [
                    "stop",
                    "prevent"
                ]))
            }, [
                _createVNode(_unref(TextInput), {
                    label: _ctx.$t('WEB2_BET_SHARE_BOOKING_CODE'),
                    placeholder: _ctx.placeholder,
                    error: _ctx.error,
                    value: _ctx.value,
                    regexp: "^[0-9a-zA-Z\\s]*$",
                    onInput: _unref(onInput),
                    onClear: _unref(onClear)
                }, null, 8, [
                    "label",
                    "placeholder",
                    "error",
                    "value",
                    "onInput",
                    "onClear"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['booking-code-form__footer'])
                }, [
                    _createVNode(VButton, {
                        disabled: !_ctx.value,
                        type: _unref(ButtonType).SUBMIT,
                        label: _ctx.$t('WEB2_BET_SHARE_LOAD_SLIP'),
                        kind: _unref(ButtonKind).PRIMARY,
                        "is-loading": _ctx.isPending,
                        "full-width": "",
                        onClick: _unref(onSubmit)
                    }, null, 8, [
                        "disabled",
                        "type",
                        "label",
                        "kind",
                        "is-loading",
                        "onClick"
                    ])
                ], 2)
            ], 34)), [
                [
                    _directive_auto_id,
                    'BetSlipBookingCodeForm'
                ]
            ]);
        };
    }
});
