import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, mergeProps as _mergeProps, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { defineAsyncComponent, onBeforeUnmount } from 'vue';
import { VLoaderMargin } from '@components/loader';
import { CashoutView } from 'web/src/modules/cashout/views';
import { BannerLocation } from 'web/src/modules/banners/enums';
import { VEmpty } from 'web/src/components/Empty';
import ModalPortal from 'web/src/components/Modal/ModalPortal/ModalPortal.vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import { BannerType } from 'web/src/components/HeadlineBanner/VHeadlineBanner/enums';
import { ModalSelector } from 'web/src/modules/core/enums';
import { useTheme } from 'web/src/modules/theme/composables';
import { BetSlipConfirmClearOverlay } from '../components/BetSlipConfirmClearOverlay';
import BetSlipMain from '../components/BetSlipMain';
import BetSlipResult from '../components/BetSlipResult';
import BetSlipEmptyPlaceholder from '../components/BetSlipEmptyPlaceholder';
import AccumulatorBoostInfo from '../components/AccumulatorBoostInfo';
import WinBoostInfo from '../components/WinBoostInfo';
import BetSlipBookingCodeForm from '../components/BetSlipBookingCodeForm';
import BookingCodeLoadResult from '../components/BookingCodeLoadResult';
import { useBetSlipRoot, useAccumulatorBoost, useSlipWarningDialog, useSlipTrace } from '../composable';
import { useGetSharedSlip } from '../submodules/get-shared-slip/composables';
import { useSlipShare } from '../submodules/slip-share/composables';
import BetSlipMyBetsView from './BetSlipMyBetsView.vue';
import BetSlipFooter from './BetSlipFooter.vue';
import BetSlipContainer from '../components/SlipContainer';
import SlipLayout from '../components/SlipLayout';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TheBetsSlipRootView',
    setup (__props) {
        const MainBannerSectionRouteComponent = require('web/src/modules/banners/components/MainBannerSectionRouteComponent').LazyMainBannerSectionRouteComponent;
        const BetSlipTraceModal = defineAsyncComponent(()=>import('../components/BetSlipTrace'));
        const SlipSettingsView = defineAsyncComponent(()=>import('./BetSlipSettings.vue'));
        const { // BetSlipContainer related
        containerProps, onDisplayAnimationEnd, onManualClose, // BetSlipView related
        slipLayoutProps, isClearBetListWarnVisible, selectTopTab, onClearBetsCancel, onClearBetsConfirm, handleInnerScroll, // loader and empty placeholder
        isRestoringSlipFromLS, isEmpty, isEmptySlipButtonHidden, emptySlipButtonLabel, emptySlipButtonClick, // BetSlipMain
        betSlipMainProps, onSelectBetMode, onEventDeleteClick, onBankerClick, onSingleStakeInput, onMultiSinglesInputFocus, onMultiSinglesInputBlur, onAcceptSinglePriceChanges, editFastBets, handleSinglesMobileInput, // SlipResult
        resultViewProps, switchToMyBets, handleSlipResultLeave, handleLimitsClick, resetResultErrorState, // life-cycle related
        onDisappear } = useBetSlipRoot();
        useSlipWarningDialog();
        const { showAccumulatorBoost, currentMultiplier, nextMultiplier, maxMultiplier, requiredOdds, itemsToNextBoost, isWinBoost, winBoostValue } = useAccumulatorBoost();
        const { isTraceModalVisible, currentTraceLines, showTraceButton, showTraceModal, hideTraceModal } = useSlipTrace();
        const { isCustomerBetShareEnabled, shareLastBet } = useSlipShare();
        const { isReceiveFromCodeEnabled, isWaitingToSlipFromCode, bookingCodeError, isSlipFromBookingCodeOverlayVisible, isAllSharedEventsExpired, isLoggedIn, sharedBookingCode, isBookingCodeFromLink, getSlipFromBookingCode, onBookingCodeInput, onOverlayButtonClick, resetBookingCodeForm } = useGetSharedSlip();
        const { isLight } = useTheme();
        onBeforeUnmount(onDisappear);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_unref(BetSlipContainer), _mergeProps(_unref(containerProps), {
                onDisplayAnimationEnd: _unref(onDisplayAnimationEnd),
                onHandleClose: _unref(onManualClose)
            }), {
                default: _withCtx(()=>[
                        _createVNode(_unref(SlipLayout), _mergeProps(_unref(slipLayoutProps), {
                            "show-booking-overlay": _unref(isSlipFromBookingCodeOverlayVisible),
                            onSelectTopTab: _unref(selectTopTab),
                            onScroll: _unref(handleInnerScroll)
                        }), _createSlots({
                            main: _withCtx(()=>[
                                    _unref(isRestoringSlipFromLS) ? (_openBlock(), _createBlock(_unref(VLoaderDelayed), {
                                        key: 0,
                                        margin: _unref(VLoaderMargin).MEDIUM
                                    }, null, 8, [
                                        "margin"
                                    ])) : _unref(isEmpty) ? (_openBlock(), _createBlock(_unref(BetSlipEmptyPlaceholder), {
                                        key: 1,
                                        "hide-button": _unref(isEmptySlipButtonHidden),
                                        "have-button-in-slot": _unref(isReceiveFromCodeEnabled),
                                        "button-label": _unref(emptySlipButtonLabel),
                                        onButtonClick: _unref(emptySlipButtonClick)
                                    }, {
                                        default: _withCtx(()=>[
                                                _unref(isReceiveFromCodeEnabled) ? (_openBlock(), _createBlock(_unref(BetSlipBookingCodeForm), {
                                                    key: 0,
                                                    "is-pending": _unref(isWaitingToSlipFromCode),
                                                    error: _unref(bookingCodeError),
                                                    value: _unref(sharedBookingCode),
                                                    onSubmitCode: _unref(getSlipFromBookingCode),
                                                    onInputCode: _unref(onBookingCodeInput)
                                                }, null, 8, [
                                                    "is-pending",
                                                    "error",
                                                    "value",
                                                    "onSubmitCode",
                                                    "onInputCode"
                                                ])) : _createCommentVNode("", true)
                                            ]),
                                        _: 1
                                    }, 8, [
                                        "hide-button",
                                        "have-button-in-slot",
                                        "button-label",
                                        "onButtonClick"
                                    ])) : (_openBlock(), _createElementBlock(_Fragment, {
                                        key: 2
                                    }, [
                                        _createVNode(_unref(BetSlipMain), _mergeProps(_unref(betSlipMainProps), {
                                            "show-trace-button": _unref(showTraceButton),
                                            onSelectBetMode: _unref(onSelectBetMode),
                                            onEventDeleteClick: _unref(onEventDeleteClick),
                                            onEventBankerClick: _unref(onBankerClick),
                                            onStakeInput: _unref(onSingleStakeInput),
                                            onStakeInputFocus: _unref(onMultiSinglesInputFocus),
                                            onStakeInputBlur: _unref(onMultiSinglesInputBlur),
                                            onEventAcceptChanges: _unref(onAcceptSinglePriceChanges),
                                            onTraceClick: _unref(showTraceModal),
                                            onEditFastBets: _unref(editFastBets),
                                            onSinglesMobileInput: _unref(handleSinglesMobileInput)
                                        }), null, 16, [
                                            "show-trace-button",
                                            "onSelectBetMode",
                                            "onEventDeleteClick",
                                            "onEventBankerClick",
                                            "onStakeInput",
                                            "onStakeInputFocus",
                                            "onStakeInputBlur",
                                            "onEventAcceptChanges",
                                            "onTraceClick",
                                            "onEditFastBets",
                                            "onSinglesMobileInput"
                                        ]),
                                        _unref(showAccumulatorBoost) ? (_openBlock(), _createElementBlock(_Fragment, {
                                            key: 0
                                        }, [
                                            _unref(isWinBoost) ? (_openBlock(), _createBlock(_unref(WinBoostInfo), {
                                                key: 0,
                                                "required-odds": _unref(requiredOdds),
                                                "current-boost": _unref(currentMultiplier),
                                                "next-boost": _unref(nextMultiplier),
                                                "items-to-next-boost": _unref(itemsToNextBoost),
                                                "current-win-bonus": _unref(winBoostValue) ?? void 0
                                            }, null, 8, [
                                                "required-odds",
                                                "current-boost",
                                                "next-boost",
                                                "items-to-next-boost",
                                                "current-win-bonus"
                                            ])) : (_openBlock(), _createBlock(_unref(AccumulatorBoostInfo), {
                                                key: 1,
                                                "current-multiplier": _unref(currentMultiplier),
                                                "next-multiplier": _unref(nextMultiplier),
                                                "max-multiplier": _unref(maxMultiplier),
                                                "required-odds": _unref(requiredOdds),
                                                "items-to-next-boost": _unref(itemsToNextBoost)
                                            }, null, 8, [
                                                "current-multiplier",
                                                "next-multiplier",
                                                "max-multiplier",
                                                "required-odds",
                                                "items-to-next-boost"
                                            ]))
                                        ], 64)) : _createCommentVNode("", true),
                                        _createVNode(BetSlipFooter)
                                    ], 64))
                                ]),
                            "my-bets": _withCtx(()=>[
                                    _createVNode(BetSlipMyBetsView)
                                ]),
                            settings: _withCtx(()=>[
                                    _createVNode(_unref(SlipSettingsView), {
                                        onScroll: _unref(handleInnerScroll)
                                    }, null, 8, [
                                        "onScroll"
                                    ])
                                ]),
                            result: _withCtx(()=>[
                                    _unref(isSlipFromBookingCodeOverlayVisible) ? (_openBlock(), _createBlock(_unref(BookingCodeLoadResult), {
                                        key: 0,
                                        "is-all-events-expired": _unref(isAllSharedEventsExpired),
                                        "is-light-theme": _unref(isLight),
                                        "is-logged-in": _unref(isLoggedIn),
                                        "is-from-link": _unref(isBookingCodeFromLink),
                                        onVnodeBeforeUnmount: _unref(resetBookingCodeForm),
                                        onButtonClick: _unref(onOverlayButtonClick)
                                    }, null, 8, [
                                        "is-all-events-expired",
                                        "is-light-theme",
                                        "is-logged-in",
                                        "is-from-link",
                                        "onVnodeBeforeUnmount",
                                        "onButtonClick"
                                    ])) : (_openBlock(), _createBlock(_unref(BetSlipResult), _mergeProps({
                                        key: 1
                                    }, _unref(resultViewProps), {
                                        "is-share-available": _unref(isCustomerBetShareEnabled),
                                        onGoToMyBets: _unref(switchToMyBets),
                                        onLeaveClick: _unref(handleSlipResultLeave),
                                        onLimitsClick: _unref(handleLimitsClick),
                                        onCloseOnError: _unref(resetResultErrorState),
                                        onShareBet: _unref(shareLastBet)
                                    }), null, 16, [
                                        "is-share-available",
                                        "onGoToMyBets",
                                        "onLeaveClick",
                                        "onLimitsClick",
                                        "onCloseOnError",
                                        "onShareBet"
                                    ]))
                                ]),
                            "confirm-overlay": _withCtx(()=>[
                                    _unref(isClearBetListWarnVisible) ? (_openBlock(), _createBlock(_unref(BetSlipConfirmClearOverlay), {
                                        key: 0,
                                        "data-test-id": "confirm-overlay",
                                        onConfirm: _unref(onClearBetsConfirm),
                                        onCancel: _unref(onClearBetsCancel)
                                    }, null, 8, [
                                        "onConfirm",
                                        "onCancel"
                                    ])) : _createCommentVNode("", true)
                                ]),
                            cashout: _withCtx(()=>[
                                    _createVNode(_unref(CashoutView))
                                ]),
                            _: 2
                        }, [
                            {
                                name: "banners",
                                fn: _withCtx(()=>[
                                        _createVNode(_unref(MainBannerSectionRouteComponent), {
                                            "is-static": "",
                                            location: _unref(BannerLocation).RIGHT,
                                            "banner-type": _unref(BannerType).ASIDE_BANNER
                                        }, null, 8, [
                                            "location",
                                            "banner-type"
                                        ])
                                    ]),
                                key: "0"
                            }
                        ]), 1040, [
                            "show-booking-overlay",
                            "onSelectTopTab",
                            "onScroll"
                        ]),
                        (_openBlock(), _createElementBlock(_Fragment, {
                            key: 0
                        }, [
                            _unref(isTraceModalVisible) ? (_openBlock(), _createBlock(ModalPortal, {
                                key: 0,
                                selector: _unref(ModalSelector).BODY
                            }, {
                                default: _withCtx(()=>[
                                        _createVNode(_unref(BetSlipTraceModal), {
                                            lines: _unref(currentTraceLines),
                                            onCloseModal: _unref(hideTraceModal)
                                        }, null, 8, [
                                            "lines",
                                            "onCloseModal"
                                        ])
                                    ]),
                                _: 1
                            }, 8, [
                                "selector"
                            ])) : _createCommentVNode("", true)
                        ], 64))
                    ]),
                _: 1
            }, 16, [
                "onDisplayAnimationEnd",
                "onHandleClose"
            ])), [
                [
                    _directive_auto_id,
                    'TheBetsSlipRootView'
                ]
            ]);
        };
    }
});
