import validateMultiSinglesMetaStake from './validateMultiSinglesMetaStake';
export default function getUpdatedMultiSinglesStakeValue(param) {
    let { value, id, source, isFastBetValue, formatMoney } = param;
    const matchedRecord = source[id];
    if (!matchedRecord) return {
        ...source
    };
    const copy = {
        ...source
    };
    copy[id] = validateMultiSinglesMetaStake({
        record: matchedRecord,
        newStakeValue: value,
        fastBetValue: isFastBetValue ? value : null,
        formatMoney
    });
    return copy;
}
