import { computed } from 'vue';
import { isNumber } from '@leon-hub/guards';
import { BetInputErrorKind } from 'web/src/modules/fast-bets/enums';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useI18n } from 'web/src/modules/i18n/composables';
export default function useBetSlipEditFastBet(props, emit) {
    const { $translate } = useI18n();
    const formatMoney = useFormatMoney();
    const getErrorMessage = (param)=>{
        let { error, maxValue, minValue } = param;
        switch(error){
            case BetInputErrorKind.INVALID_INPUT:
                return $translate('WEB2_FAST_BET_INCORRECT_INPUT').value;
            case BetInputErrorKind.ABOVE_MAXIMUM:
                return `${$translate('WEB2_FAST_BET_ABOVE_MAX').value} ${formatMoney(maxValue || 0)}`;
            case BetInputErrorKind.BELOW_MINIMUM:
                return `${$translate('WEB2_FAST_BET_BELOW_MIN').value} ${formatMoney(minValue || 0)}`;
            default:
                return '';
        }
    };
    const getLimitsLabel = (min, max)=>{
        const from = $translate('WEB2_FROM').value;
        const till = $translate('JSPBET_TILL').value;
        const minLabel = formatMoney(min, {
            hideCurrency: !!max
        });
        const maxLabel = max ? ` ${till} ${formatMoney(max)}` : '';
        return `${from} ${minLabel}${maxLabel}`;
    };
    const standardSumProps = computed(()=>({
            name: 'standardSum',
            label: $translate('WEB2_SLIP_SETTINGS_STANDARD_SUM_LABEL').value,
            value: isNumber(props.standardBetAmount) ? `${props.standardBetAmount}` : '',
            error: props.standardBetInputErrorKind ? getErrorMessage({
                error: props.standardBetInputErrorKind,
                minValue: props.minStandardBet,
                maxValue: props.maxStandardBet
            }) : void 0,
            isSaved: props.standardBetAmountIsSaved,
            placeholder: $translate('WEB2_SLIP_SETTINGS_STANDARD_SUM_PLACEHOLDER').value,
            hint: getLimitsLabel(props.minStandardBet, props.maxStandardBet),
            showHintOnFocus: true
        }));
    const computedItems = computed(()=>{
        const translatedLabel = $translate('WEB2_BETSLIP_FAST_SUMM_LABEL').value;
        return (props.fastBets ?? []).map((param, index)=>{
            let { value, error, changed } = param;
            return {
                value,
                error: error ? getErrorMessage({
                    error,
                    maxValue: props.maxFastBet,
                    minValue: props.minFastBet
                }) : '',
                isSaved: changed && props.changesIsSaved,
                label: `${translatedLabel} ${index + 1}`,
                hint: getLimitsLabel(props.minFastBet, props.maxFastBet),
                name: `fastBet${index}`,
                showHintOnFocus: true
            };
        });
    });
    const currentValues = computed(()=>(props.fastBets ?? []).map((param)=>{
            let { value } = param;
            return value;
        }));
    const getUpdatedValues = (event, index)=>{
        const { target } = event;
        const { value } = target;
        const valuesCopy = [
            ...currentValues.value
        ];
        valuesCopy[index] = value;
        return valuesCopy;
    };
    const onFastBetsBlur = (event, index)=>{
        emit('fast-bets-blur', getUpdatedValues(event, index));
    };
    const onFastBetsInput = (event, index)=>{
        emit('fast-bets-input', getUpdatedValues(event, index));
    };
    const onStandardSumInput = (event)=>{
        emit('standard-sum-input', event.target.value);
    };
    const onSwitchMaxEnabled = (value)=>{
        emit('switch-max-enabled', value);
    };
    return {
        standardSumProps,
        computedItems,
        onFastBetsBlur,
        onFastBetsInput,
        onStandardSumInput,
        onSwitchMaxEnabled
    };
}
