import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { PriceChangePolicy } from '@leon-hub/api-sdk';
import VSwitch from 'web/src/components/Switch/VSwitch/VSwitch.vue';
import useSlipCommonSettings from './composables/useSlipCommonSettings';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SlipCommonSettings',
    props: {
        showSlipOnFirstAdded: {
            type: Boolean
        },
        priceChangePolicy: {
            default: PriceChangePolicy.DENY_CHANGES
        }
    },
    emits: [
        "change-policy-select",
        "toggle-show-slip-on-first-added"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { autoUpdateEnabled, onlyIncrease, onAutoUpdateChange, toggleOnlyIncrease, resetOnlyIncreaseValue } = useSlipCommonSettings(props, emits);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['slip-common-settings'])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['slip-common-settings__group'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass(_ctx.$style['slip-common-settings__checkbox'])
                    }, [
                        _createVNode(VSwitch, {
                            checked: _unref(autoUpdateEnabled),
                            label: _ctx.$t('JS_PRICE_CHANGE_POLICY_CHECKBOX'),
                            onChange: _unref(onAutoUpdateChange)
                        }, null, 8, [
                            "checked",
                            "label",
                            "onChange"
                        ])
                    ], 2),
                    _createElementVNode("p", {
                        class: _normalizeClass(_ctx.$style['slip-common-settings__description'])
                    }, _toDisplayString(_ctx.$t('JS_SLIP_BET_SETTING_PCP_INFO')), 3),
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['slip-common-settings__collapsible']]: true,
                            [_ctx.$style['slip-common-settings__collapsible--collapsed']]: !_unref(autoUpdateEnabled)
                        }),
                        onTransitionend: _cache[0] || (_cache[0] = //@ts-ignore
                        function() {
                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                args[_key] = arguments[_key];
                            }
                            return _unref(resetOnlyIncreaseValue) && _unref(resetOnlyIncreaseValue)(...args);
                        })
                    }, [
                        _createElementVNode("div", {
                            class: _normalizeClass(_ctx.$style['slip-common-settings__checkbox'])
                        }, [
                            _createVNode(VSwitch, {
                                checked: _unref(onlyIncrease),
                                label: _ctx.$t('WEB2_PRICE_CHANGE_POLICY_ALLOW_INCREASE'),
                                onChange: _unref(toggleOnlyIncrease)
                            }, null, 8, [
                                "checked",
                                "label",
                                "onChange"
                            ])
                        ], 2)
                    ], 34)
                ], 2),
                _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SlipCommonSettings'
                ]
            ]);
        };
    }
});
