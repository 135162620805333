import { localStorageManager } from '@leon-hub/local-storage';
import { logger } from '@leon-hub/logging';
export default function useSlipLocalStorage() {
    const localStorageKey = 'web2_slip';
    const clearLocalStorageState = ()=>{
        try {
            localStorageManager.removeItem(localStorageKey);
        } catch (error) {
            logger.error('clearLocalStorageState error', error);
        }
    };
    const saveSnapshotToLocalStorage = (snapshot)=>{
        const asString = JSON.stringify(snapshot);
        if (asString) localStorageManager.setItem(localStorageKey, asString);
    };
    const getSnapshotFromLocalStorage = ()=>{
        try {
            const saveStateSnapshotString = localStorageManager.getItem(localStorageKey);
            if (!saveStateSnapshotString) return null;
            const parsedSnapshot = JSON.parse(saveStateSnapshotString);
            return parsedSnapshot || null;
        } catch  {
            return null;
        }
    };
    return {
        clearLocalStorageState,
        saveSnapshotToLocalStorage,
        getSnapshotFromLocalStorage
    };
}
