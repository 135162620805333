import isPriceChangedMatters from '../submodules/slip-info/utils/isPriceChangedMatters';
import isUnavailableEntryStatus from './isUnavailableEntryStatus';
export default function isEntryAvailableForBetting(param) {
    let { marketStatus, originalOdds, currentOdds, priceChangePolicy } = param;
    if (isUnavailableEntryStatus(marketStatus)) return false;
    return !isPriceChangedMatters({
        priceChangePolicy,
        currentOdds,
        originalOdds
    });
}
