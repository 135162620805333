import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function clearMultiSingleMarketStatusById(param) {
    let { id, currentSlipInfo } = param;
    const updatedEntries = [];
    for (const entry of currentSlipInfo.slipEntries){
        const firstEntry = entry.entries[0];
        if (firstEntry) {
            const isTargetEntry = getSlipEntryId(firstEntry) === id;
            updatedEntries.push({
                ...entry,
                entries: [
                    {
                        ...firstEntry,
                        marketStatus: isTargetEntry ? null : firstEntry.marketStatus
                    }
                ]
            });
        }
    }
    return updatedEntries;
}
