import { computed, toRef, ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { SlipTypeId } from '@leon-hub/api-sdk';
import formatOdd from 'web/src/utils/formatOdd';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useOddsSettings } from 'web/src/modules/sportline/composables/settings';
import { getFreebetMaxPrize } from '../../../utils';
import { useSlipInfoStore } from '../../slip-info/store';
import { useStakeInputStore } from '../../stake-input/store';
import { useFreebetStore } from '../../freebet/store';
import { getAccumulatorBoostPercentage, getCurrentAccumulatorBoostSetting, getNextAccumulatorBoostSetting, getWinBoostPercentageString, getSatisfyingBoostSettingsEventsCount, getSortedBoostSettings, getAccumulatorBoostValues, getWinBoostValue } from '../utils';
const useAccumulatorBoostStore = defineStore('accumulator-boost', ()=>{
    const { isOddsFormatSwitcherEnabled } = useOddsSettings();
    const siteConfigStore = useSiteConfigStore();
    const slipBlock = toRef(siteConfigStore, 'slipBlock');
    const accumulatorBoostEnabled = computed(()=>slipBlock.value?.accumulatorBoostEnabled ?? false);
    const accumulatorBoostMinOdds = computed(()=>slipBlock.value?.accumulatorBoostMinOdds ?? 1);
    const accumulatorBoostOnlyPrimaryMarkets = computed(()=>slipBlock.value?.accumulatorBoostOnlyPrimaryMarkets ?? false);
    const isWinBoost = computed(()=>slipBlock.value?.accumulatorBoostIsWinBoost ?? false);
    const accumulatorBoostPerOutcomeCount = computed(()=>slipBlock.value?.accumulatorBoostPerOutcomeCount ?? []);
    const freebetStore = useFreebetStore();
    const currentFreeBet = toRef(freebetStore, 'currentFreeBet');
    const slipInfoStore = useSlipInfoStore();
    const batchedSlipInfo = toRef(slipInfoStore, 'batchedSlipInfo');
    const combiOrFirstSlip = toRef(slipInfoStore, 'combiOrFirstSlip');
    const totalOdds = toRef(slipInfoStore, 'totalOdds');
    const betMode = toRef(slipInfoStore, 'betMode');
    const combinedEntriesInfo = toRef(slipInfoStore, 'combinedEntriesInfo');
    const selectedStakeValue = toRef(slipInfoStore, 'selectedStakeValue');
    const stakeInputStore = useStakeInputStore();
    const safeStakeValue = toRef(stakeInputStore, 'safeStakeValue');
    /** '--' is a placeholder what probably will not even visible with normal connection speed */ const savedAccumulatorBoostMinOddsStr = ref('--');
    watch(()=>batchedSlipInfo.value?.accumulatorBoostMinOddsStr, (value)=>{
        if (value && isOddsFormatSwitcherEnabled.value) savedAccumulatorBoostMinOddsStr.value = value;
    });
    const formattedAccumulatorBoostMinOdds = computed(()=>{
        if (!isOddsFormatSwitcherEnabled.value) return formatOdd(accumulatorBoostMinOdds.value);
        return savedAccumulatorBoostMinOddsStr.value;
    });
    const totalOddsWithoutAccumulatorBoost = computed(()=>{
        const unBoostedOdds = combiOrFirstSlip.value?.totalOddsWithoutAccumulatorBoost;
        if (isWinBoost.value || !unBoostedOdds || unBoostedOdds === totalOdds.value) return null;
        return unBoostedOdds;
    });
    const formattedOddsWithoutBoost = computed(()=>{
        if (betMode.value !== SlipTypeId.EXPRESS || !totalOddsWithoutAccumulatorBoost.value || totalOdds.value === totalOddsWithoutAccumulatorBoost.value) return '';
        if (isOddsFormatSwitcherEnabled.value) return combiOrFirstSlip.value?.totalOddsWithoutAccumulatorBoostStr ?? '';
        return formatOdd(totalOddsWithoutAccumulatorBoost.value);
    });
    const showAccumulatorBoost = computed(()=>{
        if (!accumulatorBoostEnabled.value) return false;
        return betMode.value === SlipTypeId.EXPRESS;
    });
    const satisfyingBoostSettingsEventsCount = computed(()=>getSatisfyingBoostSettingsEventsCount({
            combinerSlipEntries: combinedEntriesInfo.value,
            minOdds: accumulatorBoostMinOdds.value,
            onlyPrimaryMarkets: accumulatorBoostOnlyPrimaryMarkets.value
        }));
    const sortedSettingsTable = ref([]);
    const maxAccumulatorBoostMultiplier = ref(0);
    const minAccumulatorBoostMultiplier = ref(0);
    const minCount = ref(0);
    const prepareSettings = ()=>{
        sortedSettingsTable.value = getSortedBoostSettings(accumulatorBoostPerOutcomeCount.value);
        const boostValues = getAccumulatorBoostValues({
            sortedSettings: sortedSettingsTable.value,
            isWinBoost: isWinBoost.value
        });
        maxAccumulatorBoostMultiplier.value = boostValues.maxBoost;
        minAccumulatorBoostMultiplier.value = boostValues.minBoost;
        minCount.value = boostValues.minCount;
    };
    watch(accumulatorBoostPerOutcomeCount, ()=>{
        prepareSettings();
    }, {
        immediate: true
    });
    watch(isWinBoost, ()=>{
        prepareSettings();
    });
    const currentAccumulatorBoostMultiplierSetting = computed(()=>getCurrentAccumulatorBoostSetting(satisfyingBoostSettingsEventsCount.value, sortedSettingsTable.value));
    const currentAccumulatorBoostMultiplier = computed(()=>{
        if (!currentAccumulatorBoostMultiplierSetting.value) return 0;
        const { boost } = currentAccumulatorBoostMultiplierSetting.value;
        return isWinBoost.value ? boost : getAccumulatorBoostPercentage(boost);
    });
    const nextAccumulatorBoostMultiplierSetting = computed(()=>getNextAccumulatorBoostSetting(satisfyingBoostSettingsEventsCount.value, sortedSettingsTable.value));
    const nextAccumulatorBoostMultiplier = computed(()=>{
        if (!nextAccumulatorBoostMultiplierSetting.value) return maxAccumulatorBoostMultiplier.value;
        const { boost } = nextAccumulatorBoostMultiplierSetting.value;
        return isWinBoost.value ? boost : getAccumulatorBoostPercentage(boost);
    });
    const itemsToReachNextBoost = computed(()=>{
        if (!nextAccumulatorBoostMultiplierSetting.value) return 0;
        return nextAccumulatorBoostMultiplierSetting.value.count - satisfyingBoostSettingsEventsCount.value;
    });
    const maxPrizeWithoutBoost = computed(()=>{
        if (betMode.value !== SlipTypeId.EXPRESS || !totalOddsWithoutAccumulatorBoost.value || !selectedStakeValue.value) return null;
        if (currentFreeBet.value) return getFreebetMaxPrize(currentFreeBet.value, totalOddsWithoutAccumulatorBoost.value) || null;
        return totalOddsWithoutAccumulatorBoost.value * selectedStakeValue.value;
    });
    const potentialWin = computed(()=>(totalOdds.value ?? 0) * safeStakeValue.value);
    const winBoostValue = computed(()=>{
        if (!accumulatorBoostEnabled.value || !isWinBoost.value || !selectedStakeValue.value || currentFreeBet.value || !currentAccumulatorBoostMultiplier.value) return null;
        return getWinBoostValue({
            winValue: potentialWin.value,
            stake: safeStakeValue.value,
            boostPercent: currentAccumulatorBoostMultiplier.value
        });
    });
    const boostedWinValue = computed(()=>{
        if (!winBoostValue.value) return null;
        return potentialWin.value + winBoostValue.value;
    });
    const winBoostInfoRows = computed(()=>{
        if (!accumulatorBoostEnabled.value || !isWinBoost.value) return [];
        return accumulatorBoostPerOutcomeCount.value.map((item)=>({
                count: `${item.count}`,
                boost: getWinBoostPercentageString(item.boost)
            }));
    });
    return {
        accumulatorBoostEnabled,
        formattedOddsWithoutBoost,
        showAccumulatorBoost,
        maxAccumulatorBoostMultiplier,
        minAccumulatorBoostMultiplier,
        minCount,
        currentAccumulatorBoostMultiplier,
        nextAccumulatorBoostMultiplier,
        itemsToReachNextBoost,
        maxPrizeWithoutBoost,
        formattedAccumulatorBoostMinOdds,
        isWinBoost,
        boostedWinValue,
        winBoostValue,
        winBoostInfoRows
    };
});
export default useAccumulatorBoostStore;
