import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { BusEvent, useEventsBus } from '@leon-hub/event-bus';
import RouteName from '@leon-hub/routing-config';
import { BetSlipResultState, TopLevelTabs } from '../../../enums';
export default function useSlipLayoutDesktop(props) {
    const bus = useEventsBus();
    const router = useRouter();
    const showResultOverlay = computed(()=>props.showBookingOverlay || // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        props.resultState === BetSlipResultState.SUCCESS || props.resultState === BetSlipResultState.ERROR);
    const scrollDisabled = ref(false);
    const showBannerBlock = computed(()=>!props.isSettingsVisible && props.activeTopTabId === TopLevelTabs.SLIP);
    const disableScroll = ()=>{
        scrollDisabled.value = true;
    };
    const enableScroll = ()=>{
        scrollDisabled.value = false;
    };
    const onKeydown = (event)=>{
        // TODO LEONWEB-10763
        if (scrollDisabled.value && ('ArrowDown' === event.key || 'ArrowUp' === event.key)) event.preventDefault();
    };
    const goToHelp = ()=>{
        router.push({
            name: RouteName.SUPPORT
        });
    };
    bus.on(BusEvent.LAYOUT_CONTENT_SCROLL_DISABLE, disableScroll);
    bus.on(BusEvent.LAYOUT_CONTENT_SCROLL_ENABLE, enableScroll);
    return {
        onKeydown,
        goToHelp,
        scrollDisabled,
        showResultOverlay,
        showBannerBlock
    };
}
