import { ref, computed, toRef } from 'vue';
import { defineStore } from 'pinia';
import { Timer } from '@leon-hub/utils';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { KeyboardInputType } from '../../../enums';
import { getSafeStakeValueString, getSafeStakeValue, getModifiedStakeInputValue, getKeyboardPayloadFromEvent } from '../utils';
const useStakeInputStore = defineStore('stake-input-store', ()=>{
    const stakeInputValue = ref('0');
    /** to clear value on manual input start */ const stakeValueIsPredefined = ref(true);
    /** needs for metrika */ const selectedFastMoneyValue = ref(null);
    const isStakeInputFocused = ref(false);
    const sportLineBlock = toRef(useSiteConfigStore(), 'sportLineBlock');
    const isReadyToDisplayErrors = ref(true);
    const stakeInputValidationTimeout = computed(()=>sportLineBlock.value?.stakeInputValidationTimeout || 2000);
    let readyToDisplayInputErrorsTimeoutId = 0;
    const handleStakeInputEdited = (timeout)=>{
        isReadyToDisplayErrors.value = false;
        Timer.clearTimeout(readyToDisplayInputErrorsTimeoutId);
        readyToDisplayInputErrorsTimeoutId = Timer.setTimeout(()=>{
            isReadyToDisplayErrors.value = true;
        }, timeout || stakeInputValidationTimeout.value);
    };
    const setStakeInputValue = function() {
        let value = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : '0';
        stakeInputValue.value = value;
    };
    const setStakeValueIsPredefined = (isPredefined)=>{
        stakeValueIsPredefined.value = isPredefined;
    };
    const setSelectedFastMoneyValue = (value)=>{
        selectedFastMoneyValue.value = value;
    };
    const setStakeInputIsFocused = (isFocused)=>{
        isStakeInputFocused.value = isFocused;
    };
    const stakeInputFocus = ()=>{
        handleStakeInputEdited();
        setStakeInputIsFocused(true);
        if (stakeValueIsPredefined.value) {
            setStakeInputValue('');
            setSelectedFastMoneyValue(null);
        }
    };
    const stakeInputBlur = ()=>{
        setStakeInputIsFocused(false);
        if (!isReadyToDisplayErrors.value) handleStakeInputEdited(200);
        if (!stakeInputValue.value) {
            setStakeValueIsPredefined(true);
            setStakeInputValue('0');
            setSelectedFastMoneyValue(null);
        }
    };
    const safeSetStakeValue = (payload)=>{
        const safeValue = getSafeStakeValueString(payload);
        setStakeInputValue(safeValue);
        setStakeValueIsPredefined(true);
        if (selectedFastMoneyValue.value && selectedFastMoneyValue.value !== Number(safeValue)) // selected fastMoney value was overwritten on fixing stake
        setSelectedFastMoneyValue(null);
    };
    const handleStakeInput = (value)=>{
        handleStakeInputEdited();
        setStakeValueIsPredefined(false);
        setStakeInputValue(value);
        setSelectedFastMoneyValue(null);
    };
    const handleStakeKeyboardInput = (payload)=>{
        const inputValue = getModifiedStakeInputValue(stakeInputValue.value, payload);
        handleStakeInput(inputValue);
    };
    const fixStakeRange = (payload)=>{
        const { maxStake, minStake, stakeBelowMinimum } = payload;
        if (stakeBelowMinimum && minStake) setStakeInputValue(`${minStake}`);
        else if (maxStake) setStakeInputValue(`${maxStake}`);
        setSelectedFastMoneyValue(null);
        setStakeValueIsPredefined(true);
    };
    const selectFastBet = (value)=>{
        setSelectedFastMoneyValue(value);
        setStakeValueIsPredefined(true);
        setStakeInputValue(`${value}`);
    };
    const setValueFromFreebet = (freeBetAmount)=>{
        setStakeInputValue(freeBetAmount);
        setSelectedFastMoneyValue(null);
    };
    const safeStakeValue = computed(()=>getSafeStakeValue(stakeInputValue.value));
    const isMobileKeyboardShown = computed(()=>{
        "1";
        return false;
    });
    const hideKeyboard = ()=>{
        isReadyToDisplayErrors.value = true;
        stakeInputBlur();
    };
    const handleKeyboardStakeInput = (event)=>{
        const payload = getKeyboardPayloadFromEvent(event, isStakeInputFocused.value, stakeValueIsPredefined.value);
        if (payload) handleStakeKeyboardInput(payload);
    };
    const backspace = ()=>{
        handleStakeKeyboardInput({
            action: KeyboardInputType.BACKSPACE
        });
    };
    const numberInput = (value)=>{
        handleStakeKeyboardInput({
            action: KeyboardInputType.NUMBER,
            value,
            removeCurrentValue: stakeValueIsPredefined.value
        });
    };
    const decimalSeparatorClick = ()=>{
        handleStakeKeyboardInput({
            action: KeyboardInputType.DECIMAL,
            removeCurrentValue: stakeValueIsPredefined.value
        });
    };
    return {
        stakeInputValue,
        safeStakeValue,
        selectedFastMoneyValue,
        isStakeInputFocused,
        isMobileKeyboardShown,
        isReadyToDisplayErrors,
        stakeInputValidationTimeout,
        stakeInputFocus,
        stakeInputBlur,
        safeSetStakeValue,
        handleStakeInput,
        fixStakeRange,
        selectFastBet,
        setStakeInputIsFocused,
        setSelectedFastMoneyValue,
        setValueFromFreebet,
        hideKeyboard,
        handleKeyboardStakeInput,
        backspace,
        numberInput,
        decimalSeparatorClick
    };
});
export default useStakeInputStore;
