import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
    key: 0
};
import useBetSlipTraceContent from '../composables/useBetSlipTraceContent';
import { KnownTraceTypes } from '../enums/KnownTraceTypes';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipTraceContent',
    props: {
        contents: {}
    },
    setup (__props) {
        const props = __props;
        const { isList, isTable, listSource, rootTag, contentTag, formattedText, title, typeId } = useBetSlipTraceContent(props);
        return (_ctx, _cache)=>{
            const _component_BetSlipTraceContent = _resolveComponent("BetSlipTraceContent", true);
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent(_unref(rootTag)), null, {
                default: _withCtx(()=>[
                        _unref(isList) ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(listSource), (item, index)=>(_openBlock(), _createBlock(_component_BetSlipTraceContent, {
                                    key: index,
                                    contents: item
                                }, null, 8, [
                                    "contents"
                                ]))), 128))
                        ])) : _unref(isTable) ? (_openBlock(), _createElementBlock("table", {
                            key: 1,
                            class: _normalizeClass(_ctx.$style['slip-trace-table'])
                        }, [
                            _createElementVNode("tbody", null, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(listSource), (row, rowIndex)=>(_openBlock(), _createElementBlock("tr", {
                                        key: rowIndex
                                    }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (cell, cellIndex)=>(_openBlock(), _createElementBlock("td", {
                                                key: cellIndex,
                                                class: _normalizeClass(_ctx.$style['slip-trace-table__cell'])
                                            }, [
                                                _createVNode(_component_BetSlipTraceContent, {
                                                    contents: cell ?? void 0
                                                }, null, 8, [
                                                    "contents"
                                                ])
                                            ], 2))), 128))
                                    ]))), 128))
                            ])
                        ], 2)) : (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(contentTag)), {
                            key: 2,
                            title: _unref(title),
                            class: _normalizeClass({
                                [_ctx.$style['slip-trace-abbr']]: _unref(typeId) === _unref(KnownTraceTypes).LABEL_WITH_TITLE,
                                [_ctx.$style['slip-trace-label']]: _unref(typeId) === _unref(KnownTraceTypes).LABEL
                            })
                        }, {
                            default: _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(_unref(formattedText)), 1)
                                ]),
                            _: 1
                        }, 8, [
                            "title",
                            "class"
                        ]))
                    ]),
                _: 1
            })), [
                [
                    _directive_auto_id,
                    'BetSlipTraceContent'
                ]
            ]);
        };
    }
});
