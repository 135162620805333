import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "href"
];
const _hoisted_2 = [
    "href"
];
const _hoisted_3 = {
    key: 2
};
const _hoisted_4 = [
    "href"
];
import { onMounted } from 'vue';
import { VLoaderDelayed } from 'web/src/components/Loader';
import BetSlipMessageBlock from '../../BetSlipMessageBlock';
import { SlipListHistoryItem } from '../../SlipListItem';
import useBetSlipMyBets from '../composables/useBetSlipMyBets';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipMyBets',
    props: {
        list: {
            default: ()=>[]
        },
        isLoaded: {
            type: Boolean
        },
        isLoadingMore: {
            type: Boolean
        },
        placedBetsCount: {},
        historyUrl: {
            default: ''
        }
    },
    emits: [
        "load-more",
        "cash-out",
        "history-link-click",
        "item-click"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { isLoaderVisible, isEmpty, isFooterHidden, isFooterSticky, isIntersectionObserverAvailable, loadMoreObserverTarget, emitCashout, emitLoadMore, onAppear, emitHistoryClick, emitItemClick } = useBetSlipMyBets(props, emits);
        onMounted(onAppear);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['bet-slip-my-bets--desktop']]: true,
                    [_ctx.$style['bet-slip-my-bets--phone']]: false
                })
            }, [
                _unref(isLoaderVisible) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['bet-slip-my-bets__loader'])
                }, [
                    _createVNode(_unref(VLoaderDelayed))
                ], 2)) : (_openBlock(), _createElementBlock(_Fragment, {
                    key: 1
                }, [
                    _ctx.placedBetsCount ? (_openBlock(), _createElementBlock("header", {
                        key: 0,
                        class: _normalizeClass(_ctx.$style['bet-slip-my-bets__header'])
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass(_ctx.$style['bet-slip-my-bets__caption'])
                        }, _toDisplayString(_ctx.$t('WEB2_CASHOUT_HELP_MODAL_UNPLAYED_BETS')), 3),
                        _unref(isIntersectionObserverAvailable) ? _createCommentVNode("", true) : (_openBlock(), _createElementBlock("a", {
                            key: 0,
                            class: _normalizeClass([
                                _ctx.$style['bet-slip-my-bets__details-link'],
                                _ctx.$style['bet-slip-my-bets__details-link--fallback']
                            ]),
                            href: _ctx.historyUrl,
                            "data-test-id": "list-history",
                            onClick: _cache[0] || (_cache[0] = _withModifiers(//@ts-ignore
                            function() {
                                for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                    args[_key] = arguments[_key];
                                }
                                return _unref(emitHistoryClick) && _unref(emitHistoryClick)(...args);
                            }, [
                                "prevent"
                            ]))
                        }, _toDisplayString(_ctx.$t('TABS_HISTORY')), 11, _hoisted_1))
                    ], 2)) : _createCommentVNode("", true),
                    _unref(isEmpty) ? (_openBlock(), _createBlock(_unref(BetSlipMessageBlock), {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['bet-slip-my-bets__empty-message']),
                        caption: _ctx.$t('JS_BETSLIP_HISTORY_EMPTY_CAPTION'),
                        description: _ctx.$t('JS_BETSLIP_HISTORY_EMPTY_MESSAGE')
                    }, {
                        default: _withCtx(()=>[
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['bet-slip-my-bets__empty-link'])
                                }, [
                                    _createElementVNode("a", {
                                        href: _ctx.historyUrl,
                                        class: _normalizeClass(_ctx.$style['bet-slip-my-bets__details-link']),
                                        "data-test-id": "empty-history",
                                        onClick: _cache[1] || (_cache[1] = _withModifiers(//@ts-ignore
                                        function() {
                                            for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                                args[_key] = arguments[_key];
                                            }
                                            return _unref(emitHistoryClick) && _unref(emitHistoryClick)(...args);
                                        }, [
                                            "prevent"
                                        ]))
                                    }, _toDisplayString(_ctx.$t('WEB2_SLIP_ALL_HISTORY')), 11, _hoisted_2)
                                ], 2)
                            ]),
                        _: 1
                    }, 8, [
                        "class",
                        "caption",
                        "description"
                    ])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("ul", {
                            class: _normalizeClass(_ctx.$style['bet-slip-my-bets__list'])
                        }, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item)=>(_openBlock(), _createElementBlock("li", {
                                    key: item.id,
                                    class: _normalizeClass(_ctx.$style['bet-slip-my-bets__row'])
                                }, [
                                    _createVNode(_unref(SlipListHistoryItem), _mergeProps({
                                        ref_for: true
                                    }, item, {
                                        id: item.id,
                                        "bet-type": item.betType,
                                        onCashOutClick: ($event)=>_unref(emitCashout)(item),
                                        onItemClick: ($event)=>_unref(emitItemClick)(item.id)
                                    }), null, 16, [
                                        "id",
                                        "bet-type",
                                        "onCashOutClick",
                                        "onItemClick"
                                    ])
                                ], 2))), 128))
                        ], 2),
                        _createElementVNode("div", {
                            ref_key: "loadMoreObserverTarget",
                            ref: loadMoreObserverTarget
                        }, null, 512),
                        _withDirectives(_createVNode(_unref(VLoaderDelayed), null, null, 512), [
                            [
                                _vShow,
                                _ctx.isLoadingMore
                            ]
                        ]),
                        _withDirectives(_createElementVNode("div", {
                            class: _normalizeClass({
                                [_ctx.$style['bet-slip-my-bets__footer']]: true,
                                [_ctx.$style['bet-slip-my-bets__footer--sticky']]: _unref(isFooterSticky)
                            })
                        }, [
                            _unref(isIntersectionObserverAvailable) ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                href: _ctx.historyUrl,
                                class: _normalizeClass(_ctx.$style['bet-slip-my-bets__details-link']),
                                "data-test-id": "list-history",
                                onClick: _cache[2] || (_cache[2] = _withModifiers(//@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(emitHistoryClick) && _unref(emitHistoryClick)(...args);
                                }, [
                                    "prevent"
                                ]))
                            }, _toDisplayString(_ctx.$t('WEB2_SLIP_ALL_HISTORY')), 11, _hoisted_4)) : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: _normalizeClass(_ctx.$style['bet-slip-my-bets__details-link']),
                                onClick: _cache[3] || (_cache[3] = //@ts-ignore
                                function() {
                                    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                                        args[_key] = arguments[_key];
                                    }
                                    return _unref(emitLoadMore) && _unref(emitLoadMore)(...args);
                                })
                            }, _toDisplayString(_ctx.$t('JS_PROMOTIONS_TITLE_SHOWMORE')), 3))
                        ], 2), [
                            [
                                _vShow,
                                !_unref(isFooterHidden)
                            ]
                        ])
                    ]))
                ], 64))
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetSlipMyBets'
                ]
            ]);
        };
    }
});
