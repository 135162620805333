import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { SlipTypeId } from '@leon-hub/api-sdk';
import RouteName from '@leon-hub/routing-config';
import { Timer } from '@leon-hub/utils';
import { useI18n } from 'web/src/modules/i18n/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { BetSlipSummaryStatus } from '../../../enums';
export default function useBetSlipSummary(props, emit) {
    const { $translate } = useI18n();
    const router = useRouter();
    const formatMoney = useFormatMoney();
    const wrongModeMessage = computed(()=>{
        if (!props.wrongBetMode) return '';
        switch(props.betMode){
            case SlipTypeId.SINGLE:
                return $translate('WEB2_SLIP_UNAVAILABLE_SINGLE').value;
            case SlipTypeId.EXPRESS:
                return $translate('WEB2_SLIP_UNAVAILABLE_EXPRESS').value;
            case SlipTypeId.SYSTEM:
                return $translate('WEB2_SLIP_UNAVAILABLE_SYSTEM').value;
            default:
                return '';
        }
    });
    const inputError = computed(()=>{
        if (!props.readyToDisplayErrors) return false;
        switch(props.status){
            case BetSlipSummaryStatus.NOT_ENOUGH_BALANCE:
            case BetSlipSummaryStatus.ABOVE_MAXIMUM:
            case BetSlipSummaryStatus.BELOW_MINIMUM:
                return true;
            default:
                return false;
        }
    });
    const outOfLimitsText = computed(()=>{
        const from = $translate('WEB2_FROM').value;
        const till = $translate('JSPBET_TILL').value;
        return `${from} ${formatMoney(props.minStake ?? 0)} ${till} ${formatMoney(props.maxStake ?? 0)}`;
    });
    const mustShowBalanceMessage = computed(()=>props.status === BetSlipSummaryStatus.NOT_ENOUGH_BALANCE);
    const mustShowPrizeMessage = computed(()=>props.status === BetSlipSummaryStatus.DEFAULT);
    const showWrongModeMessage = computed(()=>!!props.wrongBetMode && !props.isSyncInProgress);
    const errorLabel = computed(()=>{
        if (!inputError.value || !props.readyToDisplayErrors) return '';
        if (mustShowBalanceMessage.value) return $translate('WEB2_STAKE_ABOVE_BALANCE').value;
        return outOfLimitsText.value;
    });
    const isSystemMode = computed(()=>props.betMode === SlipTypeId.SYSTEM);
    const canShowFreeBetSelector = computed(()=>!isSystemMode.value && !!props.showFreeBet && !props.isMultiSingles);
    const mustShowMessage = computed(()=>{
        switch(props.status){
            case BetSlipSummaryStatus.DEFAULT:
            case BetSlipSummaryStatus.DONE:
            case BetSlipSummaryStatus.NOT_ENOUGH_BALANCE:
                return false;
            default:
                return true;
        }
    });
    const computedWarningMessage = computed(()=>{
        if (props.warningMessage) return props.warningMessage;
        switch(props.status){
            case BetSlipSummaryStatus.LIMIT:
                return $translate('WEB2_SLIP_SUMMARY_LIMIT_MESSAGE').value;
            case BetSlipSummaryStatus.CHANGED_PRICES_AND_MARKETS:
                return $translate('WEB2_SLIP_WARNING_MARKETS_AND_PRICES_CHANGED').value;
            case BetSlipSummaryStatus.MARKETS_CHANGED:
                return $translate('WEB2_SLIP_WARNING_MARKETS_CLOSED').value;
            case BetSlipSummaryStatus.PRICES_CHANGED:
                return $translate('WEB2_SLIP_WARNING_PRICES_CHANGED').value;
            case BetSlipSummaryStatus.UNABLE_TO_APPLY_FREEBET:
                return $translate('WEB2_SLIP_WARNING_UNABLE_TO_APPLY_FREEBET', ref({
                    limit: outOfLimitsText.value
                })).value;
            case BetSlipSummaryStatus.BELOW_MINIMUM:
                return $translate('WEB2_SLIP_SUMMARY_BELOW_MINIMUM').value;
            case BetSlipSummaryStatus.ABOVE_MAXIMUM:
                return $translate('WEB2_SLIP_SUMMARY_ABOVE_MAXIMUM').value;
            case BetSlipSummaryStatus.WAITING:
                return $translate('WEB2_SLIP_SUMMARY_WAITING').value;
            default:
                return '';
        }
    });
    const freebetTermsRouteParams = computed(()=>({
            name: RouteName.CMS_PROMO_TERMS,
            params: {
                cmsKey: props.freeBetTermKey
            }
        }));
    const showAmountValue = computed(()=>{
        if (props.isMultiSingles) return !!props.sameStakeForSingles;
        return true;
    });
    const emitStakeFocus = ()=>{
        emit('stake-focus');
    };
    const emitStakeBlur = (value)=>{
        emit('stake-blur', value);
    };
    const emitStakeInput = (value)=>{
        emit('stake-input', value);
    };
    const isPcpSwitchCollapsed = ref(!props.denyPriceChanges);
    watch(()=>props.isVisible, ()=>{
        // need to be here for correct ui
        isPcpSwitchCollapsed.value = !props.denyPriceChanges;
    }, {
        immediate: true
    });
    const onAllowPriceChanges = ()=>{
        emit('allow-price-changes');
        Timer.setTimeout(()=>{
            // smooth animation behaviour
            isPcpSwitchCollapsed.value = true;
        }, 200);
    };
    const emitToggleSameStakeForSingles = ()=>{
        emit('toggle-same-stake-for-singles');
    };
    const emitFreeBetSwitch = (isChecked)=>{
        emit('freebet-switch', isChecked);
    };
    const emitFreebetSelect = (event)=>{
        emit('freebet-select', event.target.value);
    };
    const emitSystemSelect = (event)=>{
        emit('system-select', event.target.value);
    };
    const onFreeBetSwitchClick = ()=>{
        if (!props.freeBetSwitchDisabled) return;
        switch(props.status){
            case BetSlipSummaryStatus.CHANGED_PRICES_AND_MARKETS:
            case BetSlipSummaryStatus.MARKETS_CHANGED:
            case BetSlipSummaryStatus.PRICES_CHANGED:
            case BetSlipSummaryStatus.LIMIT:
                return;
            default:
                router.push(freebetTermsRouteParams.value);
        }
    };
    const emitClearClick = ()=>{
        emit('clear-button-click');
    };
    const emitSettingsClick = ()=>{
        emit('settings-click');
    };
    return {
        wrongModeMessage,
        inputError,
        errorLabel,
        mustShowBalanceMessage,
        mustShowMessage,
        isSystemMode,
        canShowFreeBetSelector,
        mustShowPrizeMessage,
        showAmountValue,
        showWrongModeMessage,
        freebetTermsRouteParams,
        computedWarningMessage,
        isPcpSwitchCollapsed,
        emitStakeInput,
        emitStakeFocus,
        emitStakeBlur,
        onAllowPriceChanges,
        emitFreeBetSwitch,
        emitToggleSameStakeForSingles,
        emitFreebetSelect,
        emitSystemSelect,
        onFreeBetSwitchClick,
        emitClearClick,
        emitSettingsClick
    };
}
