import { MarketStatus } from '@leon-hub/api-sdk';
import getSlipEntryId from '../../../utils/getSlipEntryId';
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */ const isEntryWithClosedMarket = (slipEntry)=>{
    if (!slipEntry?.marketStatus) return false;
    return slipEntry.marketStatus === MarketStatus.CLOSED || slipEntry.marketStatus === MarketStatus.MISSING;
};
export default function getClosedAndUnlockedEventsData(param) {
    let { entriesWithChangedStatus, changedEvents, slipEntries } = param;
    const resentChangedEventsIds = entriesWithChangedStatus.map((entry)=>getSlipEntryId(entry));
    const currentChangedEventsIds = changedEvents.map((entry)=>getSlipEntryId(entry));
    // diff is for no longer locked items
    const unlockedEventsIds = new Set(resentChangedEventsIds.filter((id)=>!currentChangedEventsIds.includes(id)));
    const unlockedEventsData = slipEntries.filter((entry)=>{
        const id = getSlipEntryId(entry);
        return unlockedEventsIds.has(id);
    });
    const entriesWithClosedMarket = changedEvents.filter((event)=>isEntryWithClosedMarket(event));
    return {
        unlockedEventsData,
        unlockedEventsIds,
        entriesWithClosedMarket
    };
}
