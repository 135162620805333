import { logger } from '@leon-hub/logging';
export default function logAddingToSlip(item) {
    const { betline, event, market, runner, odds } = item;
    logger.info('Adding to betslip', {
        betline,
        event,
        market,
        runner,
        odds
    });
}
