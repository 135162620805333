import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = [
    "title"
];
import { IconSize, IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import useAccumulatorBoostInfo from './composables/useAccumulatorBoostInfo';
const progressToAlignRight = 84;
export default /*@__PURE__*/ _defineComponent({
    __name: 'AccumulatorBoostInfo',
    props: {
        currentMultiplier: {
            default: 0
        },
        nextMultiplier: {
            default: 0
        },
        maxMultiplier: {
            default: 0
        },
        itemsToNextBoost: {},
        requiredOdds: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const { progress, showInfoModal, boost, termsTranslateParams } = useAccumulatorBoostInfo(props);
        // need for correct styles
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['accumulator-boost-info'])
            }, [
                _createElementVNode("span", {
                    class: _normalizeClass(_ctx.$style['accumulator-boost-info__next-boost'])
                }, _toDisplayString(_ctx.currentMultiplier === _ctx.maxMultiplier ? _ctx.$t('WEB2_ACCUMULATOR_BOOST_INFO_CAPTION_MAX', _unref(boost)) : _ctx.$t('WEB2_ACCUMULATOR_BOOST_INFO_CAPTION', _unref(boost))), 3),
                _ctx.currentMultiplier !== _ctx.maxMultiplier ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['accumulator-boost-info__terms'])
                }, _toDisplayString(_ctx.$t('WEB2_ACCUMULATOR_BOOST_INFO_TERM', _unref(termsTranslateParams))), 3)) : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['accumulator-boost-info__progress'])
                }, [
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['accumulator-boost-info__progress-limit'])
                    }, "0%", 2),
                    _createElementVNode("span", {
                        class: _normalizeClass({
                            [_ctx.$style['accumulator-boost-info__progress-bar']]: true,
                            [_ctx.$style['accumulator-boost-info__progress-bar--almost-full']]: _unref(progress) >= 84
                        }),
                        style: _normalizeStyle(`--progress: ${_unref(progress)}%`)
                    }, [
                        _createElementVNode("span", {
                            class: _normalizeClass({
                                [_ctx.$style['accumulator-boost-info__progress-value']]: true,
                                [_ctx.$style['accumulator-boost-info__progress-value--middle']]: _unref(progress) > 10 && _unref(progress) < progressToAlignRight,
                                [_ctx.$style['accumulator-boost-info__progress-value--right']]: _unref(progress) >= progressToAlignRight
                            }),
                            style: _normalizeStyle(`--progress: ${_unref(progress)}%`)
                        }, _toDisplayString(_ctx.currentMultiplier) + "%", 7)
                    ], 6),
                    _createElementVNode("span", {
                        class: _normalizeClass(_ctx.$style['accumulator-boost-info__progress-limit'])
                    }, _toDisplayString(_ctx.maxMultiplier) + "%", 3)
                ], 2),
                _createElementVNode("button", {
                    type: "button",
                    class: _normalizeClass(_ctx.$style['accumulator-boost-info__help']),
                    title: _ctx.$t('WEB2_CBC_PROMO_MORE'),
                    onClick: _cache[0] || (_cache[0] = //@ts-ignore
                    function() {
                        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
                            args[_key] = arguments[_key];
                        }
                        return _unref(showInfoModal) && _unref(showInfoModal)(...args);
                    })
                }, [
                    _createVNode(_unref(VIcon), {
                        name: _unref(IconName).INFO_OUTLINE,
                        size: _unref(IconSize).SIZE_16
                    }, null, 8, [
                        "name",
                        "size"
                    ])
                ], 10, _hoisted_1)
            ], 2)), [
                [
                    _directive_auto_id,
                    'AccumulatorBoostInfo'
                ]
            ]);
        };
    }
});
