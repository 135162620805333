export default function getSlipInfoWithoutTrace(slipInfo) {
    if (!slipInfo) return null;
    const { slipEntries, combiAvailable } = slipInfo;
    return {
        slipEntries: slipEntries.map((entry)=>({
                ...entry,
                trace: null
            })),
        combiAvailable
    };
}
