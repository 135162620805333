import { toRef } from 'vue';
import { useAccumulatorBoostStore } from '../submodules/accumulator-boost/store';
export default function useAccumulatorBoost() {
    const accumulatorBoostStore = useAccumulatorBoostStore();
    const showAccumulatorBoost = toRef(accumulatorBoostStore, 'showAccumulatorBoost');
    const currentMultiplier = toRef(accumulatorBoostStore, 'currentAccumulatorBoostMultiplier');
    const nextMultiplier = toRef(accumulatorBoostStore, 'nextAccumulatorBoostMultiplier');
    const maxMultiplier = toRef(accumulatorBoostStore, 'maxAccumulatorBoostMultiplier');
    const requiredOdds = toRef(accumulatorBoostStore, 'formattedAccumulatorBoostMinOdds');
    const itemsToNextBoost = toRef(accumulatorBoostStore, 'itemsToReachNextBoost');
    const isWinBoost = toRef(accumulatorBoostStore, 'isWinBoost');
    const boostedWinValue = toRef(accumulatorBoostStore, 'boostedWinValue');
    const winBoostValue = toRef(accumulatorBoostStore, 'winBoostValue');
    return {
        showAccumulatorBoost,
        currentMultiplier,
        nextMultiplier,
        maxMultiplier,
        requiredOdds,
        itemsToNextBoost,
        isWinBoost,
        boostedWinValue,
        winBoostValue
    };
}
