export default function getSafeStakeValueString(param) {
    let { maxStake, minStake, value } = param;
    const HARDCODED_MAX = 1e+12;
    // Trillion should be ok
    const min = minStake || 0;
    const max = maxStake || HARDCODED_MAX;
    let safeValue = value || 0;
    if (safeValue < min) safeValue = min;
    if (safeValue > max) safeValue = max;
    if (!Number.isFinite(safeValue)) safeValue = 0;
    return `${safeValue}`;
}
