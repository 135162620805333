import isDataToUpdateSlipEntry from '../../../guards/isDataToUpdateSlipEntry';
import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function updateSlipInfoFromSportlineData(currentSlipInfo, param) {
    let { events } = param;
    const { slipEntries } = currentSlipInfo;
    const changesMap = {};
    const result = [];
    for (const event of events){
        const eventId = getSlipEntryId(event);
        isDataToUpdateSlipEntry(event);
        changesMap[eventId] = event;
    }
    const isMultisingles = slipEntries.length > 1;
    if (isMultisingles) for (const entry of slipEntries){
        const firstItem = entry.entries[0];
        const firstItemId = getSlipEntryId(firstItem);
        if (changesMap[firstItemId]) {
            result.push({
                ...entry,
                entries: [
                    {
                        ...firstItem,
                        ...changesMap[firstItemId]
                    }
                ]
            });
            delete changesMap[firstItemId];
        } else result.push(entry);
    }
    else if (slipEntries.length) {
        const combi = slipEntries[0];
        const updatedEntries = combi.entries.map((item)=>{
            const itemId = getSlipEntryId(item);
            if (changesMap[itemId]) {
                const updatedItem = {
                    ...item,
                    ...changesMap[itemId]
                };
                delete changesMap[itemId];
                return updatedItem;
            }
            return item;
        });
        result.push({
            ...combi,
            entries: updatedEntries
        });
    }
    return result;
}
