import { mergeDefaults as _mergeDefaults, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { ButtonKind } from '@leon-hub/module-buttons';
import { VLoader } from '@components/loader';
import { VScrollbarDesktop } from '@components/v-scrollbar';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { BetSlipTabs } from '../BetSlipTabs';
import defaultSlipLayoutProps from './constants/DefaultSlipLayoutProps';
import useSlipLayoutCommon from './composables/useSlipLayoutCommon';
import useSlipLayoutDesktop from './composables/useSlipLayoutDesktop';
export default /*@__PURE__*/ _defineComponent({
    __name: 'SlipLayoutDesktop',
    props: /*@__PURE__*/ _mergeDefaults({
        isLogged: {
            type: Boolean
        },
        activeTopTabId: {},
        isSettingsVisible: {
            type: Boolean
        },
        slipSize: {},
        myBetsCount: {},
        resultState: {},
        clearBetListWarnVisible: {
            type: Boolean
        },
        cashoutInProgress: {
            type: Boolean
        },
        showMobileKeyboard: {
            type: Boolean
        },
        showHelpButton: {
            type: Boolean
        },
        showBookingOverlay: {
            type: Boolean
        }
    }, {
        ...defaultSlipLayoutProps
    }),
    emits: [
        "scroll",
        "select-top-tab",
        "settings-click",
        "settings-back-click",
        "select-settings-tab",
        "clear-bets-cancel",
        "clear-bets-confirm"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const props = __props;
        const emits = __emit;
        const { selectTopTab, showMyBetsTab, isPendingResultState } = useSlipLayoutCommon(props, emits);
        const { onKeydown, goToHelp, showResultOverlay, scrollDisabled, showBannerBlock } = useSlipLayoutDesktop(props);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['slip-layout'])
            }, [
                _createVNode(_unref(VScrollbarDesktop), {
                    disabled: _unref(scrollDisabled),
                    "test-el": "bet-slip",
                    "flex-fill": "",
                    onKeydown: _unref(onKeydown)
                }, {
                    default: _withCtx(()=>[
                            _createElementVNode("div", {
                                class: _normalizeClass(_ctx.$style['slip-layout__content'])
                            }, [
                                _createElementVNode("div", {
                                    class: _normalizeClass(_ctx.$style['slip-layout__body'])
                                }, [
                                    _ctx.isSettingsVisible ? _renderSlot(_ctx.$slots, "settings", {
                                        key: 0
                                    }) : (_openBlock(), _createElementBlock("div", {
                                        key: 1,
                                        class: _normalizeClass(_ctx.$style['slip-layout__main'])
                                    }, [
                                        _createVNode(_unref(BetSlipTabs), {
                                            "is-logged": _ctx.isLogged,
                                            "slip-size": _ctx.slipSize,
                                            "my-bets-count": _ctx.myBetsCount,
                                            "active-tab-id": _ctx.activeTopTabId,
                                            onTabClick: _unref(selectTopTab),
                                            onSettingsClick: _cache[0] || (_cache[0] = ($event)=>_ctx.$emit('settings-click'))
                                        }, null, 8, [
                                            "is-logged",
                                            "slip-size",
                                            "my-bets-count",
                                            "active-tab-id",
                                            "onTabClick"
                                        ]),
                                        _unref(showMyBetsTab) ? _renderSlot(_ctx.$slots, "my-bets", {
                                            key: 2
                                        }) : _renderSlot(_ctx.$slots, "main", {
                                            key: 1
                                        }),
                                        _unref(isPendingResultState) ? (_openBlock(), _createElementBlock("div", {
                                            key: 3,
                                            class: _normalizeClass(_ctx.$style['slip-layout__pending-mask'])
                                        }, null, 2)) : _createCommentVNode("", true)
                                    ], 2))
                                ], 2),
                                _unref(showBannerBlock) ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['slip-layout__banners'])
                                }, [
                                    _renderSlot(_ctx.$slots, "banners")
                                ], 2)) : _createCommentVNode("", true)
                            ], 2),
                            _ctx.showHelpButton ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(_ctx.$style['slip-layout__help'])
                            }, [
                                _createVNode(VButton, {
                                    class: _normalizeClass(_ctx.$style['slip-layout__help-button']),
                                    label: _ctx.$t('JSP_HELP'),
                                    kind: _unref(ButtonKind).BASE,
                                    rounded: "",
                                    "is-uppercase": false,
                                    onClick: _unref(goToHelp)
                                }, null, 8, [
                                    "class",
                                    "label",
                                    "kind",
                                    "onClick"
                                ])
                            ], 2)) : _createCommentVNode("", true)
                        ]),
                    _: 3
                }, 8, [
                    "disabled",
                    "onKeydown"
                ]),
                _ctx.clearBetListWarnVisible || _ctx.cashoutInProgress || _unref(showResultOverlay) ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['slip-layout__overlay'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass({
                            [_ctx.$style['slip-layout__overlay-content']]: true,
                            [_ctx.$style['slip-layout__overlay-content--cashout']]: _ctx.cashoutInProgress
                        })
                    }, [
                        _ctx.clearBetListWarnVisible ? _renderSlot(_ctx.$slots, "confirm-overlay", {
                            key: 0
                        }) : _createCommentVNode("", true),
                        _ctx.cashoutInProgress ? _renderSlot(_ctx.$slots, "cashout", {
                            key: 1
                        }) : _createCommentVNode("", true),
                        _unref(showResultOverlay) ? _renderSlot(_ctx.$slots, "result", {
                            key: 2
                        }) : _createCommentVNode("", true)
                    ], 2)
                ], 2)) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'SlipLayoutDesktop'
                ]
            ]);
        };
    }
});
