import { ref, computed, toRef } from 'vue';
import { defineStore } from 'pinia';
import { SlipTypeId } from '@leon-hub/api-sdk';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { useSlipSelectedEntriesStore } from '../../slipSelectedEntries/store';
import { useStakeInputStore } from '../../stake-input/store';
const useFreebetStore = defineStore('freebet-store', ()=>{
    const formatMoney = useFormatMoney();
    const slipSelectedEntriesStore = useSlipSelectedEntriesStore();
    const selectedEntriesIds = toRef(slipSelectedEntriesStore, 'selectedEntriesIds');
    const stakeInputStore = useStakeInputStore();
    const safeStakeValue = toRef(stakeInputStore, 'safeStakeValue');
    const { safeSetStakeValue, setValueFromFreebet } = stakeInputStore;
    const freeBets = ref({});
    const lastStakeValue = ref('0');
    const saveLastStakeValue = ()=>{
        lastStakeValue.value = `${safeStakeValue.value}`;
    };
    const updateFreeBets = (updatedFreeBets)=>{
        if (null === updatedFreeBets) {
            freeBets.value = {};
            return;
        }
        freeBets.value = {
            ...freeBets.value,
            ...updatedFreeBets.reduce((accumulator, item)=>({
                    ...accumulator,
                    [item.freeBetId]: item
                }), {})
        };
    };
    const selectedFreeBetId = ref('');
    const setSelectedFreeBetId = (id)=>{
        selectedFreeBetId.value = id;
    };
    const freeBetSwitchChecked = ref(false);
    const setFreeBetSwitchChecked = (checked)=>{
        freeBetSwitchChecked.value = checked;
    };
    const freeBetOptions = computed(()=>Object.keys(freeBets.value).map((freeBetId)=>{
            const relatedItem = freeBets.value[freeBetId];
            return {
                label: relatedItem.name,
                value: `${freeBetId}`,
                freeBetAmount: formatMoney(relatedItem.amount)
            };
        }));
    const currentFreeBet = computed(()=>{
        if (!selectedFreeBetId.value) return null;
        return freeBets.value[selectedFreeBetId.value] || null;
    });
    const showFreeBet = computed(()=>Object.keys(freeBets.value).length > 0);
    const selectedFreeBetAmount = computed(()=>{
        if (!selectedFreeBetId.value) return lastStakeValue.value;
        return `${freeBets.value[selectedFreeBetId.value].amount ?? 0}`;
    });
    const selectedFreeBetTermKey = computed(()=>{
        if (currentFreeBet.value) return currentFreeBet.value.promoActionTermsPage;
        if (1 === Object.keys(freeBets.value).length) {
            const key = Object.keys(freeBets.value)[0];
            return freeBets.value[key].promoActionTermsPage;
        }
        return '';
    });
    const disabledFreeBetSwitch = computed(()=>{
        const freeBetsArray = Object.values(freeBets.value);
        return Boolean(!freeBetSwitchChecked.value && (showFreeBet.value !== Boolean(freeBetsArray.length) || freeBetsArray.every((freeBet)=>!freeBet.oddsValid)));
    });
    const setSelectedFreeBet = (id)=>{
        if (!selectedFreeBetId.value) saveLastStakeValue();
        setSelectedFreeBetId(id);
        setValueFromFreebet(selectedFreeBetAmount.value);
    };
    const clearFreeBet = ()=>{
        setFreeBetSwitchChecked(false);
        setSelectedFreeBetId('');
        updateFreeBets(null);
    };
    const checkIfNeedToCancelFreebet = (betMode, safeStakePayload)=>{
        if (freeBetSwitchChecked.value && (betMode === SlipTypeId.SYSTEM || betMode === SlipTypeId.SINGLE && selectedEntriesIds.value.length > 1)) {
            setFreeBetSwitchChecked(false);
            setSelectedFreeBetId('');
            safeSetStakeValue(safeStakePayload);
        }
    };
    const onChangeFreeBetSwitch = (isChecked)=>{
        setFreeBetSwitchChecked(isChecked);
        if (isChecked && 1 === freeBetOptions.value.length) setSelectedFreeBet(freeBetOptions.value[0].value);
        if (!isChecked) setSelectedFreeBet('');
    };
    return {
        selectedFreeBetId,
        freeBetSwitchChecked,
        freeBetOptions,
        currentFreeBet,
        showFreeBet,
        selectedFreeBetTermKey,
        disabledFreeBetSwitch,
        setSelectedFreeBet,
        clearFreeBet,
        checkIfNeedToCancelFreebet,
        onChangeFreeBetSwitch,
        setFreeBetSwitchChecked,
        updateFreeBets
    };
});
export default useFreebetStore;
