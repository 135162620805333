import { getBetsInfoFromSharedSlipEntry } from './getBetsInfoFromSharedSlipEntry';
import { getSlipEntryFromSharedSlipEntry } from './getSlipEntryFromSharedSlipEntry';
import { getSlipEntryId } from '../../../utils';
export function convertSharedSlip(sharedSlipEntries) {
    const slipEntries = [];
    const betsInfo = {};
    for (const sharedEntry of sharedSlipEntries){
        const slipEntry = getSlipEntryFromSharedSlipEntry(sharedEntry);
        const betsInfoItem = getBetsInfoFromSharedSlipEntry(sharedEntry);
        const itemId = getSlipEntryId(slipEntry);
        slipEntries.push(slipEntry);
        betsInfo[itemId] = betsInfoItem;
    }
    return {
        slipEntries,
        betsInfo
    };
}
