import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, mergeProps as _mergeProps, createSlots as _createSlots, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue";
import { onMounted, onBeforeUnmount } from 'vue';
import { SlipTypeId } from '@leon-hub/api-sdk';
import { IconName } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { VFastMoneyChoice } from 'web/src/components/FastMoneyChoice';
import VTabsButton from 'web/src/components/Tabs/VTabsButton/VTabsButton.vue';
import VButton from 'web/src/components/Button/VButton/VButton.vue';
import { ButtonKind } from 'web/src/components/Button/VButton/enums';
import { StakeInput } from 'web/src/components/Input';
import { SlipListBettingItem } from '../SlipListItem';
import useBetSlipMain from './composables/useBetSlipMain';
export default /*@__PURE__*/ _defineComponent({
    __name: 'BetSlipMain',
    props: {
        isHidden: {
            type: Boolean
        },
        betMode: {
            default: SlipTypeId.SINGLE
        },
        singleModeDisabled: {
            type: Boolean
        },
        expressModeDisabled: {
            type: Boolean
        },
        systemModeDisabled: {
            type: Boolean
        },
        betEvents: {
            default: ()=>[]
        },
        bankersAvailable: {
            type: Boolean
        },
        showTraceButton: {
            type: Boolean
        },
        fastBetsItems: {
            default: ()=>[]
        },
        isLoggedIn: {
            type: Boolean
        },
        maxFastBetEnabled: {
            type: Boolean
        },
        stakeInputValidationTimeout: {
            default: 2000
        },
        sameStakeForSingles: {
            type: Boolean
        },
        isMultiSinglesMode: {
            type: Boolean
        },
        isFastBetsEnabled: {
            type: Boolean
        },
        currencySymbol: {
            default: '$'
        },
        showStakeInput: {
            type: Boolean
        },
        hideInputErrorsId: {},
        lastFocusedItemId: {},
        focusedItemId: {}
    },
    emits: [
        "select-bet-mode",
        "event-delete-click",
        "event-banker-click",
        "trace-click",
        "stake-input",
        "stake-input-focus",
        "stake-input-blur",
        "event-accept-changes",
        "singles-mobile-input",
        "edit-fast-bets"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        require('web/src/components/Empty/components/VEmpty').default;
        // eslint-disable-next-line vue/define-macros-order
        const props = __props;
        const emits = __emit;
        const { betModeTabs, showDeleteAnimation, selectBetMode, onEventDeleteClick, onEventBankerClick, showInputBlock, onTraceClick, onStakeInput, onStakeInputFocus, onStakeInputBlur, onEventAcceptChanges, onAppear, onDisappear, onBackspaceInput, onNumberInput, onDecimalInput, selectFastBet, onEditFastBets } = useBetSlipMain(props, emits);
        onMounted(onAppear);
        onBeforeUnmount(onDisappear);
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass({
                    [_ctx.$style['bet-slip-main']]: true,
                    [_ctx.$style['bet-slip-main--desktop']]: true
                })
            }, [
                _createElementVNode("header", {
                    class: _normalizeClass(_ctx.$style['bet-slip-main__header']),
                    role: "tablist"
                }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(betModeTabs), (param)=>{
                        let { id, label, disabled } = param;
                        return _openBlock(), _createBlock(VTabsButton, {
                            id: id,
                            key: id,
                            disabled: disabled,
                            active: id === _ctx.betMode,
                            "is-bordered": "",
                            onClick: ($event)=>_unref(selectBetMode)(id)
                        }, {
                            default: _withCtx(()=>[
                                    _createTextVNode(_toDisplayString(label), 1)
                                ]),
                            _: 2
                        }, 1032, [
                            "id",
                            "disabled",
                            "active",
                            "onClick"
                        ]);
                    }), 128))
                ], 2),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['bet-slip-main__content'])
                }, [
                    _createElementVNode("ul", {
                        class: _normalizeClass(_ctx.$style['bet-slip-main__list'])
                    }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.betEvents, (param, index)=>{
                            let { id, minStake, maxStake, maxAvailableBet, needToAcceptChanges, stakeValue, errorMessage, ...eventProps } = param;
                            return _openBlock(), _createElementBlock("li", {
                                key: id,
                                class: _normalizeClass(_ctx.$style['bet-slip-main__row'])
                            }, [
                                _createVNode(_unref(SlipListBettingItem), _mergeProps({
                                    ref_for: true
                                }, eventProps, {
                                    "bet-mode": _ctx.betMode,
                                    "bankers-available": _ctx.bankersAvailable,
                                    "need-to-accept-changes": needToAcceptChanges,
                                    "show-delete-animation": _unref(showDeleteAnimation),
                                    "error-message-hidden": _ctx.hideInputErrorsId === id,
                                    "error-message": errorMessage,
                                    onDeleteClick: ($event)=>_unref(onEventDeleteClick)(id),
                                    onBankerClick: ($event)=>_unref(onEventBankerClick)(id)
                                }), _createSlots({
                                    _: 2
                                }, [
                                    _ctx.isHidden ? void 0 : {
                                        name: "control",
                                        fn: _withCtx(()=>[
                                                needToAcceptChanges && _ctx.focusedItemId !== id ? (_openBlock(), _createBlock(VButton, {
                                                    key: 0,
                                                    label: _ctx.$t('WEB2_SLIP_ACCEPT'),
                                                    kind: _unref(ButtonKind).CANCEL,
                                                    "is-uppercase": false,
                                                    onClick: ($event)=>_unref(onEventAcceptChanges)(id)
                                                }, null, 8, [
                                                    "label",
                                                    "kind",
                                                    "onClick"
                                                ])) : _ctx.showStakeInput ? (_openBlock(), _createElementBlock("div", {
                                                    key: 1,
                                                    class: _normalizeClass(_ctx.$style['bet-slip-main__stake-input-wrapper'])
                                                }, [
                                                    _createVNode(_unref(StakeInput), {
                                                        "is-focused": _ctx.focusedItemId === id,
                                                        value: stakeValue,
                                                        "currency-symbol": _ctx.currencySymbol,
                                                        "have-error": !!errorMessage,
                                                        onFocus: ($event)=>_unref(onStakeInputFocus)(id),
                                                        onBlur: ($event)=>_unref(onStakeInputBlur)(id),
                                                        onInput: (value)=>_unref(onStakeInput)(value, id)
                                                    }, null, 8, [
                                                        "is-focused",
                                                        "value",
                                                        "currency-symbol",
                                                        "have-error",
                                                        "onFocus",
                                                        "onBlur",
                                                        "onInput"
                                                    ])
                                                ], 2)) : _createCommentVNode("", true)
                                            ]),
                                        key: "0"
                                    }
                                ]), 1040, [
                                    "bet-mode",
                                    "bankers-available",
                                    "need-to-accept-changes",
                                    "show-delete-animation",
                                    "error-message-hidden",
                                    "error-message",
                                    "onDeleteClick",
                                    "onBankerClick"
                                ]),
                                _unref(showInputBlock) && _ctx.lastFocusedItemId === id ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(_ctx.$style['bet-slip-main__singles-input'])
                                }, [
                                    _ctx.isFastBetsEnabled ? (_openBlock(), _createBlock(_unref(VFastMoneyChoice), {
                                        key: 0,
                                        "current-value": Number(stakeValue),
                                        "show-edit-button": _ctx.isLoggedIn,
                                        "max-enabled": _ctx.maxFastBetEnabled,
                                        "max-available-value": maxStake,
                                        "min-available-value": minStake,
                                        "max-value": maxAvailableBet,
                                        items: _ctx.fastBetsItems,
                                        onEditClick: _unref(onEditFastBets),
                                        onSelectValue: (value)=>_unref(selectFastBet)(value, id)
                                    }, null, 8, [
                                        "current-value",
                                        "show-edit-button",
                                        "max-enabled",
                                        "max-available-value",
                                        "min-available-value",
                                        "max-value",
                                        "items",
                                        "onEditClick",
                                        "onSelectValue"
                                    ])) : _createCommentVNode("", true),
                                    _createCommentVNode("", true)
                                ], 2)) : _createCommentVNode("", true),
                                _ctx.showTraceButton && _ctx.isMultiSinglesMode ? (_openBlock(), _createBlock(_unref(VIcon), {
                                    key: 1,
                                    name: _unref(IconName).SEARCH,
                                    title: "DEVIP trace",
                                    class: _normalizeClass(_ctx.$style['bet-slip-main__trace']),
                                    "data-test-id": "trace-button",
                                    onClick: ($event)=>_unref(onTraceClick)(index)
                                }, null, 8, [
                                    "name",
                                    "class",
                                    "onClick"
                                ])) : _createCommentVNode("", true)
                            ], 2);
                        }), 128))
                    ], 2)
                ], 2),
                _ctx.showTraceButton && !_ctx.isMultiSinglesMode ? (_openBlock(), _createBlock(_unref(VIcon), {
                    key: 0,
                    name: _unref(IconName).SEARCH,
                    title: "DEVIP trace",
                    class: _normalizeClass(_ctx.$style['bet-slip-main__trace']),
                    "data-test-id": "trace-button",
                    onClick: _cache[0] || (_cache[0] = ($event)=>_unref(onTraceClick)(0))
                }, null, 8, [
                    "name",
                    "class"
                ])) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'BetSlipMain'
                ]
            ]);
        };
    }
});
