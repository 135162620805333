export default function isMatchedArrays(first, second, sortFunction) {
    if (first.length !== second.length) return false;
    try {
        // for objects its mostly faster than lodash is equal
        return JSON.stringify([
            ...first
        ].sort(sortFunction)) === JSON.stringify([
            ...second
        ].sort(sortFunction));
    } catch  {
        return false;
    }
}
