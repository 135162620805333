import { computed, ref, toRef } from 'vue';
import { defineStore } from 'pinia';
import { Timer } from '@leon-hub/utils';
import { useSiteConfigStore } from 'web/src/modules/core/store';
import { useCustomerDataStore } from 'web/src/modules/customer/store';
import { useIsLoggedIn } from 'web/src/modules/auth/composables';
import { useFormatMoney } from 'web/src/modules/money/composables';
import { getModifiedStakeInputValue } from '../../stake-input/utils';
import { useStakeInputStore } from '../../stake-input/store';
import { isFocusedIdBecameUnavailable, modifyMetaInfoRecordOnFocus, modifyMetaInfoRecordOnBlur, getUpdatedMultiSinglesStakeValue } from '../utils';
const useMultiSinglesStore = defineStore('multi-singles-store', ()=>{
    const siteConfigStore = useSiteConfigStore();
    const customerDataStore = useCustomerDataStore();
    const stakeInputValidationTimeout = toRef(useStakeInputStore(), 'stakeInputValidationTimeout');
    const { isLoggedIn } = useIsLoggedIn();
    const formatMoney = useFormatMoney();
    const sameStakeForSingleByDefault = toRef(customerDataStore, 'sameStakeForSingleByDefault');
    const slipBlock = toRef(siteConfigStore, 'slipBlock');
    const localSameStakeForSingles = ref(false);
    const isMultiSinglesEnabled = computed(()=>slipBlock.value?.multiSinglesEnabled ?? false);
    const multiSinglesMetaInfo = ref({});
    const setMultiSinglesMetaInfo = (updatedInfo)=>{
        multiSinglesMetaInfo.value = updatedInfo;
    };
    let removeFocusedTimeoutId = 0;
    let hideInputErrorTimeoutId = 0;
    // used to display error on last modified input
    const lastModifiedId = ref(null);
    // used to show fast bets block on desktop
    const lastFocusedItemId = ref(null);
    // pass prop to component
    const focusedItemId = ref(null);
    const setLocalSameStakeForSingles = (isSame)=>{
        localSameStakeForSingles.value = isSame;
        if (isSame) {
            lastFocusedItemId.value = null;
            focusedItemId.value = null;
        }
    };
    // show error on edit with delay
    const hideInputErrorsId = ref(null);
    const setLastModifiedId = (value)=>{
        lastModifiedId.value = value;
    };
    const sameStakeForSingles = computed(()=>{
        if (!isLoggedIn.value) return localSameStakeForSingles.value;
        return sameStakeForSingleByDefault.value;
    });
    const handleStakeInputEdited = (id)=>{
        hideInputErrorsId.value = id;
        Timer.clearTimeout(hideInputErrorTimeoutId);
        hideInputErrorTimeoutId = Timer.setTimeout(()=>{
            hideInputErrorsId.value = null;
        }, stakeInputValidationTimeout.value || 0);
    };
    const updateStakeValueInMultiSingles = (param)=>{
        let { value, id, isFastBetValue } = param;
        handleStakeInputEdited(id);
        if (isFastBetValue) hideInputErrorsId.value = null;
        const updatedMetaInfo = getUpdatedMultiSinglesStakeValue({
            value,
            id,
            source: multiSinglesMetaInfo.value,
            isFastBetValue,
            formatMoney
        });
        setLastModifiedId(id);
        setMultiSinglesMetaInfo(updatedMetaInfo);
    };
    const onMultiSinglesInputBlur = (id)=>{
        focusedItemId.value = null;
        hideInputErrorsId.value = null;
        removeFocusedTimeoutId = Timer.setTimeout(()=>{
            lastFocusedItemId.value = null;
        }, 200);
        const updatedMetaInfo = modifyMetaInfoRecordOnBlur(id, multiSinglesMetaInfo.value);
        if (updatedMetaInfo) setMultiSinglesMetaInfo(updatedMetaInfo);
    };
    const onMultiSinglesInputFocus = (id)=>{
        Timer.clearTimeout(removeFocusedTimeoutId);
        lastFocusedItemId.value = id;
        focusedItemId.value = id;
        const updatedMetaInfo = modifyMetaInfoRecordOnFocus(id, multiSinglesMetaInfo.value);
        if (updatedMetaInfo) setMultiSinglesMetaInfo(updatedMetaInfo);
    };
    const handleSinglesMobileInput = (param)=>{
        let { value, id, action } = param;
        handleStakeInputEdited(id);
        const matchedRecord = multiSinglesMetaInfo.value[id];
        if (!matchedRecord) return;
        const updatedValue = getModifiedStakeInputValue(matchedRecord.stakeValue, {
            action,
            value,
            removeCurrentValue: matchedRecord.clearValueOnFocus
        });
        updateStakeValueInMultiSingles({
            value: updatedValue,
            id
        });
    };
    const clearFocusOnUnavailableEvents = (changedEvents)=>{
        if (focusedItemId.value && isFocusedIdBecameUnavailable(focusedItemId.value, changedEvents)) onMultiSinglesInputBlur(focusedItemId.value);
    };
    const resetMultiSinglesFocusOnClose = ()=>{
        if (focusedItemId.value) onMultiSinglesInputBlur(focusedItemId.value);
    };
    return {
        isMultiSinglesEnabled,
        sameStakeForSingles,
        lastModifiedId,
        multiSinglesMetaInfo,
        lastFocusedItemId,
        focusedItemId,
        hideInputErrorsId,
        setLocalSameStakeForSingles,
        setMultiSinglesMetaInfo,
        setLastModifiedMultiSingleId: setLastModifiedId,
        updateStakeValueInMultiSingles,
        onMultiSinglesInputFocus,
        onMultiSinglesInputBlur,
        handleSinglesMobileInput,
        clearFocusOnUnavailableEvents,
        resetMultiSinglesFocusOnClose
    };
});
export default useMultiSinglesStore;
