export default function modifyMetaInfoRecordOnFocus(id, currentMetaInfo) {
    const matchedItem = currentMetaInfo[id];
    if (!matchedItem) return null;
    if (matchedItem.clearValueOnFocus || '0' === matchedItem.stakeValue) {
        const updatedItem = {
            ...matchedItem,
            stakeValue: '',
            selectedFastBetValue: null,
            stakeWasEdited: true
        };
        const copy = {
            ...currentMetaInfo
        };
        copy[id] = updatedItem;
        return copy;
    }
    return null;
}
