import getMultiSinglesSlipEntry from '../../multi-singles/utils/getMultiSinglesSlipEntry';
import getSlipEntryId from '../../../utils/getSlipEntryId';
export default function updateSlipInfoBySlipEntries(param) {
    let { entries, overwriteMultisingles, currentSlipInfo, selectedEntriesIds } = param;
    const currentSlipEntries = currentSlipInfo?.slipEntries;
    if (!currentSlipEntries?.length || !entries.length) return null;
    let updatedSlipInfo = [];
    if (currentSlipEntries.length === entries.length || overwriteMultisingles) {
        // singles
        const availableEntries = overwriteMultisingles ? currentSlipEntries.filter((entry)=>selectedEntriesIds.includes(getSlipEntryId(getMultiSinglesSlipEntry(entry)))) : currentSlipEntries;
        updatedSlipInfo = availableEntries.map((entry, index)=>({
                ...entry,
                entries: [
                    entries[index]
                ]
            }));
    } else if (1 === currentSlipEntries.length) {
        // combi
        const combiEntry = currentSlipEntries[0];
        updatedSlipInfo = [
            {
                ...combiEntry,
                entries
            }
        ];
    } else throw new Error('unmatched entries lists to updateSlipInfoEntries');
    return updatedSlipInfo;
}
